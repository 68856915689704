.inner-detail-form {
    display: block;
  }
  
  .inner-detail-form-headings {
    font-size: 14px;
    font-weight: 500;
  }
  
  
  .preview-col {
    padding-left: 20px;
    padding-right: 0 !important;
  }
  
  .border-none {
    border: none;
  }
  
  .alert-send-time {
    border: 1px solid rgb(202, 183, 221);
    padding: 0 5px;
    border-radius: 5px;
  }
  
  .alert-deatil-1 {
    font-weight: 500;
    display: flex;
    justify-content: space-between;
  }
  
  .alert-box-border {
    border-bottom: 1px solid rgb(222 226 230);
  }
  
  .alert-send-time p {
    display: flex;
    align-items: center;
  }
  
  .alert-send-time select {
    border: none;
    /* width: 70px !important; */
  }
  
  .send-email {
    background: rgb(13, 55, 180);
    background: linear-gradient(117deg,
        rgba(13, 55, 180, 1) 0%,
        rgba(118, 60, 209, 1) 59%) !important;
  }
  
  
  .alert-box div {
    display: flex;
    text-align: center;
    align-items: center;
    gap: 10px;
  }
  
  .details-form label {
    color: rgb(38 40 149);
  }
  .alert-send-time.chase-width{
    width: 135px !important;
  }
  .alert-send-time select {
    border: none;
    width: 47px !important;
  }

  @media only screen and (max-width: 767px) {
  .alert-send-time.chase-width {
    width:100% !important;
  }
}