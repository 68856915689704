@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Reddit+Sans:ital,wght@0,200..900;1,200..900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Plus+Jakarta+Sans:ital,wght@0,200..800;1,200..800&display=swap");

body {
  margin: 0;
  padding: 0;
  font-family: "Montserrat";
  font-family: "Plus Jakarta Sans", sans-serif;
}

.landing-page .section {
  width: 100%;
  float: left;
  /* padding: 150px 0; */
  padding: 120px 0;
}

.landing-page p {
  font-size: 18px;
  line-height: 28px;
  font-family: "Montserrat";
  font-family: "Plus Jakarta Sans", sans-serif;
  font-weight: 500;
}

.landing-page h1,
.landing-page h2,
.landing-page h3,
.landing-page h4,
.landing-page h5,
.landing-page h6,
.landing-page p {
  margin: 0;
}

.landing-page ul {
  list-style: none;
  padding: 0;
}

/* 0 */

.landing-page .header {
  width: 100%;
  /* height: 78px; */
  float: left;
  position: absolute;
  z-index: 999;
  top: 0;
  left: 0;
  padding: 0;
  /* transition: 0.3s; */
  /* transition: background-color 0.3s ease, border-bottom 0.3s ease, box-shadow 0.3s ease; */
  -webkit-transition: all 0.5s ease;
  -moz-transition: position 10s;
  -ms-transition: position 10s;
  -o-transition: position 10s;
  transition: all 0.5s ease;
}

.landing-page .header .container-fluid {
  padding: 10px 50px;
}

.landing-page .header.sticky {
  position: fixed !important;
  background-color: #fff;
  z-index: 999;
  border-bottom: 1px solid rgba(18, 18, 18, 0.08);
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  animation: smoothScroll 1s forwards;
}

@keyframes smoothScroll {
  0% {
    transform: translateY(-100px);
  }

  100% {
    transform: translateY(0px);
  }
}

.landing-page .header.sticky .menu ul li a {
  color: #121212;
  padding-bottom: 20px;
}

.landing-page .header.sticky .nav-link:focus,
.landing-page .nav-link:hover {
  color: #121212;
}

.landing-page .header-container {
  /* width: 100%;
    height: 100%; */
  display: flex;
  justify-content: space-between;
  align-items: center;
  /* position: relative;
    padding: 10px 10px; */
}

.landing-page .nav-link:focus,
.landing-page .nav-link:hover {
  color: #ffffff;
}

/* .page-content{
    overflow: hidden;
} */

/* .mv-logo {
    padding: 0px 50px;
} */



.landing-page .mv-logo img {
  /* width: 280px; */
  width: 100%;
}

.landing-page .menu ul {
  padding: 0;
  margin-bottom: 0px;
}

.landing-page .menu ul li {
  display: inline;
  list-style: none;
  color: #fff;
  padding: 15px;
  font-size: 17px;
  font-weight: 600;
  font-family: "Plus Jakarta Sans", sans-serif;
  cursor: pointer;
}

.landing-page .menu ul li{
  color: #fff;
  font-weight: 600;
  text-decoration: none;
  padding-bottom: 20px;
  position: relative;
}

.landing-page .menu ul li::before {
  content: "";
  position: absolute;
  left: 0;
  bottom: -3px;
  width: 100%;
  height: 4px;
  background: #fc6853;
  -webkit-transform-origin: right top;
  -ms-transform-origin: right top;
  transform-origin: right top;
  -webkit-transform: scale(0, 1);
  -ms-transform: scale(0, 1);
  transform: scale(0, 1);
  transition: transform 0.3s cubic-bezier(0.74, 0.72, 0.27, 0.24);
}

.landing-page .menu ul li:hover::before {
  -webkit-transform-origin: left top;
  -ms-transform-origin: left top;
  transform-origin: left top;
  -webkit-transform: scale(1, 1);
  -ms-transform: scale(1, 1);
  transform: scale(1, 1);
}

.landing-page .menu i {
  color: #5c3cbf;
  outline: 0;
  font-size: 30px;
}

.landing-page .menu {
  justify-content: center;
  display: flex;
  align-items: center;
}

.landing-page .menu-overlay {
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: rgba(255, 255, 255, 0.8);
  display: none;
  position: fixed;
  z-index: 999999;
}

.landing-page .btn14 {
  /* position: absolute; */
  width: 35px;
  /* height: 35px; */
  /* top: 11px;
    right: 0px; */
  transition-duration: 0.5s;
  display: none;
}

.landing-page .header-btn {
  margin-left: 20px;
  display: flex;
  align-items: center;
}

.landing-page .header-btn .book-demo-btn {
  margin-right: 10px;
}

.landing-page .header-btn .book-demo-btn:last-child {
  margin-right: 0;
}

.landing-page .header-btn .book-demo-btn button {
  padding: 6px 11px;
  font-size: 15px;
  box-shadow: none;
}

.landing-page .header-btn .book-demo-btn.line-btn button {
  background: transparent;
  border: 2px solid #fff;
  color: #ffffff;
  /* border: 2px solid #fc6853;
    color: #fc6853; */
}

.landing-page .header-btn .book-demo-btn.line-btn button:hover {
  background: transparent;
  border: 2px solid #fc6853;
  color: #fff;
}

.landing-page .header.sticky .header-btn .book-demo-btn.line-btn button {
  border: 2px solid #fc6853;
  color: #fc6853;
}

.landing-page .header-btn .arrow-spacing img {
  width: 20px;
}

.landing-page .header.sticky .header-btn .book-demo-white .arrow-spacing img {
  filter: invert(52%) sepia(67%) saturate(596%) hue-rotate(323deg)
    brightness(97%) contrast(95%);
}

.landing-page .header-btn .button__shadow {
  width: calc(100% + 0.2rem);
  height: 38px;
  bottom: -7px;
  left: 0px;
}

.landing-page .ham-icon {
  width: 35px;
  height: 25px;
  display: block;
  position: relative;
}

.landing-page .ham-icon span {
  display: block;
  position: absolute;
  right: 0;
  /* left: 0; */
  height: 2px;
  transition: all 0.35s ease-in-out 0s;
  background-color: #5e3dc2;
}

.landing-page .ham-icon span:first-of-type {
  top: 50%;
  transform: translateY(-50%);
  width: 78%;
}

.landing-page .ham-icon span:nth-of-type(2) {
  top: 0;
  transform: translateZ(0) rotate(0) scaleX(1);
  width: 100%;
}

.landing-page .ham-icon span:nth-of-type(3) {
  bottom: 0;
  transform: translateZ(0) rotate(0) scaleX(1);
  width: 50%;
}

.landing-page .ham-icon:hover span {
  width: 100%;
}

/* .hidden-bar:before {
    background-image: url(../../assets/images/LandingpageImage/resp-menu-bg.svg);
    background-size: cover;
    content: "";
    height: 100%;
    left: auto;
    opacity: .2;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 0;
} */

.landing-page .hidden-bar {
  position: fixed;
  top: 0;
  right: -680px;
  width: 487px;
  height: 100%;
  padding: 40px;
  /* background: #352396; */
  background: url(../../assets/images/LandingpageImage/hidden-bar-bg.png);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: bottom;
  z-index: 99999999;
  transition: all 700ms ease;
  -webkit-transition: all 700ms ease;
  -ms-transition: all 700ms ease;
  -o-transition: all 700ms ease;
  -moz-transition: all 700ms ease;
  -webkit-transition: all 0.4s ease-out;
}

.landing-page.visible-sidebar .hidden-bar{
  right: 0;
}

.landing-page .hidden-bar.right-align .hidden-bar-closer {
  right: 0;
  top: 0;
  border: 2px solid #ddd;
  border-radius: 50%;
}

.landing-page .hidden-bar .hidden-bar-closer {
  cursor: pointer;
  position: relative;
  width: 47px;
  height: 47px;
  background: transparent !important;
  color: #ffffff;
  border-radius: 0px;
  text-align: center;
  line-height: 35px;
  transition: all 300ms ease;
  -webkit-transition: all 300ms ease;
  -ms-transition: all 300ms ease;
  -o-transition: all 300ms ease;
  -moz-transition: all 300ms ease;
  z-index: 999999;
}

.landing-page .hidden-bar .popup_close_cont {
  position: relative;
  /* width: 36px; */
  height: 36px;
  margin-top: 8px;
  margin-left: 7px;
}

.landing-page .hidden-bar .popup_close_bar:first-child {
  top: 20px;
  transform: rotate(45deg);
}

.landing-page .hidden-bar .popup_close_bar {
  width: 26px;
  height: 2px;
  background-color: #fff;
  position: absolute;
  left: 9px;
  -webkit-transition: all 0.5s ease-in-out;
  -moz-transition: all 0.5s ease-in-out;
  -o-transition: all 0.5s ease-in-out;
  transition: all 0.5s ease-in-out;
}

.landing-page .hidden-bar .popup_close_bar:nth-child(2) {
  top: 20px;
  transform: rotate(-45deg);
}

.landing-page .hidden-bar .mv-logo img {
  width: 283px;
}

.landing-page .close-btn {
  text-decoration: none;
  background: transparent;
  box-shadow: none;
  border: none;
  box-sizing: border-box;
  cursor: pointer;
  height: 50px;
  padding: 0;
  margin-bottom: 35px;
}

.landing-page .itemm {
  position: relative;
  width: 50px;
  height: 50px;
  background: transparent;
  border: none;

  &:before,
  &:after {
    content: "";
    position: absolute;
    height: 2px;
    width: 50%;
    background-color: #fff;
    transition: width 0.2s ease-out;
  }

  &:before {
    transform: rotate(45deg);
    left: 7px;
    top: 7px;
    transform-origin: left;
  }

  &:after {
    transform: rotate(-45deg);
    right: 7px;
    top: 7px;
    transform-origin: right;
  }

  .inner {
    color: #fff;

    &:before,
    &:after {
      content: "";
      position: absolute;
      height: 2px;
      width: 50%;
      background-color: #fff;
      transition: width 0.2s ease-out;
    }

    &:before {
      transform: rotate(45deg);
      transform-origin: right;
      bottom: 6px;
      right: 8px;
    }

    &:after {
      transform: rotate(-45deg);
      transform-origin: left;
      bottom: 6px;
      left: 8px;
    }
  }

  .label {
    opacity: 0;
    transition: all 0.2s ease-out;
  }

  &:focus,
  &:hover {
    &:before,
    &:after {
      width: 10px;
    }

    .inner {
      &:before,
      &:after {
        width: 10px;
      }
    }

    .label {
      opacity: 1;
      font-size: 13px;
    }
  }
}

.landing-page .hidden-bar .side-menu ul li a,.landing-page .hidden-bar .side-menu ul li {
  color: #fff;
  font-weight: 600;
  font-size: 22px;
  display: block;
  line-height: normal;
  padding: 10px 10px 10px 0;
  display: block;
  position: relative;
  text-decoration: none;
  -webkit-transition: color 0.2s linear;
  transition: color 0.2s linear;
  display: block;
  cursor: pointer;
}

.landing-page .hidebar-img {
  width: 100%;
  position: absolute;
  bottom: 0;
  left: 0;
  opacity: 0.4;
}

.landing-page .hidebar-img img {
  width: 100%;
}

@media only screen and (max-width: 767px) {

  .landing-page .bg_light{
    background-color:transparent !important;
  }
  .landing-page .hidden-bar {
    width: 100%;
    right: -844px;
  }
}

.landing-page .right-header {
  display: flex;
  /* width: 38%; */
}

.landing-page .banner-section {
  width: 100%;
  float: left;
  padding: 0;
  position: relative;
  background-color: #f5fbfe;
  height: 100vh;
  top: 0px;
  overflow: hidden;
}

.landing-page .banner-section .container-fluid {
  padding: 0 100px;
}

.landing-page .banner-container {
  height: 100%;
  display: flex;
  background: linear-gradient(
    90deg,
    rgba(249, 237, 239, 1) 0%,
    rgba(215, 239, 251, 1) 100%
  );
}

.landing-page .left-banner {
  /* position: relative; */
  height: 100%;
  width: 60%;
  /* background: linear-gradient(90deg, rgba(249, 237, 239, 1) 0%, rgba(215, 239, 251, 1) 100%); */
  display: flex;
  /* Flex for aligning left content */

  /* padding: 0 20px; */
  /* Optional: Add horizontal padding */
}

.landing-page .right-banner {
  width: 40%;
  background: url(../../assets/images/LandingpageImage/right-banner-bg.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  height: 100%;
  position: absolute;
  right: 0;
}

.landing-page .meeting {
  position: absolute;
  /* left: -221px; */
  /* bottom: 70px; */
  left: -290px;
  top: 65%;
  transform: translateY(-65%);
  width: 100%;
}

.landing-page .right-sqr-frame {
  position: absolute;
  left: 185px;
  top: 21%;
  width: 180px;
}

.landing-page .right-element1 {
  width: 90px;
  height: 80px;
  background: url(../../assets/images/LandingpageImage/right-element1.png)
    no-repeat;
  position: absolute;
  bottom: 55px;
  right: 20px;
  z-index: 9;
}

.landing-page .right-element2 {
  width: 90px;
  height: 80px;
  background: url(../../assets/images/LandingpageImage/right-element1.png)
    no-repeat;
  position: absolute;
  top: 135px;
  left: -50px;
  z-index: 9;
  transition: transform 0.2s;
}

.landing-page .right-element3 {
  width: 90px;
  height: 80px;
  background: url(../../assets/images/LandingpageImage/right-element1.png)
    no-repeat;
  position: absolute;
  top: 27%;
  right: 95px;
  z-index: 9;
}

.landing-page .left-content h1.top-title {
  font-weight: 600;

  font-family: reddit sans, Sans-serif;
}

.landing-page .left-content-wrapper {
  display: flex;
  align-items: center;
}

.landing-page .left-content {
  padding-top: 60px;
  width: 72%;
  /* padding-left: 70px; */
  z-index: 99;
  position: relative;
}

.landing-page .left-content p {
  font-size: 25px;
  line-height: 40px;
  font-weight: 500;
  margin-top: 10px;

  font-family: "Plus Jakarta Sans", sans-serif;
  font-size: 24px;
  line-height: 34px;
}

.landing-page h1.solution-txt {
  font-size: 71px;
  font-weight: 700;
  color: #000;
  margin-top: 60px;
  line-height: 68px;
  font-family: "Montserrat";

  font-size: 85px;
  line-height: 1em;
  font-family: reddit sans, Sans-serif;
  text-transform: capitalize;
}

.landing-page h1.solution-txt span {
  display: block;
  font-weight: 300;
}

.landing-page .left-element1 {
  width: 90px;
  height: 80px;
  background: url(../../assets/images/LandingpageImage/left-element-brick.png)
    no-repeat;
  position: absolute;
  top: 163px;
  left: 20px;
  z-index: 9;
}

.landing-page .left-element2 {
  width: 90px;
  height: 80px;
  background: url(../../assets/images/LandingpageImage/left-element-brick.png)
    no-repeat;
  position: absolute;
  bottom: 55px;
  left: 20px;
  z-index: 9;
}

.landing-page .top-lamp {
  width: 80px;
  height: 148px;
  background: url(../../assets/images/LandingpageImage/banner-top-lamp.png)
    no-repeat;
  position: absolute;
  top: 0px;
  z-index: 11;
  /* right: 95px; */
  left: 45%;
}

.landing-page .wht-qstn-mark {
  width: 107px;
  height: 168px;
  background: url(../../assets/images/LandingpageImage/home-question.svg)
    no-repeat;
  /* background: url(../../assets/images/LandingpageImage/banner-question-mark.png) no-repeat; */
  position: absolute;
  top: 26%;
  z-index: 9;
  right: 55%;
}

.landing-page .book-demo-btn {
  margin-top: 40px;
  display: flex;
  align-items: center;
}

.landing-page .book-demo-btn button {
  background: #fc6853;
  border-radius: 40px;
  padding: 10px 20px;
  color: #fff;
  text-decoration: none;
  font-weight: 500;
  font-size: 16px;
  box-shadow: 2px 5px 1px -1px rgba(202, 50, 45, 1);
  position: relative;
  border: none;
  /* transition: all 0.5s; */
  transition: box-shadow 0.5s ease-in-out;
  border: 2px solid transparent;
}

.landing-page .book-demo-btn button:hover {
  box-shadow: none;
  background: #5c3cbf;
  border: 2px solid #fff;
}
.con-btn:hover{
border: 2px solid #fff;
transition: 0.5s ease-in-out;
}
.landing-page .book-demo-btn button:hover .button__shadow {
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
}

.landing-page .button__shadow {
  width: calc(100% + 0.4rem);
  height: 52px;
  background-color: #fdb3b1;
  border-radius: 68px;
  position: absolute;
  bottom: -8px;
  left: -1px;
  z-index: -2;
  transition: all 0.5s;
  /* transition: width 0.5s ease-in-out, height 0.5s ease-in-out, top 0.5s ease-in-out, left 0.5s ease-in-out;  */
}

.landing-page .arrow-spacing {
  margin-left: 10px;
}

.landing-page .arrow-spacing img {
  width: 25px;
}

.landing-page .resp-meeting {
  display: none;
}

.landing-page .discover-bg {
  width: 100%;
  background: url(../../assets/images/LandingpageImage/top-frame-texture.png);
  background-repeat: no-repeat;
  background-size: contain;
  height: 100%;
  position: relative;
}

.landing-page .features-section {
  margin-top: 80px;

  margin-top: 110px;
  /* --bs-gutter-x: 60px; */
}

.landing-page .features-section .row {
  display: flex;
  justify-content: center;
}

.landing-page .features-section .col-12 {
  padding: 0 30px;
}

.landing-page .leading-brand .section-heading {
  text-align: center;
  margin: auto;
  font-size: 45px;
}

.landing-page h2.section-heading {
  font-size: 51px;
  font-weight: 700;
  color: #000;
  width: 56%;
  font-family: "Montserrat";

  font-size: 60px;
  font-family: reddit sans, Sans-serif;
  line-height: 68px;
}

.landing-page .brand-logo {
  text-align: center;
  background-color: #f7f4ff;
  padding: 20px 13px;
  border: 1px solid #f7f4ff;
  border-radius: 11px;
  margin-bottom: 24px;
}

.landing-page .feature-heading {
  color: #5c3cbf;
  font-size: 28px;
  line-height: 35px;
  font-family: "Montserrat;", sans-serif;
  font-weight: 600;
  margin-bottom: 25px;

  font-family: reddit sans, Sans-serif;
}

.landing-page .client-section {
  margin-top: 45px;
}

.landing-page .features-box {
  padding: 121px 20px 99px 20px;
  background: linear-gradient(
    90deg,
    rgba(244, 238, 240, 1) 0%,
    rgba(209, 231, 244, 1) 100%
  );
  position: relative;
  /* margin: 0px 15px; */
  min-height: 484px;
}

.landing-page .features-box:last-child {
  margin-right: 0;
}

.landing-page .section-icon img {
  width: 80px;
}

.landing-page .lets-explore .section-icon img {
  width: 60px;
}

.landing-page .section-icon {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  width: 120px;
  height: 120px;
  background-color: #fc6853;
  border-radius: 50%;
  top: -34px;
  /* top: -170px; */
  right: 29px;
  border: 2px solid #ffffff;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.2), 0 2px 6px 0 rgba(0, 0, 0, 0.19) !important;
  z-index: 9;
}

.landing-page .features-box:hover .section-icon {
  border: 2px solid #f96853;
}

.landing-page .circle-bg {
  border-radius: 50%;
  transition: background 0.5s linear;
  border: 2px solid #ffffff;
  backface-visibility: hidden;
  position: absolute;
  top: -34px;
  /* top: -170px; */
  right: 29px;
  width: 120px;
  height: 120px;
  display: block;
  z-index: 10;
}

.landing-page .features-box:hover .circle-bg {
  border-color: transparent;
  background: transparent;
  animation: circleround cubic-bezier(0.17, 0.13, 0.28, 1) 1s;
  animation-fill-mode: forwards;
  border-right-color: #5c3cbf;
  border-bottom-color: #5c3cbf;
}

@keyframes circleround {
  0% {
    transform: rotate(-90deg);
    opacity: 1;
  }

  50% {
    opacity: 1;
  }

  100% {
    transform: rotate(360deg);
    opacity: 0;
  }
}

/* .discover-bg::before{
    content: "";
    position: absolute;
    top: 0;
    right: 0;
} */

.landing-page .section-heading span {
  /* color: #5c3cbf; */
  font-weight: 300;
  display: block;
}

/* .explore-headding {
    width: 40% !important;
    position: relative;
} */

/* .landing-page .lets-explore::before {
  content: "";
  width: 147px;
  height: 925px;
  background: url(../../assets/images/LandingpageImage/explore-right-bg.png)
    no-repeat;
  position: absolute;
  right: 0;
} */
.key-features-bg {
  position: absolute;
  right: 0;
  top: 60px;
  width: 118px;
}
.landing-page .zigzag {
  width: 200px;
  height: 60px;
  /* background: url(../../assets/images/LandingpageImage/zigzag-element.png) no-repeat; */
  position: absolute;
  z-index: 9;
  left: 50%;
  top: 0;
}

.landing-page .discover-section {
  position: relative;
}

.landing-page .discover-section .discover-bg-1 {
  position: absolute;
  top: 165px;
  width: 130px;
  /* top: 180px;
    width: 13%; */
}

/* .discover-bg::before {
    content: "";
    background: url(../../assets/images/LandingpageImage/section-2-bg.png) no-repeat;
    position: absolute;
    width: 100%;
    height: 100%;
    left: -18px;
    top: 158px;
} */

.landing-page .leading-brand {
  position: relative;
  float: left;
  width: 100%;
  padding-top: 0px;
  padding-bottom: 40px;
}

.landing-page .leading-brand .container::before {
  position: absolute;
  content: "";
  /* width: 45%; */
  width: 30%;
  height: 100%;
  /* background-image: linear-gradient(276deg, rgba(18, 18, 18, 0) 38.85%, #ffffff 100%); */
  background-image: linear-gradient(
    90deg,
    rgb(255 255 255 / 100%) 44.85%,
    #ffffff00 100%
  );
  top: 0;
  inset-inline-start: 0;
  transition: all 0.5s;
  z-index: 9;
  left: 0;
}

.landing-page .leading-brand .container::after {
  position: absolute;
  content: "";
  /* width: 45%; */
  height: 100%;
  width: 30%;
  /* background-image: linear-gradient(90deg, rgba(18, 18, 18, 0) 38.85%, #ffffff 100%); */
  background-image: linear-gradient(
    265deg,
    rgb(255 255 255 / 98%) 46.85%,
    #ffffff00 100%
  );
  top: 0;
  inset-inline-start: 0;
  transition: all 0.5s;
  z-index: 9;
  right: 0;
  left: auto;
}

.landing-page .question-answers {
  position: relative;
}

.landing-page .qa-bg img {
  position: absolute;
  /* background: url(../../assets/images/LandingpageImage/faq-bg.png);
    background-repeat: no-repeat;
    background-size: cover; */
  width: 100%;
  height: 100%;
  object-fit: cover;
  background-position: center center;
  z-index: -1;
  top: 0px;
  right: 0;
}

/* .qa-bg {
    position: absolute;
    background: url(../../assets/images/LandingpageImage/faq-bg.png);
    background-repeat: no-repeat;
    background-size: cover;
    width: 100%;
    height: 777px;
    background-position: center center;
    z-index: -1;
    top: -100px;
} */

.landing-page .faq-header {
  width: 100% !important;
  overflow: hidden;
}

.landing-page .question-answers .accordion-item {
  border: 1px solid #d4d9f1 !important;
  border-radius: 20px;
  margin-bottom: 25px;
  box-shadow: -23px -20px 11px -17px rgba(212, 217, 241, 0.34);
}

.landing-page button.accordion-button.faq-heading {
  font-size: 21px;
  line-height: 27px;
  background: transparent;
  padding: 20px !important;
  box-shadow: none !important;
  color: #2b2d6c !important;
  font-weight: 700;
  display: flex;
  justify-content: space-between;
}

.landing-page .question-answers .accordion-body {
  font-size: 17px;
  line-height: 30px;
  font-weight: 500;
  color: #000000;
  padding-left: 30px;
  padding-right: 30px;
  padding-top: 0;
}

.landing-page p.explore-txt {
  margin-top: 20px;
  width: 73%;
  font-size: 17px;
}

.landing-page span.icons {
  display: flex;
  height: 70px;
  width: 70px;
  border-radius: 50%;
  justify-content: center;
  align-items: center;
  background: #ff725e;
  position: relative;
}

.landing-page .contact-details span.icons img {
  filter: invert(1);
}

.landing-page .phone-icon {
  width: 50px;
}

.landing-page .mail-icon {
  width: 45px;
}

.landing-page .location-icon {
  width: 38px;
}

.landing-page ul.contact-details {
  padding-left: 0px;
  margin-top: 3rem;
  margin-bottom: 0;
}

.landing-page .contact-details li .icons {
  position: absolute;
  left: 0;
  top: 4px;
}

.landing-page ul.contact-details li {
  position: relative;
  padding-left: 100px;
  font-size: 18px;
  line-height: 26px;
  font-weight: 400;
  margin-bottom: 35px;
  list-style: none;
  padding-top: 10px;
}

.landing-page .list-heading {
  display: block;
  color: #5c3cbf;
  font-weight: 600;
  font-size: 18px;
}

.landing-page .contact-details p a {
  text-decoration: none;
  color: #212529;
  font-size: 18px;
}

.landing-page .contact-details p {
  font-size: 20px;
  font-weight: 500;
  cursor: pointer;
}

.landing-page p.font-15 {
  font-size: 18px !important;
}

.landing-page .animation-sec {
  margin-left: 150px;
  margin-right: 150px;
}

.landing-page .question-answers .accordion-button::after {
  background-image: url(../../assets/images/LandingpageImage/acc-down-arrow.png);
  background-size: cover;
  width: 27px;
}

.landing-page .question-answers .accordion-button:not(.collapsed)::after {
  background-image: url(../../assets/images/LandingpageImage/acc-up-arrow.png);
  transform: rotateY(-180deg);
  background-size: cover;
  width: 27px;
}

.landing-page .discover-bg h2.section-heading {
  width: 60%;
}

.landing-page .contact-details li:hover img {
  animation: smoothShake 0.5s ease;
}

/* .features-section .features-box:hover img {
    animation: smoothShake 0.5s ease;
} */

.landing-page .features-section .features-box:hover .section-icon {
  background-color: #ffffff;
  transition: all ease-in-out 0.3s;
}

.landing-page .features-section .features-box:hover .section-icon img {
  filter: invert(52%) sepia(67%) saturate(596%) hue-rotate(323deg)
    brightness(97%) contrast(95%);
}

@keyframes smoothShake {
  0% {
    transform: translateX(0);
  }

  20% {
    transform: translateX(-2px);
  }

  40% {
    transform: translateX(2px);
  }

  60% {
    transform: translateX(-1px);
  }

  80% {
    transform: translateX(1px);
  }

  100% {
    transform: translateX(0);
  }
}

.landing-page .image-bg {
  background-color: #f7f4ff;
  /* padding: 10px 35px; */
  border-radius: 10px;
  padding: 7px 30px;
}

.landing-page .landing-page .features-box:hover .corner {
  width: 100%;
  height: 100%;
  transition: all 0.3s ease;
  /* border: 3px solid #f96853; */
}

.landing-page .corner {
  position: absolute;
  width: 236px;
  height: 100px;
  border: 3px solid #000;
  /* transition: 0.2s; */
  transition: all 0.3s ease;
}

/* .corner:hover {    
    width: 100%;
    height: 100%;
    z-index: -1;
} */

.landing-page .top-left {
  /* top: -16px;
    left: -16px; */
  top: -10px;
  left: -10px;
  border-right: none;
  border-bottom: none;
  width: 200px;
}

.landing-page .top-right {
  top: -10px;
  right: -10px;
  border-left: none;
  border-bottom: none;
  width: 45px;
}

.landing-page .bottom-left {
  bottom: -10px;
  left: -10px;
  border-right: none;
  border-top: none;
  height: 200px;
  width: 100px;
}

.landing-page .bottom-right {
  bottom: -10px;
  right: -10px;
  border-left: none;
  border-top: none;
  width: 175px;
  height: 118px;
}

/* @media (max-width: 768px) {
    .container {
        flex-direction: column;
    }

    .section {
        width: 80%;
        margin: 20px 0;
    }
} */
.landing-page .process-vdo {
  /* height: 460px; */
  height: 600px;
  width: 100%;
  /* margin: 0px 204px; */
  position: relative;
  /* background-color: #cfbbf5; */
  border-radius: 20px;
  margin-top: 30px;
  margin-bottom: 55px;
  overflow: hidden;
  /* box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px; */
}

.landing-page .section-heading.process-vdo-caption {
  /* width: 100%;
    text-align: center; */
  /* font-size: 32px !important; */
  font-size: 50px !important;
  text-align: left;
}
.landing-page .ml-330 {
  margin-left: 330px;
}
.landing-page .process-vdo video {
  object-fit: cover;
  display: block;
}

.landing-page .w-full {
  width: 100%;
}

.landing-page .h-full {
  height: 100%;
}
.landing-page .margin-p {
  margin: 40px 110px;
}
.landing-page .margin-p h5 {
  margin-bottom: 25px;
  line-height: 28px;
  font-weight: 600;
}


/* ------------Header-sticky------------ */
.landing-page .hidden-header{
  top: -100%;
}

body.sticky-header .hidden-header{
background-color: #fff;
position: fixed;
z-index: 9999;
top:0 !important;
transition: top .6s ease;
box-shadow: 0 0 6px #00000026;
}
body.sticky-header .landing-page .menu ul li {
  color: #000000;
}
body.sticky-header .landing-page .header-btn .book-demo-btn.line-btn button {
  background: transparent;
  border: 2px solid #fc6853;
  color: #fc6853;
}
body.sticky-header .landing-page .header-btn .book-demo-btn.line-btn button:hover{
  border: 2px solid  #5c3cbf;
}
body.sticky-header .landing-page .menu ul li::before {
  content: "";
  position: absolute;
  left: 0;
  bottom: -11px;
}




/* -------------------Footer Css Start ------------ */
.landing-page .p-top {
  padding-top: 150px;
}

.landing-page .footer {
  position: relative;
  width: 100%;
  float: left;
  overflow: hidden;
  display: block;
}

/* .footer .container {
    padding-left: 0;
    padding-right: 0;
} */
.landing-page .footer h2.footer-heading {
  font-size: 33px;
  font-weight: 700;
  line-height: 47px;
  width: 90%;
}

.landing-page .blue-text {
  color: #5c3cbf;
}

.landing-page .left-footer-bg {
  display: block;
  width: 55%;
  height: 68%;

  background: linear-gradient(to right, #f6eced, #d3e7f2);
  clip-path: polygon(0 0, 100% 100%, 0 100%);
  position: absolute;
  /* z-index: 1; */
  z-index: -1;
  left: 0;
  bottom: 0;
}

.landing-page .right-footer-bg {
  width: 86%;
  display: block;
  height: 100%;
  background: #5c3cbf;
  clip-path: polygon(0 0, 100% 130%, 0 100%);
  transform: rotateY(-180deg);
  position: absolute;
  right: 0;
  bottom: -1px;
  /* z-index: -1; */
  z-index: -2;
}

.landing-page .footer-meeting-bg-all {
  /* width: 735px; */
  width: calc(100% + 10.5rem);
  position: relative;
  top: -52px;
  /* right: 45px; */
  right: 15px;
}

.landing-page .footer-meeting-bg {
  width: 712px;
  position: absolute;
  top: 196px;
  right: 205px;
}

.landing-page .notify-box {
  margin-left: 230px;
  margin-top: 27px;
}

.landing-page .notify-1,
.landing-page .notify-2 {
  width: 260px;
  background-color: #fabb95;
  margin-bottom: 15px;
  border-radius: 5px;
  padding: 15px;
  box-shadow: 0px 0px 10px -5px rgb(49 51 68);
}

.landing-page .notify-2 {
  background-color: #beb4dd;
  margin-bottom: 0 !important;
  position: relative;
}

.landing-page .notify-2:after {
  content: "";
  position: absolute;
  top: 70px;
  left: 50%;
  transform: translatex(-50%);
  background-image: url(../../assets/images/LandingpageImage/flash-light2.png);
  height: 189px;
  width: 105px;
}

.landing-page .action-message {
  display: flex;
  align-items: center;
  gap: 11px;
}

.landing-page .action-message h6 {
  font-weight: 600;
}

.landing-page .white-circle {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 40px;
  width: 40px;
  border-radius: 50%;
  background-color: #fff;
}

.landing-page .white-circle::before {
  content: "1";
  position: absolute;
  top: 6px;
  right: 4px;
  height: 13px;
  width: 13px;
  border-radius: 50%;
  background-color: #db3333;
  display: flex;
  align-items: center;
  text-align: center;
  font-size: 10px;
  justify-content: center;
  color: #fff;
  font-weight: 500;
}

.landing-page .footer-white {
  position: absolute;
  right: -227px;
  bottom: 44px;
  z-index: -1;
  opacity: 0.08;
}

.landing-page .footer-bottom {
  position: relative;
  bottom: 45px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  z-index: 999;
  margin-top: 0px;
}

.landing-page .right-bottom p {
  font-size: 14px;
  font-weight: 500;
  color: #fff;
}

.landing-page .right-bottom ul {
  margin: 0;
  padding-left: 0;
  display: flex;
  gap: 15px;
}

.landing-page .right-bottom ul li a {
  list-style: none;
  font-size: 14px;
  color: #fff;
  font-weight: 400;
  text-decoration: none;
  cursor: pointer;
}

.landing-page .social-boxes {
  /* margin-bottom: 50px; */
  display: flex;
  gap: 15px;
}

.landing-page .s-box {
  height: 37px;
  width: 37px;
  background-color: #fff;
  border: 3px solid #2b2d6c;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  transition: all 0.8s;
  cursor: pointer;
}

.landing-page .s-box img {
  width: 20px;
  transition: all 0.8s;
}

.landing-page .s-box:hover img {
  transform: rotate(360deg);
}

.landing-page .s-box:hover {
  transform: scale(1.1);
  border: 3px solid #e66e0c;
}

/* -------------------Footer Css End------------ */

/* -----------priyanka-------------- */

.landing-page .key-features-left {
  /* background-color: #5c3cbf;
    width: 100%;
    height: 650px;
    float: left;
    position: relative; */
  background-color: #5c3cbf;
  width: 40%;
  height: 650px;
  float: left;
  position: absolute;
  z-index: 0;
}

.landing-page .key-features-left::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  background: url(../../assets/images/LandingpageImage/blue-line-bg.png);
  width: 100%;
  height: 100%;
}

.landing-page .lets-explore {
  /* overflow: hidden; */
  position: relative;
}

.landing-page .lets-explore .features-sub-heading {
  min-height: 200px;
}

.landing-page .create-meeting-ss img {
  width: 700px;
}

.landing-page .key-features-swiper {
  width: 100%;
  position: relative;
  /* padding: 0 0 0 170px; */
  z-index: 1;
}

.landing-page .key-features-right {
  justify-content: center;
  align-items: center;
  /* margin-left: -300px;
    padding-right: 320px; */
}

.landing-page .lets-explore .section-icon {
  top: 0;
  left: 40px;
}

.landing-page .person-keyfeatures {
  position: absolute;
  bottom: 8px;
  z-index: 9;
  left: -300px;
}

.landing-page .tree-img {
  position: absolute;
  bottom: 56px;
  z-index: 9;
  left: 227px;
}

.landing-page .slide-content {
  padding-left: 180px;
}

.landing-page .create-meeting-ss {
  /* position: absolute;
    left: -550px;
    top: -70px; */
  margin-top: 70px;
}

.landing-page .prev-icon-img {
  position: absolute;
  left: 150px;
  top: 50px;
}

.landing-page .next-icon-img {
  position: absolute;
  right: 170px;
  top: 50px;
}

.landing-page .lets-explore .swiper-horizontal > .swiper-pagination-bullets,
.landing-page
  .lets-explore
  .swiper-pagination-bullets.swiper-pagination-horizontal,
.landing-page .lets-explore .swiper-pagination-custom,
.landing-page .lets-explore .swiper-pagination-fraction {
  bottom: 0 !important;
  left: 0;
  rotate: 180deg;
}

.landing-page .lets-explore .swiper-pagination-bullet {
  /* background: #ded9ef !important; */
  background: #aa92f1 !important;
  opacity: var(--swiper-pagination-bullet-inactive-opacity, 1) !important;
}

.landing-page .lets-explore .swiper-pagination-bullet-active {
  opacity: var(--swiper-pagination-bullet-opacity, 1);
  background: #6242c5 !important;
}

.landing-page
  .lets-explore
  .swiper-horizontal
  > .swiper-pagination-bullets
  .swiper-pagination-bullet,
.landing-page
  .lets-explore
  .swiper-pagination-horizontal.swiper-pagination-bullets
  .swiper-pagination-bullet {
  margin: 0 var(--swiper-pagination-bullet-horizontal-gap, -50px) !important;
  width: 15px !important;
  height: 15px !important;
}

/* .lets-explore .keyfeatures-prev::after {
    content: ' ';
    position: absolute;
    background: url(../../assets/images/LandingpageImage/next-icon-img.png) no-repeat;
    width: 64px;
    height: 64px;
    background-size: cover;
    right: 150px;
    top: 50px;
}

.lets-explore .keyfeatures-next::after {
    content: ' ';
    position: absolute;
    background: url(../../assets/images/LandingpageImage/prev-icon-img.png) no-repeat;
    width: 64px;
    height: 64px;
    background-size: cover;
    left: 120px;
    top: 50px;
} */

.landing-page .swiper-pagination {
  position: relative !important;
  width: 100% !important;
  display: flex;
  align-items: center;
  justify-content: center;
}

.landing-page .swiper-pagination:before {
  content: "";
  height: 3px;
  width: 80%;
  position: absolute;
  background-color: #e6e6e6;
  /* left: 0; */
  left: 25px;
  top: 5px;
  z-index: -1;
}

.landing-page .next-prev-main-div {
  /* padding: 0 275px;
    top: -115px;
    z-index: 99;
    left: -55px; */
  top: -100px;
  z-index: 99;
  width: 553px;
  float: right;
}

.landing-page .next-prev-sub-div .text {
  font-weight: 700;
}

/* .key-features-left img {
    width: 330px;
} */

/* -----------priyanka-------------- */

/* ------------------------------ */

.landing-page .lets-explore-center .explore-headding {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.landing-page .lets-explore .nav-tabs .nav-item.show .nav-link,
.landing-page .nav-tabs .nav-link.active {
  color: #5c3cbf !important;
  background-color: transparent !important;
  border-bottom: 2px solid #5c3cbf !important;
  border-top: none !important;
  border-left: none !important;
  border-right: none !important;

  border: none !important;
  /* transition: all 0.5s ease; */
}

.landing-page .lets-explore .nav-tabs .nav-link {
  border: none;
  position: relative;
}

.landing-page .description ul li,
.terms-conditions ul li {
  padding-bottom: 20px;
  font-size: 18px;
  font-family: "Plus Jakarta Sans", sans-serif;
  font-weight: 500;

  display: flex;
align-items: center;
}

.landing-page .nav-wrapper {
  overflow-x: auto;
  white-space: nowrap;
  scrollbar-width: none;
  -ms-overflow-style: none;
}

.landing-page .nav-wrapper::-webkit-scrollbar {
  display: none;
}

.landing-page .nav-tabs {
  display: flex;
  flex-wrap: nowrap;
  overflow-x: scroll;
  overflow-y: hidden;
}

.landing-page .nav-tabs::-webkit-scrollbar {
  height: 6px !important;
  border-radius: 50px !important;
}

.landing-page .nav-tabs::-webkit-scrollbar-thumb {
  background: #5c3cbf !important;
  border-radius: 50px !important;
}

.landing-page .nav-tabs::-webkit-scrollbar-track {
  background-color: transparent !important;
  border-radius: 50px !important;
}

.landing-page .nav-link {
  display: inline-block;
  padding: 10px 20px;
  cursor: pointer;
  white-space: nowrap;
}

@media (max-width: 768px) {
  .landing-page .nav-wrapper {
    width: 100%;
  }
}

.landing-page .lets-explore .nav-link:focus {
  color: #5c3cbf;
}

.landing-page .lets-explore .nav-link:hover {
  color: #949da6;
}

.landing-page .nav-link {
  color: #949da6;
  font-weight: 600;
  font-size: 20px;
  font-family: "Montserrat" !important;
  text-transform: capitalize;
}

.landing-page .outer-features-box {
  height: auto;
  visibility: visible;
  opacity: 1;
  padding-top: 20px;
}

.landing-page .lets-explore .tab-content {
  margin-top: 30px;
}

.landing-page .outer-features-box .demo {
  width: 609px;
  max-width: 100%;
  text-align: center;
}

.landing-page .subtitle {
  font-size: 25px;
  line-height: 35px;
  font-weight: 700;
  margin-bottom: 20px;
}

.landing-page .demo-vdo {
  display: flex;
  justify-content: center;
  /* background: #f8f6ff; */
  border-radius: 9px;
  
}
.landing-page .discover-bg-2{
  width: 110px;
  position: absolute;
  bottom: 0;
  left: 0;
}
.landing-page .demo-vdo img {
  /* width: 700px; */
  width: 100%;
  border-radius: 6px;
}
.green-check {
  width: 30px;
  position: absolute;
  right: 5px;
  top: 15px;
}
.landing-page .section.terms-conditions {
  padding: 100px 0;
}

.landing-page .terms-conditions .content p {
  margin-bottom: 20px;
}

.landing-page .terms-conditions .content {
  padding-top: 20px;
}

.landing-page .terms-conditions h2.section-heading {
  width: 60%;
}

.landing-page .terms-conditions .section-heading span {
  display: inline-flex;
}
/* 
.inner-footer.footer .p-top {
    padding-top: 100px;
} */

.landing-page .inner-header .menu ul li a {
  color: #121212;
}

.landing-page .inner-header .header-btn .book-demo-btn.line-btn button {
  background: transparent;
  border: 2px solid #fc6853;
  color: #fc6853;
}

.landing-page .inner-header .header-btn .book-demo-white .arrow-spacing img {
  filter: invert(52%) sepia(67%) saturate(596%) hue-rotate(323deg)
    brightness(97%) contrast(95%);
}

.landing-page .inner-header {
  border-bottom: 1px solid #ddd;
}

/*------ Leading Brands CSS---------- */

.landing-page .leading-brand .swiper-wrapper {
  transition-timing-function: linear !important;
}

.landing-page .leading-brand .swiper-container {
  width: 100%;
  height: 100%;
  position: relative;
  overflow: hidden;
}

&:after {
  content: "";
  display: block;
  width: 100%;
  background-size: 10% 1px;
  background-position: -50px;
  background-repeat: repeat-x;
  position: absolute;
  left: -10%;
  top: 40%;
  animation: line-slide 60s forwards infinite linear;
  z-index: 1;
}

& .leading-brand .swiper--bottom {
  &:after {
    animation-direction: reverse;
  }
}

.landing-page .leading-brand .swiper-slide {
  text-align: center;
  font-size: 33px;
  background: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  width: auto;
  position: relative;
  overflow: hidden;
  /* padding: 0 10px; */
  padding: 10px 7px;
}

@keyframes swiper-container {
  0% {
    background-position: -5% 0;
  }

  100% {
    background-position: 100% 0;
  }
}

.landing-page .swiper--top,
.landing-page .swiper--bottom {
  margin-top: 24px;
}

.landing-page .swiper--top .swiper-slide,
.landing-page .swiper--bottom .swiper-slide {
  display: flex;
  justify-content: center;
  align-items: center;
  width: auto;
}

.landing-page .image-bg {
  max-width: 100%;
  height: auto;
}

/* .line-menu-icon {
    width: 35px;
    height: 25px;
    display: block;
    position: relative;
    background-color: transparent;
    border: 0;
}

.line-menu-icon span:first-of-type {
    top: 50%;
    transform: translateY(-50%);
    width: 78%;
}

.line-menu-icon span:nth-of-type(2) {
    top: 0;
    transform: translateZ(0) rotate(0) scaleX(1);
    width: 100%;
}

.line-menu-icon span:nth-of-type(3) {
    bottom: 0;
    transform: translateZ(0) rotate(0) scaleX(1);
    width: 50%;
}

.line-menu-icon span {
    display: block;
    position: absolute;
    right: 0;
    left: 0; 
    height: 2px;
    transition: all .35s ease-in-out 0s;
    background-color: #5b3bbd;
} */

.landing-page .hamberger {
  position: fixed;
  z-index: 2147483647;
  right: -100%;
  top: 0px;
  width: 100%;
  height: 100vh;
  min-height: max-content;
  /* background: #DFE3EC; */
  background: #f8fbff;
  /* background: #f7f7f7; */
  padding: 0px 4vw;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: 0.5s ease-in-out 0.4s;
  overflow: hidden;
}

/* ----------------------contact us modal------------------ */

.landing-page .modal-content .contact-form {
  padding: 0;
  /* border-radius: 0 30px 30px 0; */
  box-shadow: none;
  width: 533px;
  margin: 0 auto;
  float: none;
}

.landing-page .otp-send-email {
  font-size: 13px;
  color: #10a300;
  line-height: 13px;
  margin-top: 7px !important;
}

.landing-page .contact-form {
  width: 100%;
  float: left;
  /* background: #ffffff; */
  box-shadow: rgb(17 17 26 / 10%) 0px 0px 16px;
  border-radius: 20px;
  margin-top: 80px;
  padding: 25px;
}

.landing-page .form-floating .form-control {
  /* background: #f7f6fd; */
  background: #ffff;
  border: 1px solid #d1c8da;
}

.landing-page .form-control {
  width: 100%;
}

.landing-page .form-floating input:focus,
.landing-page .form-floating select:focus,
.landing-page .form-floating textarea:focus {
  box-shadow: 0 0 0 0.125rem rgb(12 131 255 / 70%);
}

.landing-page .modal-content {
  border-radius: 10px;
}

.landing-page .form-control-feedback-icon {
  left: 10px;
  position: absolute;
  /* top: 50%; */
  top: 28px;
  transform: translateY(-50%);
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  z-index: 5;
}

.landing-page .form-floating > label {
  position: absolute;
  top: 0;
  left: 30px;
  height: 100%;
  padding: 1rem 0.75rem;
  pointer-events: none;
  border: 1px solid transparent;
  transform-origin: 0 0;
  transition: opacity 0.1s ease-in-out, transform 0.1s ease-in-out;
  color: #c5bfd2;
}

.landing-page .form-floating .form-control,
.landing-page .form-floating .form-select {
  padding-left: 40px;
}

.landing-page .for-overflow-text input{
  padding-right: 80px;
}

.landing-page .contact-heading .btn-close {
  box-shadow: none;
}

.landing-page .ph-user {
  background: url(../../assets/images/LandingpageImage/users.png);
  background-size: contain;
}

.landing-page .ph-phone {
  background: url(../../assets/images/LandingpageImage/telephone.png);
  background-size: contain;
}

.landing-page .ph-email {
  background: url(../../assets/images/LandingpageImage/mail1.png);
  background-size: contain;
}

.landing-page .ph-message {
  background: url(../../assets/images/LandingpageImage/list1.png);
  background-size: contain;
}

.landing-page .ph-otp {
  background: url(../../assets/images/LandingpageImage/otp-icon3.png);
  background-size: contain;
}

.landing-page .ph {
  width: 24px;
  height: 24px;
  background-repeat: no-repeat;
}

.landing-page .feedback-icon-message .form-control-feedback-icon {
  top: 30px;
}

.landing-page .verify-email {
  position: absolute;
  right: 13px;
  top: 15px;
  border-radius: 4px;
  cursor: pointer;
}

.landing-page .req-image {
  width: 100%;
  float: left;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  padding-top: 20px;
}

.landing-page .req-image img {
  width: 287px;
}

.landing-page .contact-subheading p {
  text-align: center;
  font-weight: 600;
  padding-top: 46px;
}

.landing-page .contact-heading h5 {
  font-size: 25px;
  font-weight: 600;
}

.landing-page .contact-form .book-demo-btn button {
  background: #fc6853;
  border-radius: 40px;
  padding: 5px 15px;
  color: #fff;
  text-decoration: none;
  font-weight: 500;
  font-size: 18px;
  box-shadow: 1px 6px 1px -1px rgba(202, 50, 45, 1);
  position: relative;
  border: none;
  transition: all 0.5s;
  font-family: "Roboto";
  width: 70%;
  margin: 0 auto;
}

.landing-page
  .form-floating
  > .form-control:not(:placeholder-shown)
  ~ label::after {
  background-color: transparent;
}

.landing-page .contact-form .arrow-spacing img {
  width: 22px;
}

.landing-page .modal-lg,
.landing-page .modal-xl {
  --bs-modal-width: 700px;
}

/* ---------------------Monalisha-------------------------------- */
.landing-page .step {
  position: absolute;
  left: 50%;
  transform: translate(-50%, -50%);
  top: 50%;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.landing-page .modal-inside {
  max-width: 980px;
  /* max-width: calc(100% - 30%); */
  height: 620px;
  width: 100%;
  position: relative;
  border-radius: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.landing-page #salesForm {
  position: relative;
  width: 100%;
  height: 100%;
  /*overflow: hidden; */
}
.landing-page #salesForm .row {
  height: 100%;
}
.landing-page .modal-inside .modal-content {
  border-radius: 10px;
  height: 100%;
  overflow: hidden;
}

.landing-page .modal-inside .modal-body {
  position: relative;
}

.landing-page .bg-img {
  bottom: -43px;
  right: -65px;
  background-repeat: no-repeat;
  position: absolute;
  /* height: 100%; */
  width: 69%;
  /* Z-INDEX: 1; */
  opacity: 0.2;
  rotate: 92deg;
}

.landing-page .modal-inside .modal-content .contact-form {
  border-radius: 0;
}

.landing-page .modal {
  width: 100%;
  min-height: 100vh;
  max-height: 100vh;
  overflow-y: scroll;
  position: fixed;
  left: 0;
  top: -1080px;
  z-index: 999;
  background-color: rgb(59 61 80 / 0.5);
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.3s ease;
  display: flex;
  align-items: center;
  justify-content: center;
}

.landing-page .text-bg-success {
  /* color: #fff !important;
    background-color: RGBA(25, 135, 84, var(--bs-bg-opacity, 1)) !important; */
  color: #fff !important;
  background-color: #5c3cbf !important;
  padding: 7px 11px;
  font-size: 14px;
  font-weight: 600;
}

.landing-page .modal.show {
  opacity: 1;
  visibility: visible;
  top: 0;
  display: flex !important;
  z-index: 99999999;
}

.landing-page .modal-backdrop.show {
  z-index: 9;
}

.landing-page #exampleModalLabel {
  color: #5c3cbf;
}

.landing-page .modal-header {
  background: #f2f2f2;
}

.landing-page .step.out {
  animation: fadeOutRight 0.4s ease-out forwards;
}

@keyframes fadeOutRight {
  0% {
    opacity: 1;
    left: 50%;
  }

  100% {
    opacity: 0;
    left: -100%;
  }
}

.landing-page .step.in {
  animation: fadeInLeft 0.4s ease-out forwards;
}

@keyframes fadeInLeft {
  0% {
    opacity: 0;
    left: 100%;
  }

  100% {
    opacity: 1;
    left: 50%;
  }
}
.landing-page .modal-inside .book-demo-btn {
  margin-top: 0;
  border-top: 1px solid #ddd;
  padding: 8px 35px;
}
.landing-page .modal-inside .button__shadow {
  width: calc(100% + 3px);
  height: 47px;
  background-color: #fdb3b1;
  border-radius: 68px;
  position: absolute;
  bottom: -10px;
  left: -1px;
}
.landing-page .modal-body {
  background: #fff;
}
.landing-page .about-area-bg-shape {
  position: absolute;
  left: 326px;
  top: 223px;
  z-index: 1;
  opacity: 0.5;
}
.landing-page .about-area-bg-shape img {
  max-width: 100%;
  height: auto;
}

.landing-page .date-container {
  display: flex;
  flex-direction: row;
  justify-content: end;
  padding: 25px 25px 0 0;
}
.landing-page .pick-time {
  position: relative;
  float: right;
  text-align: end;
  /* display: flex; */
  /* overflow-y: scroll; */
  align-items: stretch;
  justify-content: end;
  width: 137px;
}

.landing-page .pick-time button {
  padding: 11px 16px;
  margin: 4px;
  border: none;
  color: #000000;
  background-color: transparent;
  border-radius: 10px;
  border: 1px solid rgb(92, 60, 191);
  outline: none;
  cursor: pointer;
  font-size: 14px;
  opacity: 1;
  min-width: 98px;
  transition: all 0.3s;
}
.landing-page .pick-time button:hover {
  color: #fff;
  background-color: rgb(92, 60, 191);
}
.landing-page .text-light-grey {
  margin: 5px 0 0px 0;
  color: #5a5a5a;
  font-size: 16px;
}
.landing-page .modal-data {
  display: flex;
  align-items: baseline;
  gap: 10px;
  margin-bottom: 10px;
  font-weight: 600;
  color: #6b6b6b;
}
.landing-page .m-text {
  font-size: 16px;
  line-height: 26px;
}
.landing-page .modal-left-dtl {
  height: 100%;
  position: relative;
  padding: 25px 0 0 26px;
}
.landing-page .modal-left-dtl::before {
  position: absolute;
  content: "";
  height: 100%;
  width: 1px;
  background-color: #ddd;
  right: -16px;
  top: 0;
}

.landing-page .verify-email-grn-btn {
  background-color: green;
}

.landing-page .bg_light {
  background-color: #f9fcff ;
  padding: 90px 0;
}
/* .landing-page .lets-explore .nav-tabs .nav-item.show .nav-link, .landing-page .nav-tabs .nav-link.active:after {
  content: '';
  width: 0;
  opacity: 0;
  height: 2px;
  background-color: #614efa;
  position: absolute;
  top: -1px;
  left: 50%;
  transform: translateX(-50%);
  transition: all 0.5s ease;
}
.landing-page .lets-explore .nav-tabs .nav-item.show .nav-link, .landing-page .nav-tabs .nav-link.active:after {
  width: 100%;
  opacity: 1;
} */
/* .landing-page .outer-nav-wrapper{
  overflow-x: auto;
    white-space: nowrap;
    scrollbar-width: none;
    -ms-overflow-style: none;
    position: relative;
} */
/* -----------Responsive Code---------- */
@media only screen and (max-width: 1919px) {
  /* .meeting {
        bottom: 150px;
    } */

  .landing-page .discover-section .discover-bg-1 {
    width: 220px;
  }
}

@media only screen and (max-width: 1800px) {
  .landing-page .left-footer-bg {
    height: 60%;
  }

  .landing-page .footer-meeting-bg-all {
    right: 60px;
  }

  .landing-page .brx-popup.active-contact .brxe-div,
  .landing-page .book-demo-form.active-demo .brxe-div {
    bottom: 120px;
  }

  .landing-page .discover-section .discover-bg-1 {
    width: 160px;
  }
}

@media only screen and (max-width: 1600px) {
  /* .left-footer-bg {
        width: 60%;
        height: 55%;
    } */
  .key-features-bg {
    top: 146px;
    width: 90px;
  }
  .landing-page .about-area-bg-shape {
    left: 210px;
  }

  .landing-page .margin-p {
    margin: 40px 80px;
  }

  .landing-page .image-bg {
    /* padding: 10px 45px; */
    padding: 7px 28px;
  }

  .landing-page .leading-brand .section-heading {
    font-size: 40px;
  }

  .landing-page .left-footer-bg {
    width: 60%;
    height: 50%;
  }

  .landing-page .right-footer-bg {
    height: 100%;
  }

  .landing-page .footer-meeting-bg-all {
    /* width: 690px;
        right: 120px; */
    right: 115px;
  }

  .landing-page .brx-popup.active-contact .brxe-div,
  .landing-page .book-demo-form.active-demo .brxe-div {
    bottom: 45px;
  }

  .landing-page .discover-section .discover-bg-1 {
    width: 130px;
    top: 167px;
  }

  .landing-page .lets-explore .swiper-horizontal > .swiper-pagination-bullets,
  .landing-page
    .lets-explore
    .swiper-pagination-bullets.swiper-pagination-horizontal,
  .landing-page .lets-explore .swiper-pagination-custom,
  .landing-page .lets-explore .swiper-pagination-fraction {
    width: 27%;
  }

  .landing-page .footer-white {
    position: absolute;
    right: -227px;
    bottom: 44px;
    z-index: -1;
  }

  .landing-page .footer-bottom {
    position: relative;
    bottom: 35px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    z-index: 999;
    margin-top: 0px;
  }

  .landing-page .right-bottom p {
    font-size: 13px;
    font-weight: 500;
    color: #fff;
  }

  .landing-page .right-bottom ul {
    margin: 0;
    padding-left: 0;
    display: flex;
    gap: 15px;
  }

  .landing-page .right-bottom ul li a {
    font-size: 13px;
  }

  .landing-page .social-boxes {
    /* margin-bottom: 50px; */
    display: flex;
    gap: 15px;
  }

  .landing-page .s-box {
    height: 37px;
    width: 37px;
    border: 2.5px solid #2b2d6c;
  }

  .landing-page .s-box img {
    width: 20px;
  }

  /* .swiper-pagination:before {
        content: '';
        height: 3px;
        width: 98%;
        position: absolute;
        background-color: #e6e6e6;
        left: 2px;
        top: 8px;
        z-index: -1;
    } */

  .landing-page .menu ul li {
    padding: 10px;
  }

  .landing-page .right-banner {
    width: 46%;
  }

  .landing-page .meeting {
    left: -215px;
    top: 71%;
    transform: translateY(-71%);
    width: 80%;
  }

  .landing-page .lets-explore::before {
    /* right: -40px; */
    right: 0;
  }

  .landing-page .key-features-swiper {
    /* padding: 0 0 0 280px; */
    /* padding: 0 0 0 200px; */
  }

  .landing-page .nav-link {
    font-size: 18px;
    text-transform: capitalize;
  }
}

@media only screen and (max-width: 1500px) {
  .landing-page .left-content {
    padding-top: 60px;
  }

  .landing-page .right-banner {
    width: 49%;
  }

  .landing-page .meeting {
    left: -130px;
    width: 75%;
  }

  .landing-page .lets-explore::before {
    display: none;
  }
}

@media only screen and (max-width: 1536px) {
  .landing-page .left-footer-bg {
    width: 60%;
    height: 53%;
  }
  .key-features-bg {
    top: 421px;
    width: 64px;
  }
}

@media only screen and (max-width: 1480px) {
  .landing-page .footer-meeting-bg-all {
    /* width: 590px;
        right: 100px; */
    right: 90px;
    width: calc(100% + 7.5rem);
  }

  .landing-page .left-footer-bg {
    width: 60%;
    height: 48%;
  }

  .landing-page .menu ul li {
    font-size: 17px;
  }

  .landing-page .feature-heading {
    font-size: 25px;
    margin-bottom: 15px;
  }
}

@media only screen and (max-width: 1399px) {
  .landing-page .footer-meeting-bg-all {
    right: 80px;
    width: calc(100% + 8.5rem);
  }

  .landing-page .left-footer-bg {
    width: 64%;
    height: 48%;
  }

  .landing-page .footer .container {
    padding-left: 0;
    padding-right: 0;
  }

  .landing-page .left-content p {
    font-size: 22px;
  }

  .landing-page h1.solution-txt {
    font-size: 65px;
    margin-top: 45px;
  }

  .landing-page .discover-section .discover-bg-1 {
    width: 100px;
  }

  .landing-page .features-box h3 {
    font-size: 26px;
    min-height: 70px;
  }

  .landing-page .features-box p {
    min-height: 140px;
  }

  .landing-page .right-banner {
    width: 53%;
  }

  .landing-page .top-lamp{
    left: 35%;
  }
  .landing-page .meeting {
    /* width: 60%; */
    width: 77%;
    left: -100px;
  }

  .landing-page .wht-qstn-mark {
    right: 59%;
  }

  .landing-page .left-content p {
    font-size: 18px;
    line-height: 30px;
  }

  .landing-page .left-content {
    width: 69%;
  }

  .landing-page .person-keyfeatures {
    width: 266px;
    bottom: 9px;
    left: -250px;
  }

  /* .key-features-swiper {
        padding: 0 0 0 170px;
    } */

  .landing-page .next-prev-main-div {
    width: 500px;
  }

  .landing-page
    .lets-explore
    .swiper-horizontal
    > .swiper-pagination-bullets
    .swiper-pagination-bullet,
  .landing-page
    .lets-explore
    .swiper-pagination-horizontal.swiper-pagination-bullets
    .swiper-pagination-bullet {
    margin: 0 var(--swiper-pagination-bullet-horizontal-gap, -40px) !important;
  }

  .landing-page .key-features-right {
    flex-direction: column;
  }

  .landing-page .key-features-left {
    width: 100%;
    height: 405px;
  }

  .landing-page .next-prev-main-div {
    left: 50%;
    transform: translateX(-50%);
    float: none;
  }

  .landing-page .create-meeting-ss {
    margin-bottom: 30px;
  }

  .landing-page .lets-explore .features-sub-heading {
    min-height: 250px;
  }
}

@media only screen and (max-width: 1368px) {
  /* .person-keyfeatures {
        z-index: 9;
        left: 30px;
    } */
  /* .image-bg {
        background-color: #f7f4ff;
        padding: 15px 25px;
        border-radius: 7px;
    } */
    body.sticky-header .landing-page .menu ul li::before {
      bottom: -22px;
  }
  .landing-page .section {
    padding: 120px 0;
  }

  .landing-page ul.contact-details {
    margin-top: 2rem;
  }

  .landing-page .discover-bg h2.section-heading {
    width: 80%;
  }
  .line-h-55 {
    line-height: 55px !important;
  }
  .landing-page .features-section {
    margin-top: 80px;
  }
  /* .menu ul li {
        font-size: 17px;
        padding: 5px;
    } */

  .landing-page .social-boxes {
    gap: 12px;
  }

  /*--------------- prajakta-responsive start--------------------- */

  .landing-page h2.section-heading {
    font-size: 48px;
    line-height: 58px;
  }

  .landing-page .s-box {
    height: 32px;
    width: 32px;
  }

  .landing-page .s-box img {
    width: 18px;
  }

  .landing-page .footer h2.footer-heading {
    font-size: 29px;
  }

  .landing-page .book-demo-btn button {
    font-size: 14px;
  }

  .landing-page .footer-meeting-bg-all {
    right: 105px;
    width: calc(107% + 7.5rem);
  }

  .landing-page .left-footer-bg {
    width: 55%;
    height: 45%;
  }

  .landing-page .section-icon {
    width: 100px;
    height: 100px;
  }

  .landing-page .section-icon img {
    width: 60px;
  }

  .landing-page .features-box {
    padding: 90px 20px 90px 20px;
    min-height: 433px;
  }

  /* .button__shadow {
        height: 66px;
        bottom: -10px;
    } */

  /* .meeting {
        left: -200px;
        width: 530px;
    } */

  /*--------------- prajakta-responsive end--------------------- */

  /* .left-content p {
        font-size: 20px;
        line-height: 30px;
    } */

  .landing-page h1.solution-txt {
    font-size: 50px;
    line-height: 53px;
  }

  .landing-page .left-content h1.top-title {
    font-size: 33px;
  }

  .landing-page .meeting {
    /* left: -185px; */
    top: 76%;
    transform: translateY(-70%);
  }
}

@media only screen and (max-width: 1199px) {
  .landing-page .about-area-bg-shape {
    left: auto;
    right: 30px;
    opacity: 0.3;
    top: 77px;
    width: 155px;
    rotate: 61deg;
  }
  .landing-page .ml-330 {
    margin-left: 0px !important;
  }

  .landing-page .margin-p {
    margin: 30px 0px;
  }

  .landing-page .leading-brand h2.section-heading {
    width: 100%;
    position: relative;
    z-index: 66;
  }

  .landing-page .left-content {
    padding-left: 0;
    width: 100%;
  }

  .landing-page .mv-logo {
    padding: 0px;
  }

  .landing-page .main-menu {
    display: none;
  }

  .landing-page .btn14 {
    display: block;
    cursor: pointer;
    /* top: 20px;
        right: 20px; */
  }

  .landing-page .footer h2.footer-heading {
    font-size: 26px;
    line-height: 40px;
  }

  /* 
    .book-demo-btn button {
        font-size: 20px;
    } */

  .landing-page .footer-meeting-bg-all {
    /* width: 515px; */
  }

  /* .footer-bottom{
        margin-top: 165px;
    } */
  /* .landing-page .social-boxes {
    margin-bottom: 25px;
  } */

  .landing-page .left-footer-bg {
    height: 40%;
  }

  .landing-page .s-box {
    height: 28px;
    width: 28px;
  }

  .landing-page .s-box img {
    width: 16px;
  }

  .landing-page .left-banner {
    width: 100%;
  }
/* 
  .landing-page .right-banner {
    display: none;
  } */

  .landing-page .faq-header {
    width: 55% !important;
  }

  .landing-page .discover-section .discover-bg-1 {
    display: none;
  }

  .landing-page ul.contact-details {
    margin-top: 2rem;
    margin-bottom: 3rem;
  }

  /* .features-box p {
        min-height: 0;
    } */

  .landing-page .features-box {
    min-height: auto;
    margin-bottom: 90px !important;
  }

  .landing-page .features-section .col-xl-4:last-child .features-box {
    margin-bottom: 0px !important;
  }

  .landing-page .resp-meeting {
    display: block;
    text-align: center;
    margin-top: 40px;
  }

  /* .landing-page .meeting-img {
    width: 70%;
    left: 50%;
    transform: translate(-50%, 0px);
    opacity: 0.2;
  } */
  .landing-page .right-banner{
    background: none;
  }
  .landing-page .meeting{
    top: 75%;
    transform: translateY(-53%);
    width: 77%;
    left: 29px;
  }
  .landing-page .top-lamp {
    display: none;
  }

  .landing-page .wht-qstn-mark {
    display: none;
  }

  .landing-page .left-content-wrapper {
    flex-direction: column;
    justify-content: center;
  }

  /* .landing-page .left-content {
    text-align: center;
    justify-content: center;
    align-items: center;
    display: flex;
    flex-direction: column;
  } */

  /* .landing-page .left-content h1.top-title {
    font-size: 30px;
  } */

  .landing-page h1.solution-txt {
    font-size: 42px;
    line-height: 42px;
    margin-top: 35px;
  }
/* 
  .landing-page .left-content .book-demo-btn {
    justify-content: center;
  } */

  .landing-page .person-keyfeatures {
    display: none;
  }

  /* .key-features-swiper {
        padding: 0 0 0 0px;
    } */

  .landing-page .left-content p {
    width: 60%;
    margin: 0 auto;
  }
 
  /* .lets-explore h2.section-heading {
        width: 50%;

    } */

  .landing-page .zigzag {
    left: 60%;
  }

  /* .key-features-left {
        width: 35%;

    } */
     

  .landing-page h1.solution-txt{
    font-size: 62px;
    line-height: 63px;
    }
}

@media only screen and (max-width: 1050px) {
  /*--------------- prajakta-responsive start--------------------- */

  .landing-page .footer .container {
    padding-left: 20px;
    padding-right: 20px;
  }
  .landing-page .right-sqr-frame {
    left: 210px;
    top: 38%;
    width: 164px;
}
.landing-page .right-element1 {
  width: 86px;
  bottom: 55px;
  right: 13px;
  z-index: auto;
}
.landing-page .meeting {
  top: 80%;
  transform: translateY(-53%);
  width: 70%;
  left: 95px;
}
.landing-page .right-element3{
  top: 33%;
  right: 50px;
}
  .landing-page .left-footer-bg {
    /* background: linear-gradient(to right, #f6eced, #d3e7f2);
        clip-path: inset(75% 0 0 0); */
    width: 65%;
    height: 47%;
  }

  .landing-page .footer h2.footer-heading {
    font-size: 25px;
    line-height: 37px;
  }

  .landing-page .footer-bottom {
    margin-top: 0px;
    bottom: 20px;
  }

  .landing-page .footer-meeting-bg-all {
    right: 140px;
    width: calc(100% + 6.5rem);
  }

  /* .book-demo-btn button {
        font-size: 18px;
    } */

  .landing-page .book-demo-btn {
    margin-top: 30px;
  }

  /* .book-demo-btn button {
        padding: 8px 21px;
    } */

  .landing-page .button__shadow {
    height: 54px;
    bottom: -9px;
  }

  .landing-page .left-footer-bg {
    width: 55%;
    height: 40%;
  }

  .landing-page .marquee {
    mask-image: linear-gradient(
      var(--mask-direction, to right),
      hsl(0 0% 0% / 0),
      hsl(0 0% 0% / 1) 10%,
      hsl(0 0% 0% / 1) 90%,
      hsl(0 0% 0% / 0)
    );
  }

  .landing-page .animation-sec {
    margin-left: 160px;
    margin-right: 130px;
  }

  /*--------------- prajakta-responsive end--------------------- */
}

@media only screen and (max-width: 991px) {
  .landing-page .right-element3 {
    top: 25%;
    right: 17px;
}
.landing-page .right-element2{
  top: 107px;
  left: 49px;
}
  .landing-page .features-box h3,
  .landing-page .features-box p {
    min-height: 0;
  }
  .landing-page .process-vdo{
    height: auto;
    margin-bottom: 0;
  }
  .landing-page .features-box {
    padding: 60px 20px 60px 20px;
    min-height: 0;
  }

  .landing-page .bottom-left {
    height: 117px;
  }

  .landing-page .key-features-swiper {
    padding: 0;
  }

  /* .faq-header {
        margin-top: 60px;
    } */

  .landing-page ul.contact-details li {
    margin-bottom: 25px;
  }

  .landing-page .animation-sec {
    margin-left: 0px;
    margin-right: 0px;
  }

  .landing-page .section {
    padding: 60px 0px;
  }

  /* .landing-page h1.solution-txt {
    font-size: 38px;
    font-weight: 700;
    line-height: 40px;
    margin-top: 35px;
  } */

  .landing-page .discover-bg h2.section-heading,
  .landing-page .explore-headding,
  .landing-page .leading-brand .section-heading,
  .landing-page .faq-header,
  .landing-page p.explore-txt {
    width: 100% !important;
  }

  .landing-page .features-box {
    min-height: auto;
    margin-bottom: 90px !important;
  }

  .landing-page .features-box:last-child {
    margin-bottom: 0px;
  }

  .landing-page .discover-section .discover-bg-1,
  .landing-page .leading-brand::before,
  .landing-page .leading-brand::after,
  .landing-page .zigzag {
    display: none !important;
  }

  .landing-page .right-header {
    justify-content: flex-end;
  }

  .landing-page .banner-section .container-fluid {
    padding: 0px 20px;
  }

  .landing-page .image-bg {
    padding: 12px 10px;
    /* width: 100%; */
    object-fit: cover;
  }

  /* .faq-page .position-relative {
        margin-top: 40px;
    } */

  .landing-page .leading-brand .swiper-slide {
    padding: 7px 10px;
  }

  .landing-page .next-prev-main-div {
    top: 50px;
  }

  .landing-page .lets-explore .section-icon {
    top: 0;
    left: 0px;
  }
  .landing-page .leading-brand .container::before,
  .landing-page .leading-brand .container::after {
    width: 16%;
  }
  .landing-page .slide-content {
    padding-left: 120px;
  }

  /* .next-prev-main-div {
        left: -66px;
    } */

  .landing-page .lets-explore .features-sub-heading {
    min-height: 150px;
  }

  .landing-page .lets-explore .section-heading {
    /* margin-top: 60px; */
    margin-top: 20px;
  }

  .landing-page .lets-explor .demo-vdo {
    margin-top: 25px;
  }

  /*--------------- prajakta-responsive start--------------------- */
  .landing-page .footer h2.footer-heading {
    font-size: 19px;
    line-height: 33px;
  }

  .landing-page .footer-meeting-bg-all {
    right: 92px;
    width: calc(100% + 10.5rem);
    top: -16px;
  }

  .landing-page .left-footer-bg {
    width: 62%;
    height: 47%;
  }

  .landing-page .footer-white {
    right: -251px;
    bottom: -14px;
  }

  /*--------------- prajakta-responsive end--------------------- */

  .landing-page .lets-explore .nav-wrapper {
    /* overflow-x: auto;
        white-space: nowrap; 
        scrollbar-width: none;
        -ms-overflow-style: none; */
    overflow-x: scroll;
    white-space: nowrap;
    scrollbar-width: thin;
    overflow: hidden;
  }
}

@media only screen and (max-width: 900px) {
  .landing-page .footer-meeting-bg-all {
    right: 120px;
    width: calc(100% + 7.5rem);
  }

  .landing-page .footer-bottom {
    margin-top: 22px;
    bottom: 20px;
  }

  .landing-page .left-footer-bg {
    width: 70%;
    height: 40%;
  }

  /* .button__shadow {
        height: 63px;
        bottom: -9px;
    } */
}

@media only screen and (max-width: 768px) {
  .landing-page .book-demo-btn {
    margin-top: 28px;
  }

  /* .book-demo-btn button {
        padding: 7px 19px;
        font-size: 18px;
    } */

  .landing-page .button__shadow {
    height: 53px;
    bottom: -9px;
  }
}

@media only screen and (max-width: 767px) {
  /*--------------- prajakta-responsive start---------------- */
  .landing-page .right-bottom p {
    color: #000000;
    margin-top: 13px !important;
}
.landing-page .footer-meeting-bg-all{
  top: -31px;
}
  .landing-page .banner-section{
    height: auto;
  }
  .landing-page .right-banner{
    position: relative;
    height: 450px;
    width: 100%;
  }
  .landing-page .banner-section .container-fluid{
    flex-direction: column;
  }
  .landing-page .left-content{
    text-align: center;
    margin-top: 95px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .landing-page .meeting {
    top: 72%;
    /* transform: translateY(-53%); */
    width: 55%;
    left: 50%;
    transform: translate(-50%, -50%);
}
.landing-page .right-sqr-frame {
  left: 50%;
  top: 34%;
  width: 164px;
  transform: translate(-50%, -50%);
}









  .landing-page .leading-brand.pb-0{
  padding-bottom: 0 !important;
  }
  .landing-page p.font-15 {
    font-size: 17px !important;
  }

  .landing-page ul.contact-details li {
    margin-bottom: 23px;
  }

  .landing-page .right-bottom ul {
    gap: 40px;
  }

  .landing-page ul.contact-details li {
    position: relative;
    padding-left: 85px;
  }

  .landing-page span.icons {
    height: 65px;
    width: 65px;
  }

  .landing-page ul.contact-details {
    /* margin: 25px 0; */
  }

  .landing-page h2.section-heading {
    font-size: 38px;
    line-height: 45px;
  }

  .landing-page .footer h2.footer-heading {
    font-size: 24px;
    line-height: 40px;
  }

  .landing-page .container {
    padding-left: 20px;
    padding-right: 20px;
  }

  .landing-page .p-top {
    padding-top: 30px;
  }

  .landing-page .left-footer-bg {
    background: linear-gradient(to right, #f6eced, #d3e7f2);
    clip-path: inset(75% 0 0 0);
    width: 100%;
    height: 75%;
  }

  .landing-page .footer-meeting-bg-all {
    right: 0;
    /* width: calc(100% + 0.4rem); */
    width: 100%;
    margin: 40px 0 50px 0;
  }

  .landing-page .footer-bottom {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    bottom: 13px;
    margin-top: 0;
  }

  .landing-page .left-bottom {
    text-align: center;
  }

  /* .landing-page .social-boxes {
    margin-bottom: 25px;
  } */

  .landing-page .right-bottom ul li a {
    color: #000000;
    font-weight: 500;
    line-height: 28px;
  }

  .landing-page .footer-white {
    right: -251px;
    bottom: 85px;
  }

  .landing-page .right-footer-bg {
    width: 100%;
  }

  .landing-page .brx-popup.active-contact .brxe-div,
  .landing-page .book-demo-form.active-demo .brxe-div {
    bottom: -140px;
  }

  .landing-page .brxe-div h1 {
    font-size: 32px;
    font-weight: 500;
  }

  .landing-page .close-icon,
  .landing-page .demo-close-icon {
    width: 20px;
  }

  .landing-page .demo-form::before {
    display: none;
  }

  .landing-page .next-prev-main-div {
    width: 480px;
    /* left: 0px; */
  }

  .landing-page .keyfeatures-prev img {
    width: 60px;
  }

  .landing-page .keyfeatures-next img {
    width: 60px;
  }

  /*--------------- prajakta-responsive end--------------------- */

  .landing-page .header .container-fluid {
    padding: 15px 20px;
  }

  .landing-page .left-content p {
    width: 85%;
  }

  .landing-page .create-meeting-ss img {
    width: 650px;
  }

  .landing-page .key-features-left {
    width: 100%;
    height: 360px;
  }
  .landing-page .leading-brand .swiper-slide {
    padding: 8px 5px;
    width: 100px !important;
  }
}

@media only screen and (max-width: 680px) {
  .landing-page .footer h2.footer-heading {
    font-size: 22px;
    line-height: 34px;
  }

  .landing-page .book-demo-btn {
    margin-top: 25px;
  }

  .landing-page .top-lamp {
    left: 70%;
  }

  .landing-page .create-meeting-ss img {
    width: 465px;
  }

  .landing-page .key-features-left {
    height: 270px;
  }
  .landing-page .modal-content .contact-form{
    width: auto;
  }
  .landing-page .middle-text{
    line-height: 28px;
  }
}

@media only screen and (max-width: 575px) {
  .landing-page .meeting {
    top: 74%;
    /* transform: translateY(-53%); */
    width: 62%;
}
.landing-page .right-element2,.landing-page .right-element1 {
  /* top: 57px;
  left: 18px; */
  display: none;
}
.landing-page .left-element2 {
  top: 275px;
  left: auto;
  right: -10px;

}
.landing-page .right-element3 {
  /* top: 17%;
  right: 0px; */
  display: none;
}





  .key-features-bg{
  display: none;
  }
  .landing-page .footer {
    margin-top: 20px;
  }

  .landing-page .footer h2.footer-heading {
    font-size: 21px;
    line-height: 34px;
  }

  .landing-page h2.section-heading {
    font-size: 38px;
  }

  .landing-page p.font-15 {
    font-size: 16px !important;
  }

  /* .contact-details p {
        font-size: 18px  !important;
    } */
  .landing-page span.icons {
    height: 60px;
    width: 60px;
  }

  .landing-page span.icons img {
    width: 32px;
  }

  .landing-page ul.contact-details li {
    padding-left: 80px;
    margin-bottom: 22px;
  }

  .landing-page .question-answers .accordion-item {
    margin-bottom: 20px;
  }

  .landing-page button.accordion-button.faq-heading {
    font-size: 18px;
  }

  .landing-page .question-answers .accordion-body {
    padding-left: 22px;
    padding-right: 22px;
  }

  .landing-page .mv-logo img {
    width: 80%;
  }

  .landing-page .lets-explore .section-icon {
    width: 70px;
    height: 70px;
  }

  .landing-page .lets-explore .section-icon img {
    width: 40px;
  }

  .landing-page .slide-content {
    padding-left: 80px;
  }
}

@media only screen and (max-width: 535px) {
  .landing-page .footer h2.footer-heading {
    font-size: 19px;
    line-height: 34px;
    /* width: 93%; */
  }

  .landing-page .left-content p {
    /* font-size: 17px;
    line-height: 30px; */

    font-size: 17px;
    line-height: 26px;
    margin-top: 8px !important;
  }
  .landing-page .about-area-bg-shape {
    left: auto;
    right: 13px;
    opacity: 0.3;
    top: 60px;
    width: 100px;
    rotate: 61deg;
}
  .landing-page h1.solution-txt {
    font-size: 48px;
    line-height: 55px;
    margin-top: 30px;
  }

  .landing-page
    .lets-explore
    .swiper-horizontal
    > .swiper-pagination-bullets
    .swiper-pagination-bullet,
  .landing-page
    .lets-explore
    .swiper-pagination-horizontal.swiper-pagination-bullets
    .swiper-pagination-bullet {
    margin: 0 var(--swiper-pagination-bullet-horizontal-gap, -35px) !important;
  }

  .landing-page .next-prev-main-div {
    width: 440px;
  }

  .landing-page .keyfeatures-prev img {
    width: 55px;
  }

  .landing-page .keyfeatures-next img {
    width: 55px;
  }

  .landing-page .hidden-bar .mv-logo img {
    width: 230px;
 }

 .landing-page .hidden-bar{
  padding:25px;
 }
 
 .landing-page .itemm{
  width:42px;
  height:42px;
 }


}

@media only screen and (max-width: 480px) {
  .landing-page .image-bg {
    padding: 8px 7px;
    /* width: 100%; */
    object-fit: cover;
  }

  .landing-page .features-box {
    padding: 90px 20px 90px 20px;
  }

  .landing-page .features-box h3 {
    font-size: 23px;
  }

  .landing-page section.leading-brand {
    padding-bottom: 60px !important;
  }
  .landing-page section.leading-brand.pb-0{
padding-bottom: 0;
  }
  .landing-page .question-answers .accordion-button::after {
    margin-left: 20px;
  }

  .landing-page ul.contact-details {
    margin-top: 1.5rem;
    margin-bottom: 2.5rem;
  }

  .landing-page ul.contact-details li {
    padding-left: 75px;
    margin-bottom: 15px;
  }

  .landing-page span.icons {
    height: 55px;
    width: 55px;
  }

  .landing-page .contact-details li .icons {
    top: 10px;
  }

  .landing-page span.icons img {
    width: 28px;
  }

  .landing-page .social-boxes {
    gap: 10px;
  }

  .landing-page .s-box {
    height: 30px;
    width: 30px;
  }

  /* .landing-page .p-top {
    padding-top: 60px;
  } */

  .landing-page .footer .container {
    padding-left: 30px;
    padding-right: 30px;
  }

  .landing-page .right-footer-bg {
    clip-path: none;
  }

  .landing-page .footer-meeting-bg-all {
    margin: 50px 0 60px 0;
  }

  .landing-page .footer h2.footer-heading {
    font-size: 18px;
    line-height: 33px;
    color: #fff;
  }

  .landing-page .footer h2.footer-heading .blue-text {
    /* color: blueviolet; */
    color: #ff8181;
  }

  .landing-page .left-footer-bg {
    height: 92%;
  }

  .landing-page .book-demo-btn {
    margin-top: 30px;
  }

  .landing-page .footer-bottom {
    bottom: 25px;
  }

  .landing-page
    .lets-explore
    .swiper-horizontal
    > .swiper-pagination-bullets
    .swiper-pagination-bullet,
  .landing-page
    .lets-explore
    .swiper-pagination-horizontal.swiper-pagination-bullets
    .swiper-pagination-bullet {
    margin: 0 var(--swiper-pagination-bullet-horizontal-gap, -40px) !important;
  }

  .landing-page .swiper-pagination:before {
    left: 25px;
  }

  .landing-page .keyfeatures-prev img {
    width: 45px;
  }

  .landing-page .keyfeatures-next img {
    width: 45px;
  }

  .landing-page .next-prev-main-div {
    width: 350px;
  }

  .landing-page .slide-content {
    padding-left: 65px;
  }

  .landing-page .lets-explore .section-icon img {
    width: 35px;
  }

  .landing-page .lets-explore .section-icon {
    width: 60px;
    height: 60px;
  }

  .landing-page .next-prev-sub-div .text {
    display: none;
  }

  .landing-page .create-meeting-ss img {
    width: 330px;
  }

  .landing-page .create-meeting-ss {
    margin-bottom: 20px;
  }

  .landing-page .key-features-left {
    height: 190px;
  }





  .landing-page .meeting {
    top: 72%;
    /* transform: translateY(-53%); */
    width: 77%;
}
.landing-page .right-sqr-frame {
  top: 32%;
  width: 130px;
}
.landing-page .right-banner {
  height: 325px;
}
.landing-page .footer h2.footer-heading{
  width:100%;
}

.landing-page .button__shadow {
  height: 48px;
}

.landing-page .book-demo-btn button{
  padding: 7px 17px;
}


}

@media only screen and (max-width: 400px) {
  .landing-page .left-footer-bg {
    height: 95%;
  }

  .landing-page .footer-bottom {
    bottom: 27px;
  }

  .landing-page button.accordion-button.faq-heading {
    font-size: 17px;
  }
}

@media only screen and (max-width: 380px) {
  .landing-page .footer h2.footer-heading {
    font-size: 17px;
  }

  .landing-page .book-demo-btn button {
    font-size: 16px;
  }

  .landing-page .faq-page .faq-header {
    width: 100% !important;
    /* display: flex !important; */
    font-size: 33px;
  }

  .landing-page .next-prev-main-div {
    width: 325px;
  }
}

@media only screen and (max-width: 360px) {
  .landing-page button.accordion-button.faq-heading {
    font-size: 17px;
  }

  .landing-page .footer h2.footer-heading {
    font-size: 16px;
  }

  .landing-page .footer-bottom {
    bottom: 23px;
  }

  .landing-page .create-meeting-ss img {
    width: 315px;
  }

  .landing-page .next-prev-main-div {
    width: 320px;
  }

  .landing-page .faq-page .faq-header {
    /* width: 100% !important;
        display: flex !important; */
    /* font-size: 31px; */
    line-height: 37px;
  }

  .landing-page .key-features-left {
    height: 180px;
  }
  .landing-page .section-heading.process-vdo-caption {
    font-size: 47px !important;
  }
}

@media only screen and (max-width: 320px) {
  .landing-page .section-icon {
    width: 90px;
    height: 90px;
  }

  .landing-page .section-icon img {
    width: 50px;
  }

  .landing-page .features-box h3 {
    font-size: 19px;
  }

  .landing-page .top-left,
  .landing-page .bottom-left {
    width: 85px;
  }

  .landing-page .top-right {
    width: 40px;
  }

  .landing-page .bottom-right {
    width: 57px;
  }

  /* .landing-page .faq-page .faq-header {
    font-size: 28px;
  } */

  .landing-page .footer h2.footer-heading {
    font-size: 14px;
    line-height: 27px;
  }

  /* .book-demo-btn button {
        font-size: 16px;
    }

    .book-demo-btn button {
        padding: 6px 16px;
    } */

  .landing-page .footer-bottom {
    bottom: 20px;
  }

  .landing-page .footer-meeting-bg-all {
    margin: 40px 0 50px 0;
  }

  .landing-page .social-boxes {
    margin-bottom: 20px;
  }

  .landing-page .right-bottom ul li a {
    line-height: 20px;
  }

  .landing-page .question-answers .accordion-body {
    font-size: 14px;
    line-height: 24px;
  }

  .landing-page button.accordion-button.faq-heading {
    font-size: 15px;
  }

  .landing-page p.font-15 {
    font-size: 15px !important;
  }

  .landing-page .next-prev-main-div {
    width: 280px;
  }

  .landing-page
    .lets-explore
    .swiper-horizontal
    > .swiper-pagination-bullets
    .swiper-pagination-bullet,
  .landing-page
    .lets-explore
    .swiper-pagination-horizontal.swiper-pagination-bullets
    .swiper-pagination-bullet {
    margin: 0 var(--swiper-pagination-bullet-horizontal-gap, -32px) !important;
  }

  .landing-page .create-meeting-ss img {
    width: 290px;
  }

  .landing-page .lets-explore .section-icon img {
    width: 30px;
  }

  .landing-page .lets-explore .section-icon {
    width: 55px;
    height: 55px;
  }
}

.landing-page .btn1 {
  text-transform: uppercase;
  position: relative;
  text-decoration: none;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 3rem;
  background-size: 300%;
  transition: all 0.8s;
  border-radius: 20px;
}

.landing-page .orange {
  background-image: linear-gradient(120deg, #ffffff00 50%, #fc6853 50%);
  z-index: 1;
}

.landing-page .btn1:hover {
  color: #fff;
  background-position: 100%;
}

.landing-page .btn1:active {
  box-shadow: 0px 10px 20px -15px rgba(0, 0, 0, 0.75);
}

/* .header-btn .book-demo-btn button:hover {
    color: #ffffff !important;
    background: transparent !important;
   border: 2px solid #fc6853!important;
  } */

.landing-page .header.sticky .btn1.orange {
  background-image: linear-gradient(120deg, #ffffff00 50%, #fc6853 50%);
  z-index: 1;
}

.landing-page .header.sticky .header-btn .book-demo-btn.line-btn button {
  border: 2px solid #fc6853;
  color: #fc6853;
}

.landing-page .header.sticky .header-btn .book-demo-btn.line-btn button:hover {
  color: #fff;
}

.landing-page
  .header.sticky
  .header-btn
  .book-demo-btn.line-btn
  button:hover
  .arrow-spacing
  img {
  filter: invert(100%) brightness(100%) contrast(100%) sepia(0%) saturate(0%)
    hue-rotate(0deg);
}
.landing-page .schedule-left {
  display: flex;
  flex-direction: column;
  align-items: center;
  /* width: 265px; */
  border-right: 1px solid rgb(221, 221, 221);
  padding: 32px;
}

.landing-page .book-a-demo-modal .modal-header {
  background: #ffffff;
  border: none;
}
.landing-page .book-a-demo-modal .modal-lg {
  max-width: 774px;
  height: 550px;
  width: 100%;
  position: relative;
  border-radius: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.landing-page .modal-lg p.text-danger {
  position: absolute;
  bottom: -9px;
  left: 14px;
  font-size: 12px;
  background: #f16565;
  padding: 1px 5px;
  color: #ffffff !important;
  border-radius: 4px;
  top: auto;
  width: auto;
  line-height: 18px;
  height: auto;
  opacity: 1 !important;
}

.landing-page .book-a-demo-modal .verify-email {
  position: absolute;
  right: 8px;
  top: 17px;
  border-radius: 4px 4px !important;
  padding: 0.3em 0.65em !important;
  font-size: 0.75em !important;
  cursor: pointer;
  color: #fff !important;
  background-color: RGBA(25, 135, 84, var(--bs-bg-opacity, 1)) !important;
}

.landing-page .middle-text {
  font-size: 20px;
  line-height: 36px;
  font-weight: 600;
  color: #614efa;
  text-align: center;
}

.landing-page .book-a-demo-modal .modal-body {
  max-height: inherit;
  overflow-y: inherit;
  border-bottom-left-radius: var(--bs-modal-inner-border-radius);
  border-bottom-right-radius: var(--bs-modal-inner-border-radius);
  padding-bottom: 70px;
}

.landing-page .success-response{
  font-size: 13px;
  line-height: 16px;
  font-family: "Plus Jakarta Sans", sans-serif;
  font-weight: 500;
  display: block;
  margin-top: 2px !important;
  color: #06a113;
}

.landing-page .error-response{
  font-size: 13px;
  line-height: 16px;
  font-family: "Plus Jakarta Sans", sans-serif;
  font-weight: 500;
  display: block;
  margin-top: 2px !important;
  color: #da190b;
}
.landing-page .hidden-bar .side-menu ul li a:hover,
.landing-page .hidden-bar .side-menu ul li:hover{
  color: #a7a7a7;
}

.landing-page .f-success-response{
  font-size: 14px;
    line-height: 16px;
    font-family: "Plus Jakarta Sans", sans-serif;
    font-weight: 500;
    display: block;
    margin-top: 2px !important;
    background: #06a113;
    width: 100%;
    color: #fff;
    text-align: center;
    margin-top: 18px !important;
    padding: 5px;
    border-radius: 5px;
}

.landing-page .f-error-response{
  font-size: 14px;
    line-height: 16px;
    font-family: "Plus Jakarta Sans", sans-serif;
    font-weight: 500;
    display: block;
    margin-top: 2px !important;
    background: #f3382a;
    width: 100%;
    color: #fff;
    text-align: center;
    margin-top: 18px !important;
    padding: 5px;
    border-radius: 5px;
}

.landing-page.visible-sidebar .menu-overlay{ 
  display: block;
 }

 .landing-page.feature-tabs {
  overflow-x: auto;
  white-space: nowrap;
  background-color: #007bff; /* Blue background */
  border-radius: 5px;
  padding: 10px 0;
}

.landing-page.feature-tabs .nav-link {
  color: #fff;
  font-size: 16px;
  margin: 0 15px;
}

.landing-page.feature-tabs .nav-link.active {
  color: #007bff;
  background-color: #fff;
  border-radius: 5px;
}

.landing-page.tab-content {
  margin-top: 20px;
}

.landing-page.outer-features-box {
  padding: 20px;
  background-color: #f8f9fa;
  border-radius: 8px;
}

.landing-page.demo-vdo img {
  max-width: 100%;
  border-radius: 5px;
}


/*------------ Responsive Code 14-03-2024 ---------------- */

@media only screen and (max-width: 991px) {
  .landing-page .hidden-bar .side-menu ul li a{
    padding:0;
  }
  .faq-header{
    margin-top:3rem;
  }

  .lets-explore, .faq-page{
    padding-top: 0px !important;
  }

  .faq-page{
    padding-bottom:30px !important;
  }

  .landing-page .leading-brand .section-heading{
    font-size:38px;
  }
}
@media only screen and (max-width: 1368px) {
  .landing-page .discover-bg h2.section-heading {
      width: 80%;
      margin-top: 50px;
  }
  
  
}

@media only screen and (max-width: 575px) {
  .landing-page h2.section-heading {
      font-size: 38px;
      /* margin-top: 50px; */
      margin-top: 50px;
  }
}
#skip-to-solution,
#skip-to-features,
#skip-to-furtherquestion {
    scroll-margin-top: 50px; 
}