.user-login {
    width: 100%;
    height: 100vh;
    float: left;
    background-color: white;
}

.user-login .container-fluid,
.user-login .loginform-container,
.user-login .blue-box-cont {
    width: 100%;
    height: 100vh;
}

.loginform-container {
    width: 100%;
    float: left;
    padding-left: 50px;
    padding-top: 45px;

}

.sign-in form {
    position: relative;
    width: 400px;
    /* height: calc(100vh - 135px) !important; */
    height: calc(100vh - 100px) !important;
    display: flex;
    justify-content: center;
    flex-direction: column;
}

.text h4 {
    font-weight: 700;
    margin: 0;
}

.inner-group {
    display: flex;
    align-items: center;
    border: 1px solid rgb(202, 183, 221);
    padding: 0 14px;
    border-radius: 5px;
}

.sign-in .form-group input {
    width: 100%;
    padding: 0;
    outline: none;
    border: none;
    text-indent: 10px;
    font-size: 14px;
}

.email-group {
    margin: 20px 0 !important;
}

.remember-forgot-pwd {
    display: flex;
    justify-content: space-between;
    font-size: 14px;
    margin: 20px 0;
}

.remember {
    color: black;
    display: flex;
    gap: 5px;
    align-items: center;
}

input[type="checkbox"] {
    border-color: rgba(137, 43, 226, 0.295);
}

.forgot-pwd a {
    color: rgb(11 119 232);
}

.login-btn {
    padding: 7px 10px;
    width: 100%;
    border-radius: 15px;
    background-color: #5522b2;
    color: white;
    border: none;
    font-size: 14px;
}

.signup-btn {
    padding: 7px 10px;
    width: 100%;
    border-radius: 20px;
    color: black;
    border: none;
    font-size: 14px;
    background-color: #f0f0f0;
}

.login-btn a {
    color: white;
}

.account {
    text-align: center;
    color: gray;
    margin: 15px 0;
    font-size: 13px;
    position: relative;
}

.account:before {
    content: "";
    height: 1px;
    width: 30%;
    position: absolute;
    background-color: rgb(128 128 128 / 31%);
    top: 50%;
    left: 0;
    transform: translateY(-50%);
}

.account:after {
    content: "";
    height: 1px;
    width: 30%;
    position: absolute;
    background-color: rgb(128 128 128 / 31%);
    top: 50%;
    right: 0;
    transform: translateY(-50%);
}

.by-password .back {
    color: rgb(11 119 232);
    margin: 20px 0;
    font-size: 14px;
    position: relative;
    display: flex;
    align-items: center;
    gap: 5px;
}

.back a {
    color: gray;
    font-size: 15px;
}


@media only screen and (max-width: 767px){
    .resp-text-center{
        text-align: center;
    }
}
    