.details-form {

  border-radius: 7px;
  background-color: #fff;
  padding: 25px;

}

.return-agenda {
  display: flex;
  align-items: center;
  gap: 10px;
}

.meeting-header-text {
  display: flex;
  align-items: center;
}

.details-form {
  position: relative;
}

.meeting-1,
.meeting-2 {
  display: flex;
}

.meeting-3 {
  display: flex;
  align-items: center;
}

.meeting-count1 {
  position: relative;
  height: 40px;
  width: 40px;
  border-radius: 50px;
  background: rgb(13, 55, 180);
  background: linear-gradient(117deg,
      rgba(13, 55, 180, 1) 0%,
      rgba(118, 60, 209, 1) 59%);
  display: flex;
  align-items: center;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
}

.meeting-count1::before {
  content: "";
  position: absolute;
  bottom: -54px;
  height: 54px;
  width: 1px;
  background-color: #80808045;
  border-radius: 20px;
}

.meeting-count1::after {
  content: "";
  position: absolute;
  top: -25px;
  height: 25px;
  width: 1px;
  background-color: #80808045;
  border-radius: 20px;
}

.return-left-arrow {
  display: flex;
  align-items: center;
  justify-content: center;
  height: auto;
  width: auto;
  padding: 8px;
  background-color: transparent;
  border-radius: 50px;
  margin-top: 10px;
}

.return-left-arrow:hover {
  background-color: #80808045;
}

.meeting-count2 {
  position: relative;
  height: 40px;
  width: 40px;
  border-radius: 50px;
  background: rgb(13, 55, 180);
  background: linear-gradient(117deg,
      rgba(13, 55, 180, 1) 0%,
      rgba(118, 60, 209, 1) 59%);
  display: flex;
  align-items: center;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
}

.meeting-count2::before {
  content: "";
  position: absolute;
  height: 49px;
  top: -49px;
  width: 1px;
  background-color: #80808045;
  border-radius: 20px;
}

.meeting-count2::after {
  content: "";
  position: absolute;
  height: 54px;
  bottom: -54px;
  width: 1px;
  background-color: #80808045;
  border-radius: 20px;
}

.view-minutes {
  border: 1px solid rgb(90, 53, 240);
  border-radius: 23px;
  color: rgb(90, 53, 240);
  width: 125px;
  font-size: 15px;
  font-weight: 500;
  padding: 5px;
  display: flex;
  justify-content: center;
  background-color: #fff;
}

.create-follow {
  border: 1px solid rgb(90, 53, 240);
  border-radius: 23px;
  color: rgb(90, 53, 240);
  width: 140px;
  font-size: 15px;
  font-weight: 500;
  padding: 5px;
  display: flex;
  justify-content: center;
  background-color: #fff;
}

.create-follow a {
  color: rgb(90, 53, 240);
}

.meeting-1-details {
  margin-left: 60px;
}

.meeting-time {
  color: gray;
  font-size: 13px;
}

.meeting-circle-add {
  height: 40px;
  width: 40px;
  border-radius: 50px;
  background: rgb(13, 55, 180);
  background: linear-gradient(117deg,
      rgba(13, 55, 180, 1) 0%,
      rgba(118, 60, 209, 1) 59%);
  display: flex;
  align-items: center;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
}

.check-text span {
  font-size: 14px !important;
}

.hide-agenda-right.show {
  display: block;
}

.hide-agenda-right {
  display: none;
}

.hide-agenda-right.show .card {
  display: none;
}