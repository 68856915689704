.login-no-padding {
  padding-left: 0px !important;
  padding-top: 0px !important;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

ul {
  padding: 0;
  margin: 0;
}

.position-relative {
  position: relative !important;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

/* .btn{
  min-width: 62px !important;
} */
/* You can add global styles to this file, and also import other style files */

body {
  font-family: "Roboto", sans-serif !important;
  font-size: 16px;
  margin: 0;
}

ul {
  padding: 0;
  margin: 0;
}

table th {
  color: #182a91 !important;
}

table td,
table th {
  vertical-align: middle;
  white-space: nowrap;
  text-overflow: ellipsis;
  max-width: 100%;
}

h4,
h5 {
  margin: 0;
  padding: 0;
}

p {
  font-family: "Roboto", sans-serif;
  font-size: 16px;
  margin: 0 !important;
  padding: 0;
}

p:last-child {
  margin-bottom: 0;
}

input[type="text"],
input[type="email"] {
  /* padding: 10px; */
  padding: 9px 0px;
}

form select,
form textarea,
textarea {
  padding: 9px 0px;
  width: 100% !important;
}

form input[type="date"],
form input[type="time"] {
  padding: 10px 5px 10px 5px;
  font-size: 15px;
}

table th,
table td {
  vertical-align: middle;
}

.form-check-input {
  width: 1em !important;
  height: 1em !important;
}

form input,
form select {
  width: 100%;
  height: 40px;
}

form input[type="checkbox"] {
  width: auto !important;
  height: auto !important;
}

.form-check-inline label {
  font-weight: normal;
}

.form-check-input:focus {
  outline: 0 !important;
  box-shadow: none !important;
}

.form-group {
  position: relative;
}

label {
  font-weight: 600;
  font-size: 15px;
  display: block;
}

/* -------------topbar----------------- */
.meeting {
  overflow: hidden;
}

.pointer {
  cursor: pointer;
}

.topbar-1 {
  position: fixed;
  width: 100%;
  height: 60px;
  background-color: #fff;
  border-bottom: 1px solid rgb(181, 179, 179);
  display: flex;
  align-items: center;
  -webkit-box-shadow: 3px 47px 22px -55px rgba(0, 0, 0, 0.74);
  -moz-box-shadow: 3px 47px 22px -55px rgba(0, 0, 0, 0.74);
  box-shadow: 3px 47px 22px -55px rgba(0, 0, 0, 0.74);
  z-index: 999;
}

.sidebar-open-btn {
  display: none;
  border: none;
  background-color: transparent;
}

.topbar1-content {
  width: 100%;
  height: 60px;
  background-color: #fff;
  padding-left: 280px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #000;
  font-size: 25px;
  -webkit-box-shadow: 0px 0px 5px 0px rgb(189 189 189 / 75%);
  -moz-box-shadow: 0px 0px 5px 0px rgb(189 189 189 / 75%);
  box-shadow: 0px 0px 5px 0px rgb(189 189 189 / 75%);
}

.top-right-svg {
  padding-right: 15px;
  display: flex;
  gap: 17px;
  align-items: center;
  text-align: center;
}

.bell {
  position: relative;
}

.bell span {
  z-index: 11;
  color: #fff;
  font-weight: 700;
  position: absolute;
  height: 17px;
  /* width: 24px; */
  padding: 0px 5px;
  background-color: rgb(244, 64, 14);
  border-radius: 10px;
  top: 1px;
  left: 18px;
  line-height: 27px;
  font-size: 11px;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
}

.admin-box {
  gap: 5px;
  font-size: 13px;
  font-weight: 500;
  display: flex;
  align-items: center;
  justify-content: center;
  text-transform: capitalize;
}

.top-header-profile .dropdown-toggle:hover {
  background-color: rgba(227, 223, 218, 0.534);
  border-radius: 50px;
}

.notification-filter .dropdown-toggle:hover {
  background-color: blue;
  border-radius: 50px;
}

.create-meet {
  position: relative;
  background: rgb(60, 222, 27);
  background: linear-gradient(
    117deg,
    rgba(60, 222, 27, 0.7987570028011204) 0%,
    rgba(18, 105, 1, 0.6558998599439776) 100%
  );
  border-radius: 23px;
  color: #fff;
  width: 140px;
  font-size: 14px;
  padding: 7px;
  border: none;
  display: flex;
  justify-content: center;
}

.line {
  height: 30px;
  width: 1px;
  background-color: #80808045;
  border-radius: 20px;
}

.user {
  height: 35px;
  width: 35px;
  display: flex;
  align-items: center;
  border-radius: 50%;
  margin-right: 5px;
}

svg.bi.bi-person-fill {
  position: absolute;
  top: 4px;
  left: 5px;
}

.topbar-2 {
  position: fixed;
  width: 100%;
  height: 70px;
  margin-top: 60px;
  background-color: #fff;
  display: flex;
  padding-left: 280px;
  align-items: center;
  border-top: 1px solid gainsboro;
  -webkit-box-shadow: 3px 47px 22px -55px rgba(0, 0, 0, 0.74);
  -moz-box-shadow: 3px 47px 22px -55px rgba(0, 0, 0, 0.74);
  box-shadow: 3px 47px 22px -55px rgba(0, 0, 0, 0.74);
  z-index: 9;
}

.topbar2-content ul {
  display: flex;
  list-style: none;
  margin: 0;
  padding: 0;
}

.home {
  padding-left: 0 !important;
}

.home,
.agenda-top,
.alert,
.timeline {
  padding: 11px;
  display: block;
  text-align: center;
  position: relative;
}

a {
  text-decoration: none;
  color: #000;
}

Link {
  color: black;
}

.agenda-top:before,
.alert:before,
.timeline:before {
  content: "";
  position: absolute;
  height: 50px;
  width: 1px;
  background-color: #80808045;
  left: 0;
  border-radius: 20px;
}

.alert:before {
  background-color: transparent;
}

.home a:hover .home-text,
.agenda-top a:hover .agenda-text,
.alert a:hover .alert-text,
.timeline a:hover .timeline-text {
  color: blue;
}

.home a:hover .home-svg svg,
.agenda-top a:hover .home-svg svg,
.alert a:hover .home-svg svg,
.timeline a:hover .home-svg svg {
  fill: blue;
}

/* -----------sidebar----------- */
.sidebar-content .sub-setting {
  margin-left: 30px;
}

.sidebar {
  position: fixed;
  display: block;
  height: 100vh;
  width: 260px;
  /* background: #3c3cb5 url("./assets/images/sidebar-bg.png"); */
  background: #3c3cb5 url("./assets/images/sidebar-background.png");
  z-index: 999;
  background-size: cover;
  /* background-size: auto; */
  /* transition: all 0.7s ease;
  -webkit-transition: all 0.7s ease;
  -ms-transition: all 0.7s ease;
  -o-transition: all 0.7s ease;
  -moz-transition: all 0.7s ease; */
  transition: left 0.3s ease-in;
  overflow: auto;
}

/* .sidebar::before{
content: '';
position: absolute;
width: 100%;
height: 100%;
bottom: 0;
top: 81%;
left: 0;
background: url("./assets/images/meeting2.svg");
background-size: contain;
background-repeat: no-repeat;
opacity: 0.6;
} */
p.success-message {
  font-size: 12px !important;
}

.side-bg-img {
  position: absolute;
  z-index: 222;
  opacity: 0.5;
  width: 264px;
}

.meeting-img {
  position: absolute;
  bottom: 0;
  width: 100%;
  left: 0;
  opacity: 0.5;
}

.ntspl-logo {
  text-align: center;
  position: relative;
  z-index: 999;
  width: 100%;
}

.login-logo {
  /* max-width: 300px; */
  width: 280px;
}

.sidebar-content a li {
  background-color: transparent;
  font-size: 17px;
  position: relative;
  z-index: 99999;
  text-decoration: none;
  color: #ffffff;
  font-weight: 500;
  padding: 10px 0;
}

.ntspl-logo img {
  width: auto;
  max-width: 100%;
}

.top-logo {
  /* display: flex;
  align-items: center;
  justify-content: space-between; */
  padding: 0px 20px;
}

.sidebar-close {
  display: none;
  text-align: right;
  padding: 0;
  position: absolute;
  width: 35px;
  height: 35px;
  right: 3px;
  top: 3px;
  padding: 2px;
  /* background: #2b41bb; */
  border-radius: 50%;
  cursor: pointer;
}

.sidebar-content a li {
  width: 100%;
  display: block;
  width: 100%;
  list-style: none;
  border-radius: 50px 0 0 50px;
  list-style: none;
  position: relative;
  margin-bottom: 5px;
  display: flex;
  align-items: center;
  gap: 10px;
  padding: 9px 13px;
  cursor: pointer;
}

.sidebar-content li:hover .drop-icon {
  filter: invert(1);
}

.manage-dropdown {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 100px;
}

.sidebar-content li:hover {
  color: black;
  background: #ffffff;
}

.sidebar-content li:hover a {
  color: #232323;
}

.sidebar-content li:hover .sidebar-content li {
  color: #232323;
}

.sidebar-content li.active-menu {
  background-color: #ffffff;
  color: #000;
}

.sidebar-content li.active-menu a {
  color: #232323;
}

.sidebar-content {
  padding-top: 40px;
  padding-left: 20px;
  width: 100%;
  float: left;
}

.main-content {
  padding-left: 280px !important;
  padding-top: 75px !important;
  padding-right: 15px !important;
  padding-bottom: 30px !important;
  background-color: #e2e6f0;
  min-height: 100vh;
  overflow: hidden;
}

.content-hover-effect {
  display: none;
  left: 0;
  background-color: #fff;
  padding: 10px 10px;
  border-top-left-radius: 40px;
  border-bottom-left-radius: 40px;
  width: 235px;
  height: 40px;
  position: relative;
  float: right;
  z-index: 9999;
}

.active-menu:before {
  content: "";
  position: absolute;
  background: url(./assets/images/curve.png);
  width: 15px;
  bottom: 100%;
  height: 15px;
  z-index: 999999;
  right: 0;
  background-position: center;
  background-size: cover;
}

.active-menu:after {
  content: "";
  position: absolute;
  background: url(./assets/images/curve2.png);
  width: 15px;
  top: 100%;
  height: 15px;
  z-index: 999999;
  right: 0;
  background-position: center;
  background-size: cover;
}

.sidebar-content .active-menu img,
.sidebar-content li:hover img {
  filter: none;
}

.contstnt-body {
  overflow: hidden !important;
}

.no-padding {
  padding: 0 !important;
}

.show {
  display: block;
}

.sidebar-content img {
  width: 22px;
  filter: invert(1);
}

.bar-btn {
  width: 40px;
  height: 40px;
  border-radius: 50px;
  background-color: #adc3fc;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.bar-btn:hover {
  background-color: rgb(2, 2, 187);
}

.bar-btn:hover svg {
  filter: invert(1);
}

.meeting-header-text {
  display: flex;
  justify-content: space-between;
  align-items: center;
  /* margin:10px 0px; */
  /* margin-top: 20px; */
}

.meeting-header-text h4 {
  font-weight: 600;
}

.table-box {
  background-color: #fff;
  padding: 16px 16px;
  border-radius: 10px;
  box-shadow: rgba(50, 50, 105, 0.15) 0px 2px 5px 0px,
    rgba(0, 0, 0, 0.05) 0px 1px 1px 0px;
  /* overflow-x: auto; */
  width: 100%;
}

.resp-height100 {
  height: 100%;
}

.no-data-img {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  padding: 10px 15px 15px 15px;
}

.tbl-text-search {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
}

.tbl-text-search .error-message {
  position: absolute;
  right: 0;
  bottom: -18px;
}

.search-box svg {
  position: relative;
  top: -1px;
  right: 2px;
}

.left-tbl-text p {
  margin: 0;
}

.attendees {
  display: flex;
  align-items: center;
  justify-content: left;
}

.attendees p {
  font-size: 14px !important;
  margin-left: 7px !important;
}

.attendee-list {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: -5px;
  color: #fff;
  font-size: 13px;
  cursor: pointer;
}

.details-form .attendee-list:first-child {
  margin-left: 0px;
}

.attendee-list-all {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: -5px;
  color: #fff;
  font-size: 13px;
  cursor: pointer;

  justify-content: left;
}

.attendees .attendee-list:nth-child(1),
.sl {
  background-color: rgb(95, 7, 176);
}

.attendees .attendee-list:nth-child(2),
.pk {
  background-color: rgb(243, 176, 51);
}

.attendees .attendee-list:nth-child(3),
.rb {
  background-color: rgb(22, 179, 43);
}

.attendees .attendee-list:nth-child(4),
.yh {
  background-color: rgb(58, 127, 255);
}

.attendees .attendee-list:nth-child(5),
.db {
  background-color: rgb(109, 214, 253);
}

.attendees .attendee-list:nth-child(6),
.db {
  background-color: rgb(109, 214, 253);
}

#attendees-modal:hover {
  background-color: #e6e6e6 !important;
  color: #000000 !important;
}

.detail p {
  cursor: pointer;
}

.attendee-content {
  display: flex;
  align-items: flex-start;
}

.attendee-contentall {
  display: flex;
  align-items: flex-start;
}

.cursor-pointer {
  cursor: pointer;
}

.attendees-popup .attendee-list {
  width: 45px !important;
  height: 45px !important;
  margin-left: 15px !important;
  margin-right: 15px !important;
}

.attendees-popup-list .attendee-list {
  width: 45px !important;
  height: 45px !important;
  margin-left: 0 !important;
  margin-right: 15px !important;
  font-size: 15px !important;
}

.attendees-popup .attendee-list {
  font-size: 20px;
}

/* .attendees-popup, .attendees-popup-list{
  overflow: auto;
} */
.attendees-popup .comment-text {
  font-size: 14px;
}

.attendees-popup .action-cmnt-text {
  line-height: 22px;
}

.space .attendee-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.check-attendee {
  display: flex;
  align-items: center;
  gap: 20px;
}

.attendees-popup .permission {
  font-size: 12px;
  padding: 2px 5px;
  background-color: #a9ecff;
  border-radius: 5px;
  color: #1e1e1e;
}

.attendees-popup .detail-name {
  font-size: 17px;
  font-weight: 400;
}

.req-by .date,
.space .date {
  font-size: 13px;
  color: #898989;
}

.table .dropdown-item:hover {
  background-color: none !important;
}

.add-agenda {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.add-text {
  font-size: 18px;
  font-weight: 500;
}

.view p {
  color: #0202bb;
  cursor: pointer;
}

.add-buttons {
  display: flex;
  align-items: center;
  justify-content: left;
  margin-top: 25px;
}

.accept-by {
  width: 100%;
  margin-top: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.added-by {
  background-color: rgb(255 253 194);
  width: 100%;
  padding: 10px 15px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: 1px solid #ffc107;
}

.add-agenda {
  display: flex;
  align-items: center;
}

.added-by .attendee-list {
  margin-left: 10px !important;
}

.action-bar {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background-color: rgb(225, 195, 253);
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  cursor: pointer;
}

.search-box input {
  border: none;
  outline: none;
  width: 100%;
  text-indent: 5px;
  padding-right: 30px;
  background-color: transparent;
}

button.btn.btn-light {
  border: 1px solid #ddd;
  border-radius: 23px;
}

.search-box {
  border-radius: 20px;
  border: 1px solid #c3c3c3;
  outline: none;
  padding: 8px 8px;
  width: 400px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 40px;
  /* background-image: url(./assets/images/search-icon.png);
  background-repeat: no-repeat;
  background-position: right 9px top 8px; */
}

.search-box input::placeholder {
  color: #4f2cc8;
  font-weight: 500;
}

.tbl-bottom {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 20px;
}

.left-tbl-bottom {
  display: flex;
  justify-content: space-around;
  align-items: center;
  gap: 5px;
}

.left-tbl-bottom ul {
  display: flex;
  align-items: center;
  list-style: none;
  gap: 8px;
  padding: 0;
  margin: 0;
}

.left-tbl-bottom ul li {
  height: 30px;
  width: 30px;
  border-radius: 50px;
  background-color: #c9c9c9;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.right-tbl-bottom {
  display: flex;
  justify-content: space-around;
  align-items: center;
  gap: 8px;
}

.left-arrow,
.right-arrow {
  border-radius: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 30px;
  width: 30px;
  background-color: #d1cfcf;
  border: none;
  cursor: pointer;
}

.left-arrow:hover {
  background-color: #767676;
}

.right-arrow:hover {
  background-color: #767676;
}

.right-arrow {
  background-color: #0202bb !important;
}

.left-arrow {
  background-color: #0202bb !important;
}

.selected-page {
  color: #343a40;
  font-weight: bolder;
  background-color: #c7c7c7 !important;
}

.no-opt-box {
  width: 80px;
  padding: 2px 3px;
  border-bottom: 1px solid black !important;
  border: none;
  border-radius: 0 !important;
}

.no-opt-box:focus {
  outline: none;
  border: none;
}

.right-tbl-bottom p {
  font-size: 15px;
}

.schedule {
  color: rgb(4, 145, 34);
  font-weight: 500;
}

.close {
  color: black;
  font-weight: 500;
}

.attendee1 {
  background-color: rgb(95, 7, 176);
}

.attendee2 {
  background-color: rgb(243, 176, 51);
}

.bg-opacity-10 {
  --bs-bg-opacity: 1.1 !important;
  color: #fff !important;
}

/* -----------filter-menu--------- */

.filter.show {
  right: 0;
}

.filter form {
  display: flex;
  flex-direction: column;
}

.filter button {
  margin-top: 10px;
}

.filter {
  font-size: 18px;
}

/* Yamuna's style start*/

.filter-container {
  border-bottom: 1px solid #d4d4d4;
  padding: 0px 0px 15px;
  margin-bottom: 15px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.filter-form label {
  margin-top: 15px;
}

.filter-form label:first-child {
  margin-top: 0px;
}

.filter label {
  font-weight: 500;
}

/* .filter form select {
  color: #979797;
} */

/* Yamuna's style end  */

/* .filter-container {
  border-bottom: 1px solid #d4d4d4;
  padding: 10px 0;
  position: relative;
  margin-bottom: 15px;
} */

/* .filter-container button {
  position: absolute;
  top: -20px;
  right: -8px;
} */

/* .from-to,
.fltr-opt {
  border: 1px solid rgb(202, 183, 221);
  border-radius: 5px;
} */

.from-to {
  border: 1px solid rgb(202, 183, 221);
  border-radius: 5px;
  display: flex;
  align-items: center;
  position: relative;
}

/* .filter-date::-webkit-calendar-picker-indicator {
  background-color: rgb(11, 68, 238);
  padding: 12px 12px;
 }  */
.filter-inputIcon {
  position: absolute;
  bottom: 11px;
  right: 12px;
}

.filter input {
  width: 100%;
  height: 37px;
  border: none;
  outline: none !important;
  border-radius: 5px;
  text-indent: 5px;
  font-size: 16px;
  /* color: #979797; */
}

/* input[type="date"]::-webkit-calendar-picker-indicator {
  background: rgb(13, 55, 180);
  background: linear-gradient(
    117deg,
    rgba(13, 55, 180, 1) 0%,
    rgba(118, 60, 209, 1) 59%
    
  );
  padding: 11px 12px;
} */
/* input[type="time"]::-webkit-calendar-picker-indicator {
  background: rgb(13, 55, 180);
  background: linear-gradient(
    117deg,
    rgba(13, 55, 180, 1) 0%,
    rgba(118, 60, 209, 1) 59%
    
  );
  padding: 11px 12px;
} */
.fltr-opt {
  outline: none !important;
  padding: 5px 0;
  z-index: 99;
}

.date-time {
  display: flex;
  justify-content: center;
  align-items: center;
  float: right;
  padding: 9px 11px;
  background-color: #4f2cc8;
}

.form-btm-btn {
  display: flex;
  gap: 5px;
  margin-left: 111px;
}

.followonDate {
  padding: 8px 5px;
}

.date-time {
  display: flex;
  justify-content: center;
  align-items: center;
  float: right;
  padding: 9px 11px;
  background-color: #4f2cc8;
}

/* input[type="date"]::-webkit-calendar-picker-indicator {
  background-color: #6a76ff;
  padding: 11px 12px;
} */
.followonDate::-webkit-calendar-picker-indicator {
  display: block;
}

.followon-poupText h6 {
  font-size: 17px;
  font-weight: 500;
  line-height: 28px;
}

.followon-poupText p {
  font-size: 17px;
  font-weight: 500;
}

.check-text {
  display: flex;
  align-items: center;
  gap: 10px;
}

.check-text span {
  font-size: 16px;
  color: rgb(98, 98, 98);
}

.filter {
  position: fixed;
  top: 0;
  right: -300px;
  width: 300px;
  height: 100%;
  z-index: 999;
  background-color: #ffffff;
  padding: 15px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
  transition: right 0.3s ease-in-out;
}

.filterheader {
  font-size: 18px;
}

/* .filter-container {
  border-bottom: 1px solid #d4d4d4;
  padding: 10px 0;
  position: relative;
  margin-bottom: 15px;
} */

/* .filter-container button {
  position: absolute;
  top: -20px;
  right: -8px;
} */

.fltr-opt {
  outline: none !important;
  padding: 5px 0;
  font-size: 15px;
}

.reset {
  border-radius: 40px;
  padding: 5px 11px;
  border: 1px solid #2438ba !important;
  color: #2538bb !important;
  border-radius: 40px;
  background-color: transparent;
  font-size: 15px;
  overflow: hidden;
  position: relative;
}

.filter-btn {
  color: #fff;
  border-radius: 40px;
  padding: 3px 0px;
  background-color: green;
  border: none;
}

.form-btm-btn {
  display: flex;
  gap: 5px;
  justify-content: right;
}

/* -------button----------- */
.Mom-btn {
  color: #fff;
  border-radius: 40px;
  padding: 5px 13px;
  background: rgb(60, 222, 27);
  background: linear-gradient(
    117deg,
    rgba(60, 222, 27, 0.7987570028011204) 0%,
    rgba(18, 105, 1, 0.6558998599439776) 100%
  );
  border: none;
  font-size: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  /* gap: 10px; */
  position: relative;
  overflow: hidden;
  /* z-index: 1; */
}

.Mom-btn svg {
  position: relative;
  z-index: 0;
  /* z-index: 9; */
  color: #fff;
}

.filter-svg {
  margin-left: 4px;
}

.Mom-btn p {
  position: relative;
  z-index: 0;

  font-size: 15px;
  color: #fff;
}

.select option {
  border-radius: 0 !important;
}

.dropdown-toggle.show.btn:focus {
  box-shadow: none !important;
  outline: none !important;
}

.btn:focus {
  box-shadow: none !important;
}

.custom-dropdown .dropdown-toggle::after {
  display: none;
}

.custom-dropdown .dropdown-toggle {
  width: 35px;
  height: 35px;
  border-radius: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0;
  background: #dcd2ff;
  border: none;
}

.custom-dropdown .dropdown-item {
  font-size: 14px;
  display: flex;
  align-items: center;
}

.notification-filter.custom-dropdown .dropdown-toggle {
  width: 40px;
  height: 40px;
  border-radius: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0;
  border: none;
}

.notification-filter.custom-dropdown .dropdown-item {
  font-size: 14px;
  display: flex;
  align-items: center;
  /* justify-content: center; */
}

.top-header-profile .dropdown-toggle {
  padding: 5px 10px 5px 8px;
  width: auto;
  background: transparent;
  color: #232323;
  height: auto;
}

.btn-close {
  box-shadow: none;
  outline: none;
  --bs-btn-close-focus-shadow: none !important;
}

.delete-txt p {
  font-size: 16px;
  color: #8a8a8a;
}

.headerpprofile .dropdown-menu a:focus {
  color: #000000;
  background-color: #763cd1;
}

.delete-txt h6 {
  font-size: 18x;
}

.plus-more-text {
  color: #8a8a8a;
  font-size: 12px;
}

.detail-date-time {
  font-size: 12px;
  font-weight: 400;
  color: #767676;
  word-wrap: break-word;
  /* overflow-wrap: break-word; */
  white-space: normal;
  word-break: break-word;
}

td {
  line-height: 22px;
}

.respond-action {
  border: 1px solid #dee2e6;
  background-color: #e0efff;
  color: black;
  font-size: 12px;
  border-radius: 5px;
}

.respond-button button:hover {
  background-color: rgb(2, 2, 187);
  color: #fff;
}

.respond-button {
  display: flex;
  position: relative;
  justify-content: left;
  gap: 5px;
  margin-top: 5px;
}

th td {
  text-align: left;
  vertical-align: middle;
}

.menu-dropdown .btn:hover {
  background-color: rgb(2, 2, 187);
  color: #fff !important;
}

.menu-dropdown .btn:hover svg {
  filter: invert(1);
}

.FollowOnpopup-left {
  display: flex;
  align-items: center;
  gap: 7px;
}

/* ---------------date-time-day----------- */
input::placeholder,
textarea::placeholder,
select option {
  font-size: 14px;
}

/* input[type="date"]::-webkit-calendar-picker-indicator {
  background: linear-gradient(
    117deg,
    rgba(13, 55, 180, 1) 0%,
    rgba(118, 60, 209, 1) 59%
  );
  padding: 11px 12px;
} */

.input-date {
  position: relative;
}

.input-time,
.input-time2 {
  position: relative;
}

.time {
  position: absolute;
  bottom: 11px;
  right: 12px;
}

.time2 {
  position: absolute;
  bottom: 11px;
  right: 11px;
}

.calender {
  position: absolute;
  bottom: 11px;
  right: 12px;
}

/* 
  padding: 11px 12px;
  font-size: 16px;
} */

/* ---------------------Add Agenda--------------- */
.inner-detail-form label {
  color: rgb(38 40 149);
  font-size: 16px;
}

.agenda {
  border-top: 0;
  border-top-style: dashed;
  padding-top: 0px;
  margin-bottom: 0 !important;
}

input[type="checkbox"] {
  border: 1px solid rgb(202, 183, 221);
  width: 18px !important;
  height: 18px !important;
  width: 20px !important;
  height: 20px !important;
}

.agenda-box-border {
  /* border: 1px solid rgb(222 226 230); */
  border-radius: 5px;
  /* display: flex;
  justify-content: center; */
}

.no-data-img {
  display: flex;
  justify-content: center;
}

.time-taken {
  display: flex;
  gap: 5px;
  align-items: center;
  border: 1px solid rgb(202, 183, 221);
  width: 135px !important;
  border-radius: 5px;
  padding: 0 13px;
}

.time-taken input {
  border: none !important;
}

.minute_box {
  display: flex;
  align-items: center;
}

.accordion-button:focus {
  box-shadow: none !important;
}

.agenda-title {
  border: none !important;
  width: 94%;
  height: 30px;
  background-color: rgb(253 253 253 / 97%);
}

.topic-head {
  background-color: rgb(207 226 255);
}

/* label.topic-head {
  font-size: 15px;
  border-bottom: 1px solid gainsboro;
  padding-bottom: 10px;
} */

.home {
  padding: 10px;
  display: block;
  text-align: center;
}

input,
select {
  width: 100%;
  border: 1px solid rgb(202, 183, 221);
  padding: 0;
  outline: none;
  text-indent: 5px;
  border-radius: 5px;
  height: 40px;
}

textarea {
  width: 100%;
  border-radius: 5px;
  border: 1px solid rgb(202, 183, 221);
  outline: none;
  padding: 5px;
}

.detail-col {
  border-right: 2px solid rgb(222 226 230);
}

.details-form {
  border: 7px;
  border-radius: 10px;
  background-color: #fff;
  padding: 20px;
  /* min-height: 196px; */
  background-color: #fff;
}

.details-form-right label {
  margin: 0 !important;
  color: rgb(38 40 149);
  width: 100%;
}

.details-form-right p {
  font-size: 15px;
}

.details-form-right {
  position: relative;
}

.loader-icon {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.people-circle {
  height: 40px;
  width: 40px;
  border-radius: 50px;
  background-color: rgb(0 41 148);
  display: flex;
  align-items: center;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  font-weight: normal !important;
}

.people {
  gap: 10px;
}

.people-circle-add {
  height: 40px;
  width: 40px;
  border-radius: 50px;
  background: rgb(13, 55, 180);
  background: linear-gradient(
    117deg,
    rgba(13, 55, 180, 1) 0%,
    rgba(118, 60, 209, 1) 59%
  );
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  padding: 7px 10px !important;
}

.star-mark {
  color: red;
}

.add-people-box {
  display: none;
}

.add-people-box.show {
  display: block;
}

.minutes-box {
  display: none;
}

.minutes-box.show {
  display: block;
}

.plus {
  display: flex;
  gap: 13px;
  align-items: center;
}

.save-meet-head {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
}

/* .save-meet-btn {
  margin-top: 20px;
} */

.save-meet-menu {
  position: absolute;
  cursor: pointer;
  top: 54px;
  right: -100%;
  min-height: 50px;
  width: 135px;
  border-radius: 10px;
  font-weight: 500;
  font-size: 14px;
  background-color: rgb(255 252 252);
}

.save-meet-menu.show {
  right: 0;
}

.modal-body .minutes-box {
  display: block !important;
}

.down-arrow {
  width: 12px;
  height: 6px;
}

.attendees {
  display: flex;
  align-items: center;
  justify-content: left;
}

.contact-close {
  float: right;
}

.input-firstname {
  padding-left: 0;
}

.input-lastname {
  padding-right: 0;
}

.add-btn {
  background: rgb(13, 55, 180);
  background: linear-gradient(
    117deg,
    rgba(13, 55, 180, 1) 0%,
    rgba(118, 60, 209, 1) 59%
  );
  color: #fff;
}

.Mom-btn::before {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 0%;
  height: 100%;
  background: linear-gradient(204deg, rgb(171 50 255) 0%, rgb(50 77 255) 100%);
  transition: all 0.3s ease-in;
  border-radius: 10rem;
  /* z-index: -1 !important; */
}

.reset::before {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 0%;
  height: 100%;
  background: linear-gradient(
    117deg,
    rgba(13, 55, 180, 1) 0%,
    rgba(118, 60, 209, 1) 59%
  );
  transition: all 0.3s ease-in;
  border-radius: 10rem;
  /* z-index: -1 !important; */
}

.Mom-btn:hover.Mom-btn::before,
.reset:hover.reset::before {
  width: 100%;
}

.Mom-btn::before,
.reset::before {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 0%;
  height: 100%;
  background: linear-gradient(204deg, rgb(171 50 255) 0%, rgb(50 77 255) 100%);
  transition: all 0.3s ease-in;
  border-radius: 10rem;
  /* z-index: -1 !important; */
}

/* .Mom-btn:hover.Mom-btn::before,
.reset:hover.reset::before,
.reset:hover.reset p {
  left: 0;
  z-index: 0;
  color: #fff;
} */
.action-commentsbox-1 .comment-text {
  white-space: initial;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  line-height: 30px;
}

.comment-text {
  white-space: nowrap;
  overflow: hidden;
  /* text-overflow: ellipsis; */
}

.req-by {
  display: flex;
  align-items: flex-start;
}

.reset p {
  position: relative;
  z-index: 0 !important;
  font-size: 15px;
  font-weight: 400;
}

.Mom-btn:hover .dropdown-toggle::after {
  position: absolute;
  color: #fff !important;
  z-index: 11 !important;
}

.custom-dropdown .dropdown-item a {
  display: flex;
  align-items: center;
}

.status-detail {
  display: flex;
  gap: 15px;
  align-items: center;
}

.rsvpStatus {
  font-size: 16px;
  color: #666666;
}

.SlNo {
  width: 90px;
}

textarea {
  display: block;
  text-indent: 5px;
}

.copyright {
  /* position: absolute;
  bottom: 35px;
  left: 50%;
  transform: translateX(-50%);
  color: white;
  font-size: 13px; */
  position: relative;
  text-align: center;
  bottom: 35px;
  left: auto;
  transform: none;
  color: white;
  font-size: 13px;
  cursor: pointer;
}

.slider-cont {
  width: 100%;
  float: left;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  flex-direction: column;
}

.blue-box-cont {
  padding: 15px 0;
  position: relative;
}

.blue-box {
  height: 100%;
  width: 100%;
  background-color: #352396;
  border-radius: 10px;
  float: right;
  overflow: hidden;
}

.white-box {
  width: 425px;
  background-color: #ffffff;
  border-radius: 10px;
  z-index: 1;
  /* margin-bottom: 40px; */
  display: flex;
  align-items: center;
  justify-content: center;
}

.white-box img {
  width: 70%;
}

.table-box.no-data-img {
  box-shadow: none;
}

.blue-box::before {
  content: "";
  position: absolute;
  z-index: 0;
  background-image: url(./assets/images/background.svg);
  width: 100%;
  height: 100%;
  opacity: 0.2;
  top: 0px;
  left: auto;
  background-size: cover;
}

.blue-box h2 {
  color: #ffffff;
  text-align: center;
}

.blue-box h6 {
  color: #ffffff;
  text-align: center;
  font-size: 24px;
  margin-bottom: 20px;
}

/* svg.bi-plus-circle:before{
  content: '';
  position:absolute;
  background-color: rgb(231 233 242);
  border-radius: 50px;
  padding: 6px;
  height: 35px;
  width: 35px;
} */
.dropdown-menu a:focus {
  color: #ffffff;
  background-color: #763cd1;
}

.dropdown-item:focus {
  color: #fff;
}

/* .dropdown-item:hover {
  background-color: rgb(240 240 240) !important;
}
.dropdown-item:active {
  background-color: rgb(78 19 243) !important;
} */

.hideField {
  display: none;
}

.save {
  color: #ffffff;
  background: rgb(13, 55, 180);
  background: linear-gradient(
    117deg,
    rgba(13, 55, 180, 1) 0%,
    rgba(118, 60, 209, 1) 59%
  );
}

.meeting-organiser span {
  margin-left: 5px;
  font-size: 15px;
}

.organiser {
  font-size: 12px;
  color: rgb(128 128 128);
}

.rsvp-status {
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid #dee2e6;
  padding: 10px 0;
}

.create-meet-sidebar {
  display: none;
}

.sidebar-create-meeting {
  display: none !important;
}

.sidebar-show {
  display: none;
}

/* .sidebar-show.show {
  display: block;
} */

.sidebar-open-now .sidebar {
  display: block;
}

/* .sidebar-open-now{
  transition: all 0.7s ease;
} */
.sub-setting li {
  font-size: 15px !important;
}

th.action-col {
  width: 85px !important;
}

.amendment select {
  width: 120px !important;
  height: auto !important;
  border: none;
  border-bottom: 1px solid black !important;
  border-radius: 0;
  text-align: center;
}

.box-border {
  border-bottom: 1px solid rgb(222 226 230);
  padding-bottom: 24px;
}

.attendees-center .attendees {
  justify-content: center;
}

.resp-grid {
  display: flex;
}

.abstract-bg {
  position: relative;
}

.abstract-bg::before {
  content: "";
  position: absolute;
  z-index: 0;
  background-image: url("./assets/images/common-abstract.svg");
  /* width: 451px;
    height: 100%;
    opacity: 0.1;
    left: -20px;
    background-repeat: no-repeat;
    background-size: contain;
    display: none;
    rotate: 180deg;
    top: 21px;
    height: 100%; */
  width: 480px;
  height: 100%;
  opacity: 0.1;
  right: -27px;
  background-repeat: no-repeat;
  background-size: contain;
  display: none;
  bottom: -485px;
  height: 100%;
}

.abstract-bg::after {
  content: "";
  position: absolute;
  z-index: 0;
  background-image: url("./assets/images/common-abstract.svg");
  rotate: 180deg;
  opacity: 0.1;
  top: -523px;
  height: 100%;
  left: -137px;
  width: 450px;
  background-repeat: no-repeat;
  background-size: contain;
  display: none;
}
.center-error .error-message {
  background: #f16565 !important;
  border-radius: 4px !important;
  bottom: -9px !important;
  color: #fff !important;
  font-size: 12px !important;
  height: auto !important;
  left: -120px !important;
  line-height: 18px !important;
  opacity: 1 !important;
  padding: 1px 5px !important;
  position: absolute !important;
}
.center-error {
  position: relative !important;
}
.error-list .error-item {
  font-size: 14px;
  margin-top: 10px;
}
/* -------------------------RESPONSIVE----------------------- */
@media only screen and (max-width: 1399px) {
  .attendance-tbl .action-cmnt-text {
    width: 140px;
    flex: 1 1;
  }

  .attendance-tbl .attendees-popup-list .attendee-list {
    width: 40px !important;
    height: 40px !important;
    margin-right: 7px !important;
  }
}

@media only screen and (max-width: 1360px) {
  /*   .table-box .table thead {
    display: none;
  } */
  /* .table-box .table tbody,
  .table-box .table tr,
  .table-box .table td {
    display: block;
    width: 100%;
  } */
  .table-box .table tr {
    margin-bottom: 20px;
  }

  /* .table-box .table td {
    text-align: left;
    position: relative;
    border-bottom: 1px solid #dee2e6;
    display: grid;
    align-items: inherit;
    justify-items: left;
    justify-content: start;
    min-height: 50px;
    padding: 0.5rem 0.5rem;
  }
  .table-box .table td::before {
    content: attr(data-label);
    position: relative;
    left: 0;
    width: 50%;
    font-weight: 500;
    font-size: 16px;
    padding-bottom: 10px;
    color: #182a91;
  } */
}

/* .respond-button {
  justify-content: right;
} */
/* .table .attendees {
  justify-content: right;
} */
@media only screen and (max-width: 1299px) {
  .attendees-popup-list .attendee-list {
    width: 35px !important;
    height: 35px !important;
    margin-right: 5px !important;
    font-size: 13px !important;
  }

  .gap-in-field .col-md-12 {
    width: 100% !important;
    float: left;
  }

  .margin-bottom-16 {
    margin-bottom: 16px !important;
  }

  /* .responsive-table .table td div{
    flex-direction: row-reverse;

  } */

  .css-kdc3n8-MuiStack-root {
    justify-content: flex-end;
  }

  .css-kdc3n8-MuiStack-root {
    justify-content: flex-end;
  }

  .table-responsive .actionTitle,
  .table-responsive .des-detail {
    display: contents;
  }

  .resp-padding-left {
    padding-left: 165px !important;
  }

  .tbl-2-responsive tr {
    border-bottom: 1px solid #ddd;
  }

  .tbl-2-responsive tr:last-child {
    border-bottom: 0;
  }

  .tbl-2-responsive .table tr {
    margin-bottom: 0 !important;
  }

  .responsive-right {
    display: inline-flex;
    text-align: left;
  }

  .metting-sts .css-kdc3n8-MuiStack-root {
    justify-content: flex-end;
  }

  .attendees-center .attendees {
    justify-content: right;
  }

  .meeting-td-width {
    min-width: auto;
  }

  .minHeight-for-noData {
    min-height: 47px;
  }

  /* .table-box table tr:nth-child(even) {
    background: #f1f9ff !important;
  } */
  .responsive-table table td {
    background-color: transparent !important;
  }

  .responsive-table .table thead {
    display: none;
  }

  .responsive-table .table tbody,
  .responsive-table .table tr,
  .responsive-table .table td {
    display: block;
    width: 100% !important;
  }

  .responsive-table .table tr {
    margin-bottom: 20px;
  }

  .responsive-table .table td {
    text-align: right;
    position: relative;
    padding-bottom: 15px;
    border-bottom: 1px dashed #ddd !important;
    padding-left: 130px;
  }

  .responsive-table .table td:last-child {
    border-bottom: 0 !important;
  }

  .responsive-table .table td::before {
    content: attr(data-label);
    position: absolute;
    left: 0;
    width: 50%;
    padding-left: 15px;
    font-weight: 500;
    font-size: 16px;
    text-align: left;
  }

  .responsive-table .respond-button {
    justify-content: right;
  }

  .responsive-table .attendees {
    justify-content: right;
  }
}

@media only screen and (max-width: 1199px) {
  .white-box img {
    width: 100%;
  }

  .res-head,
  .res-mt-20 {
    margin-top: 20px !important;
  }

  .responsive-table {
    margin-top: 20px !important;
  }

  .save-meet-head.respmt-20 {
    display: none;
  }
  .meeting-1-details {
    margin-left: 20px !important;
  }

  .detail-right {
    padding-left: 12px !important;
    margin-top: 20px;
  }

  .responsive-table .table td {
    padding-left: 10px !important;
  }

  .panel-db {
    margin-bottom: 25px;
  }
}

@media only screen and (max-width: 1150px) {
  /* .panel-db {
      min-height: 180px;
  } */
  .color-box-resp .col-lg-3 {
    width: 50%;
  }
  .color-box-resp {
    margin-bottom: 40px !important;
  }
}

@media only screen and (max-width: 1050px) {
}

@media only screen and (max-width: 991px) {
  .ntspl-logo img {
    width: 170px;
  }

  .res-991-otp {
    text-align: center;
  }
  .pincode {
    justify-content: center;
  }
  .pincode .digit:last-child {
    margin-right: 0;
  }
  .otp-varify form {
    width: 100% !important;
  }

  .meeting-img {
    display: none;
  }

  .modal-table tr {
    display: grid;
  }

  .tbl-2-responsive .table tr {
    margin-bottom: 0;
  }

  .tbl-2-responsive .attendee-list {
    margin-right: 3px !important;
  }

  .modal-table td {
    border-bottom: 0;
  }

  /* .footer{
    display: block;
  } */
  .sign-in .container-fluid,
  .sign-in .loginform-container,
  .sign-in .blue-box-cont {
    height: auto !important;
  }

  .sign-in,
  .otp-varify {
    overflow: hidden;
  }

  .abstract-bg::before,
  .abstract-bg::after {
    display: block;
  }

  .req-by .text {
    text-align: left;
  }

  .resp-input-margin-bottom {
    margin-bottom: 18px;
  }

  .blue-box-cont {
    display: none;
  }

  .loginform-container .ntspl-logo {
    margin-bottom: 0 !important;
  }

  .sidebar {
    left: -260px;
  }

  .sidebar-open-now .sidebar {
    left: 0;
  }

  .sidebar-close {
    display: block;
  }

  .sidebar-open-btn {
    display: block;
    padding-right: 10px;
    margin-left: -3px !important;
  }

  .topbar-1 .create-meeting-button {
    display: none;
  }

  .create-meet-sidebar {
    display: block;
  }

  .sidebar-create-meeting {
    display: block;
  }

  .line {
    display: none;
  }

  .main-content {
    padding-left: 20px !important;
    padding-right: 20px !important;
  }

  .topbar1-content,
  .topbar-2 {
    padding-left: 20px;
  }

  .sign-in,
  form {
    height: auto !important;
  }

  .filter-form {
    height: 100% !important;
  }

  .loginform-container form {
    height: calc(100vh - 150px) !important;
  }

  form .text {
    text-align: center;
    height: auto;
  }

  .loginform-container,
  .user-login.loginform-container {
    display: grid;
    justify-content: center;
    padding-left: 0px !important;
  }

  /* form{
    width: 100% !important;
  } */
  .blue-box {
    padding: 45px 0;
  }

  .loginform-container .ntspl-logo {
    margin: 0 auto 50px;
  }

  .logo-container {
    text-align: center;
  }
}

@media only screen and (max-width: 767px) {

.resp-recording-view{
  margin:10px 0px;
}

  .recording-video-box {
    margin-bottom: 25px;
  }

  .recording-video-box-wrapper > .col-xl-4:last-child .recording-video-box {
    margin-bottom: 0;
  }

  .attendees-popup .attendee-list {
    font-size: 15px;
  }

  .css-kdc3n8-MuiStack-root {
    justify-content: flex-start;
  }

  .responsive-table .respond-button {
    justify-content: left;
  }

  /* .responsive-table .table td div {
  flex-direction: row;
} */
  .resp-padding-left {
    padding-left: 0 !important;
  }

  .metting-sts .css-kdc3n8-MuiStack-root {
    justify-content: flex-start;
  }

  .attendees-center .attendees {
    justify-content: left;
  }

  .loginform-container {
    padding-left: 0px !important;
  }

  .profile-box {
    border-right: none !important;
  }

  .white-box {
    height: 360px;
    width: 360px;
  }

  .search-box {
    margin-top: 10px;
    width: 100%;
  }

  .tbl-text-search {
    display: grid;
    justify-content: normal;
  }

  .tbl-bottom {
    flex-direction: column;
  }

  .right-tbl-bottom {
    margin-top: 15px;
  }

  .responsive-table .table td {
    text-align: justify;
    padding-top: 42px !important;
    padding-left: 16px !important;
    border-bottom: 1px dashed #ddd !important;
    padding-left: 160px;
  }

  .responsive-table .table td::before {
    padding-left: 15px;
    text-align: left;
    top: 10px;
  }

  .responsive-table .attendees {
    justify-content: left;
  }

  .responsive-table .table td::before {
    width: 100%;
  }

  .responsive-table .table td {
    text-align: left;
  }

  .accordion-button{
    display: flex;
    flex-direction: column;
    align-items: flex-start !important;

  }

  .accordion-button::after {
    position: absolute;
    top:15px;
    right:15px;

  }
}

@media only screen and (max-width: 640px) {

  
  .container-fluid {
    padding-left: 6px;
    padding-right: 6px;
  }

  .msg-box,
  .bell-box,
  .full-screen,
  .theme-switch-container {
    margin: 0 7px;
  }
}

@media only screen and (max-width: 500px) {
  .admin-box svg {
    display: none;
  }

  .resp-grid {
    display: grid !important;
    gap: 7px;
  }

  .resp-grid .padding-left-0 {
    padding-left: 0;
  }

  .text-align-center {
    text-align: left;
  }

  .white-box {
    height: 300px;
    width: 100%;
  }

  .blue-box-cont .white-box {
    width: 300px;
  }

  /* .table thead {
    display: none;
  }
  .table tbody,
  .table tr,
  .table td {
    display: block;
    width: 100%;
  }
  .table tr {
    margin-bottom: 20px;
  }
  .table td {
    text-align: right;
    position: relative;
    padding-bottom: 15px;
    border-bottom: 1px solid #dee2e6;
  }
  .table td::before {
    content: attr(data-label);
    position: absolute;
    left: 0;
    width: 50%;
    padding-left: 15px;
    font-weight: 500;
    font-size: 16px;
    text-align: left;
  } */
  /* .respond-button {
    justify-content: right;
  }
  .attendees {
    justify-content: right;
  } */

  .admin-box span {
    display: none;
  }

  .next-prev-details {
    margin-top: 16px;
  }
}

@media only screen and (max-width: 480px) {


  .Mom-btn p {
    font-size: 13px;
  }

  .agenda-btn-icon {
    gap: 6px !important;
  }

  .alert-box div,
  .alert-send-time p,
  .alert-send-time select {
    width: 100%;
  }

  .button-position .add-minutes {
    width: 100% !important;
  }

  .agenda-accordion .accordion-header span:first-child {
    width: 97px !important;
  }

  span.meeting-title-hover {
    max-width: 180px !important;
  }

  /* .statistics-data {
display: block !important;
} */

  .attendees-popup-list .attendee-list {
    margin-right: 5px !important;
  }

  .active-menu:after {
    top: 98%;
  }

  .active-menu:before {
    bottom: 98%;
  }

  /* .alert-box .d-flex {
    display: grid !important;
    width: 57%;
  } */

  .time-width {
    width: 57%;
  }

  .login-logo {
    max-width: 268px;
  }

  .sign-in form {
    width: 100% !important;
    padding: 0 15px;
  }

  .font-size-15 {
    font-size: 15px;
  }

  .text-3xl {
    font-size: 27px;
  }

  .panel {
    padding: 15px;
  }

  .color-box-resp .panel {
    min-height: 137px;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
  }

  .responsive-flex {
    display: flex;
    align-items: center;
  }

  .mr-2 {
    top: 0 !important;
  }

  .color-box-resp .responsive-flex svg {
    width: 22px;
  }

  .sidebar-open-btn {
    padding-right: 6px;
  }

  .left-tbl-bottom ul li {
    height: 24px;
    width: 24px;
    font-size: 12px;
  }
}

@media only screen and (max-width: 450px) {
.resp-flex-col{
  flex-direction: column;
  align-items: flex-start;
  gap:10px;
}
}

@media only screen and (max-width: 400px) {
  /* .color-box-resp .panel {
    min-height: 197px;
  } */

  .left-tbl-bottom ul {
    gap: 2px;
  }
  /* .recharts-wrapper svg.recharts-surface {
    width: 248px !important;
  } */
  .recharts-wrapper {
    text-align: center;
  }
}

@media only screen and (max-width: 360px) {
  /* .color-box-resp .col-lg-3 {
    width:100%;
    }  */
  .search-box input {
    padding-right: 11px;
  }

  .topbar1-content .meeting {
    font-size: 20px;
  }

  .top-right-svg {
    gap: 11px;
  }

  /* .color-box-resp .col-lg-3 {
    width: 100%;
  } */

  /* .color-box-resp .panel {
    min-height: auto;
  } */

  .color-box-resp .panel {
    min-height: 137px;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
  }
  .left-arrow,
  .right-arrow {
    height: 26px;
    width: 26px;
  }
}

/* ------------------------------------------ */
.agenda-background h2 {
  padding: 1rem;
}

/* .agenda-background h2 button {
  padding: 1rem;
} */
.button-outer {
  margin-top: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.atd-button button {
  padding: 6px 13px !important;
  margin-top: 15px;
}

.agenda-background:has(button[aria-expanded="true"]) h2 {
  background-color: #cfe2ff;
}

.add-btn p {
  margin: 0;
}

.btn-primary {
  background-color: transparent !important;
  border: none !important;
  color: #000 !important;
}

/* .btn-primary:hover {
  background-color: #e3dfda88 !important;
  border-radius: 50px !important;
} */

.dropdown-toggle::after {
  display: none !important;
}

.dropdown button {
  border-radius: 50px !important;
}

.digit input {
  font-size: 15px !important;
  outline: none !important;
  font-weight: 600 !important;
  width: 40px !important;
  height: 40px !important;
  border-radius: 5px !important;
  border: 1px solid gray !important;
  text-align: center;
  /* Pratishruti add the css  text-align: center; for text align center */
}

.otp-Check,
.cpwd-group {
  margin: 25px 0;
}

/* .add-meetings .form-group {
  margin-bottom: 15px;
} */
.btn-primary {
  border-radius: 40px !important;
  padding: 6px 17px !important;
  background: linear-gradient(117deg, #0d37b4, #763cd1 59%) !important;
  color: #fff !important;
  border: none !important;
  font-size: 15px !important;
  display: flex !important;
  justify-content: center !important;
  align-items: center;
  position: relative;
  overflow: hidden;
  z-index: 1;
}

.btn-light {
  border-radius: 40px !important;
  padding: 6px 17px !important;
  background: linear-gradient(117deg, #dfe3f0, #e8e0f5 59%) !important;
  color: #000000 !important;
  border: none !important;
  font-size: 15px !important;
  display: flex !important;
  justify-content: center !important;
  align-items: center;
  position: relative;
  overflow: hidden;
  /* z-index: 1; */
}

/* .btn-light p {
  z-index: 1;
} */

.btn-light::before {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 0%;
  height: 100%;
  background: linear-gradient(204deg, rgb(171 50 255) 0%, rgb(50 77 255) 100%);
  transition: all 0.3s ease-in;
  border-radius: 10rem;
  /* z-index: -1 !important; */
}

.btn-light:hover.btn-light::before {
  left: 0;
  /* z-index: 0 !important; */
  color: #fff !important;
  width: 100%;
}

.btn-light:hover p,
.btn-light:hover {
  color: #fff;
}

.btn-light:hover p {
  z-index: 1;
}

.dropdown-toggle.btn-primary {
  /* background: linear-gradient(117deg, #eaebee, #edecee 59%) !important; */
  background: linear-gradient(117deg, #eaebee, #edf6ff 59%) !important;
  color: #000000 !important;
  border: 1px solid #cfcfcf !important;
}

.dropdown-toggle.add-btn {
  background: rgb(13, 55, 180) !important;
  background: linear-gradient(
    117deg,
    rgba(13, 55, 180, 1) 0%,
    rgba(118, 60, 209, 1) 59%
  ) !important;
  color: #fff !important;
}

.custom-dropdown .dropdown-toggle {
  padding: 0 !important;
}

.custom-dropdown .dropdown-toggle:hover {
  color: #333 !important;
  background: linear-gradient(117deg, #5171d3, #93a4d5 59%) !important;
}

button {
  border: none;
  background: transparent;
}

.set-pswrd .form-group input {
  border: none;
}

.error-message {
  font-size: 12px;
  color: red;
  padding-top: 2px;
}

.alert {
  font-size: 13px;
  text-align: left;
  padding: 10px 30px 10px 10px;
  /* margin-top: 10px; */
}

.form-group .alert::before {
  display: none;
}

.back-resend span {
  display: flex;
  align-items: center;
  color: #e02e5a;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

.respond-action:disabled {
  background-color: rgb(2, 2, 187);
  color: #fff;
}

.dropdown .btn-primary {
  /* background: transparent !important; */
}

.modal-title .attendees-modal {
  border: none !important;
  display: flex;
  justify-content: space-between;
  padding: 0 !important;
}

.modal-content .attendees-modal {
  justify-content: space-between;
  align-items: center;
}

.modal-body .attendee-content {
  margin-bottom: 20px;
}

/* .modal-body .attendee-content:last-child {
/* .modal-body .attendee-content {
  margin-bottom: 18px;
} */
.modal-body .attendee-content:last-child {
  margin-bottom: 0;
}

.modal-content .modal-dialog {
  margin: 0 !important;
}

.modal-footer .Mom-btn {
  border-radius: 30px;
  border: none;
}

.Action-list-page .form-group {
  margin-bottom: 20px;
}

.modal-margin .attendee-content {
  margin-bottom: 0px !important;
  padding: 12px 0px 12px 0;
  display: flex;
  align-items: center;
  border-bottom: 1px dashed #bbb9b9;
}

.modal-margin .attendee-content:last-child {
  border-bottom: none;
}

.modal-margin .attendee-list {
  margin-left: 0 !important;
}

.modal-margin table {
  margin-bottom: 0 !important;
}

.modal-margin .attendee-content:first-child {
  padding-top: 0;
}

.modal-margin .attendee-content:last-child {
  padding-bottom: 0;
}

.create-meet-btn button {
  padding: 5px 18px;
}

.no-padding-2 {
  padding: 0px;
}

.agenda-background h2 {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.reset {
  padding: 4px 11px !important;
  border: 1px solid #6a76ff !important;
  color: #6a76ff !important;
  border-radius: 40px !important;
  background-color: transparent;
  font-size: 17px !important;
  overflow: hidden;
  position: relative;
}

.Mom-btn:hover.Mom-btn:before,
.reset:hover.reset:before,
.reset:hover.reset p {
  left: 0;
  z-index: 0;
  color: #fff !important;
}

.loader-cont {
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  width: 100% !important;
  position: absolute;
  height: 200px;
  left: 0;
  padding: 40px 0;
}

.form-group {
  margin-bottom: 15px;
  position: relative;
}

.alert {
  width: 100%;
}

.attendees-popup .form-check-input {
  margin-right: 5px;
}

.table.table-bordered {
  margin-bottom: 0px;
}

.check-action {
  width: 115px;
}

.form-check label {
  width: max-content;
}

.form-check button {
  padding-right: 0;
  margin-left: 20px;
}

.form-check-wrap button {
  margin-left: 20px;
}

.btn-outerbox {
  display: flex;
  gap: 4px;
  align-items: center;
}

.table-overflow {
  overflow-x: auto;
}

.logo-width img {
  width: 280px;
  border: 1px solid #ddd;
  padding: 20px;
}

.btn-outerbox {
  display: flex;
  gap: 4px;
  align-items: center;
}

.panel {
  position: relative;
  border-radius: 0.375rem;
  opacity: 1;
  background-color: rgb(255 255 255);
  padding: 1.25rem;
}

/* .to-cyan-400 {
  background-image: linear-gradient(to right, #22d3ee, #06b6d4);
} */

/* .to-violet-400 {
  background: linear-gradient(to left, rgb(255, 65, 108), rgb(255, 75, 43));
} */

/* .to-blue-400 {
  background: linear-gradient(to right, rgb(247, 151, 30), rgb(255, 210, 0));
} */

/* .to-fuchsia-400 {
  background: linear-gradient(to right, rgb(118, 184, 82), rgb(155 201 130));
} */

/* Yamuna's style 17-12-2024 */

.to-cyan-400 {
  /* background: #43ced6 url(./assets/images/vector-blue.png) no-repeat; */
  background: #43ced6 url(./assets/images/vector-blue.png) no-repeat;
  background-position: right;
  background-size: contain;
}

.to-cyan2-400 {
  /* background: #43ced6 url(./assets/images/vector-blue.png) no-repeat; */
  /* background: #43ced6 url(./assets/images/dark-blue.png) no-repeat; */
  background: #7239ea url(./assets/images/vector-violet.png) no-repeat;
  background-position: right;
  background-size: contain;
}

.to-violet-400 {
  /* background-color: #7239ea; */
  background: #7239ea url(./assets/images/vector-violet.png) no-repeat;
  background-position: right;
  background-size: contain;
}

.to-blue-400 {
  /* background-color: #35c654; */
  background: #186fef url(./assets/images/dark-blue-1.png) no-repeat;
  background-position: right;
  background-size: contain;
}

.to-fuchsia-400 {
  /* background-color: #f8285a; */
  background: #f1416c url(./assets/images/vector-1.png) no-repeat;
  background-position: right;
  background-size: contain;
}

.to-green-400 {
  /* background-color: #35c654; */
  background: #35c654 url(./assets/images/vector-green.png) no-repeat;
  background-position: right;
  background-size: contain;
}

/* Yamuna's style 17-12-2024 */

.panel-db {
  color: #ffffff;
}

.text-3xl {
  font-size: 1.875rem;
  line-height: 2.25rem;
}

.font-semibold {
  font-weight: 600;
}

.font-bold {
  font-weight: 700;
}

.text-sm {
  font-size: 0.875rem;
  line-height: 1.25rem;
}

.mr-2 {
  margin-right: 0.5rem;
  position: relative;
}

.alert-info {
  font-family: "Roboto", sans-serif !important;
  font-size: 14px !important;
}

.no-image {
  border: 1px solid #ddd;
  border-radius: 0.375rem;
}

.no-image .white-box {
  margin: 0 auto 20px;
}

.agenda-minutes {
  background-color: rgb(242 245 255);
  background: linear-gradient(
    90deg,
    rgb(246 241 255) 0%,
    rgb(229 214 255) 100%
  );
  padding: 15px;
  border: 1px solid rgb(211 221 251);
}

.minutes-border:last-child {
  display: none;
}

.details-form .table {
  margin-bottom: 0;
}

span.badge {
  border-radius: 12px;
}

/*  .agenda-minutes .form-group{
  margin-bottom: 0;
} 

.agenda-minutes .form-group .row{
  margin-bottom: 15px;
}

 */

.agenda-minutes .form-group:last-child {
  margin-bottom: 0px;
}

.agenda-box-border {
  margin-bottom: 20px;
}

.agenda-box-border:last-child {
  margin-bottom: 0px;
}

.topic-head label {
  font-size: 18px;
  padding-left: 10px;
}

.mom-acceptance {
  padding: 20px;
  background-color: #bcffe0;
  border: 1px solid #7ee7b6;
}

.mom-acceptance span {
  margin-left: 15px;
}

.mom-activities .no-data-img {
  padding: 10px 15px 15px 15px;
}

.topic-head {
  border-bottom: 1px solid #dee2e6;
  background: linear-gradient(270deg, #8abce6, #86a8e7, #3e3e99);
}

.topic-head label {
  color: #fff !important;
}

.topbar .headerpprofile button {
  background: transparent !important;
  padding: 0 !important;
  border: 0 !important;
}

.topbar .headerpprofile button.create-meeting-button.Mom-btn {
  padding: 5px 13px !important;
  background: rgb(60, 222, 27);
  background: linear-gradient(
    117deg,
    rgba(60, 222, 27, 0.7987570028011204) 0%,
    rgba(18, 105, 1, 0.6558998599439776) 100%
  ) !important;
}

.topbar .headerpprofile .dropdown-menu.show {
  top: 11px !important;
  right: 0;
  width: 100%;
}

/* .topbar .headerpprofile .dropdown-menu.show .dropdown-item {
  background: transparent;
  font-size: 14px;
} */

.agenda .topic-head {
  color: #fff;
}

.agenda-box-border .form-group:last-child {
  margin-bottom: 0;
}

/* 
.table tr:nth-child(1n) .attendee-list {
  background-color: #5f07b0;
  margin-left: 0 !important;
}
.table tr:nth-child(2n) .attendee-list {
  background-color: #f3b033;
}
.table tr:nth-child(3n) .attendee-list {
  background-color: #16b32b;
}
.table tr:nth-child(4n) .attendee-list {
  background-color: #3a7fff;
}
.table tr:nth-child(5n) .attendee-list {
  background-color: #6dd6fd;
} */

.complete-check input {
  margin-top: 0;
}

.timeline-div {
  justify-content: end;
  gap: 20px;
}

p {
  word-wrap: break-word;
}

.fltr-opt .css-b62m3t-container {
  padding: 0;
  border: none;
}

.css-13cymwt-control {
  border: 1px solid rgb(202, 183, 221) !important;
}

.css-1fdsijx-ValueContainer {
  padding: 0 8px !important;
}

.css-qbdosj-Input {
  margin: 0 !important;
  padding: 0 !important;
}

.uploaded-logo {
  background-color: #ddd;
  height: 100px;
}

.uploaded-logo {
  background-color: #f1f1f1;
  max-height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #ededed;
}

.uploaded-logo img {
  max-width: 100%;
}

.inner-notification img {
  width: 15px;
  height: 15px;
  flex: none;
  margin-top: 2px;
  margin-right: 10px;
}

.inner-notification .heading {
  display: flex;
  word-break: break-word;
}

/* #designationName, #Employee, #Email {
  box-shadow: none;
} */

.no-data-found {
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 30px;
}

.no-data-found img {
  width: 100%;
  height: 100%;
}

.des-detail {
  max-width: 300px;
  width: 300px;
  display: block;
  white-space: pre-wrap;
  font-style: normal;
  word-wrap: break-word;
}

.actionTitle {
  max-width: 200px;
  width: 200px;
  display: block;
  white-space: pre-wrap;
  font-style: normal;
  word-wrap: break-word;
}

/* ---------------------------10-09-24---------------------- */
.imp-field {
  color: #f50a0a;
}

.signin-btn1 {
  /* position: relative;
  overflow: hidden; */
  z-index: 1;
  transition: all 0.3s ease;
}

/* .signin-btn1::before{
 position: absolute;
 content: '';
 bottom: 0;
 left: 0;
 height:0%;
 width: 100%;
 background: linear-gradient(72deg, rgba(28, 73, 207, 1) 0%, rgba(125, 64, 221, 1) 50%);
  transition: 0.5s linear;
 z-index: -1;
 } */
.signin-btn1:hover {
  box-shadow: rgba(0, 0, 0, 0.19) 0px 10px 20px, rgba(0, 0, 0, 0.23) 0px 6px 6px;
}

.responsive-table table .attendees {
  padding-left: 5px !important;
}

.action-col {
  text-align: center;
}

.text-align-center {
  text-align: center;
}

.word-break {
  word-wrap: break-word;
  white-space: initial;
}

.meeting-td-width-2 {
  width: 320px;
}

.color-box-resp {
  margin-bottom: 30px;
}

.meeting-td-width-1 {
  width: 185px;
}

.meeting-td-width-4 {
  width: 230px;
}

.meeting-td-width-5 {
  width: 160px;
}

.meeting-td-width-6 {
  width: 135px;
}

.meeting-td-width-7 {
  width: 100px;
}

.logo-container {
  width: 100%;
}

.filter .css-hlgwow {
  padding: 0 8px !important;
}

.filter .css-19bb58m {
  margin: 0 !important;
  padding: 0;
}

.blue-bold-text {
  color: #182a91 !important;
  font-weight: 700;
}

.tbl-2-responsive {
  padding: 0px !important;
  padding-bottom: 0 !important;
}

.tbl-2-responsive table .attendees {
  padding-left: 0px !important;
}

/* .tbl-2-responsive tr td:last-child{
border-bottom: 0;
} */
.inner-group svg {
  cursor: pointer;
}

.form-group .css-19bb58m,
.inner-detail-form .css-19bb58m {
  margin: 0;
  padding: 0;
}

.form-group .css-hlgwow,
.inner-detail-form .css-19bb58m {
  padding: 0 10px;
}

/* .css-13cymwt-control{
  z-index: 99;
} */
input[type="date"],
input[type="time"] {
  padding: 4px 6px;
}

.modal-body.update-attendee .modal-footer {
  padding-right: 0;
}

.modal-body.update-attendee {
  padding-bottom: 0;
}

a.signin-btn2.user-manual {
  border: none;
  color: gray;
  font-size: 14px;
  margin: 20px 0;
  padding: 0;
  text-align: center;
}

.attendee-content:nth-child(1n) .attendee-list {
  background-color: #5f07b0;
  margin-left: 0 !important;
}

.attendee-content:nth-child(2n) .attendee-list {
  background-color: #f3b033;
}

.attendee-content:nth-child(3n) .attendee-list {
  background-color: #16b32b;
}

.attendee-content:nth-child(4n) .attendee-list {
  background-color: #3a7fff;
}

.attendee-content:nth-child(5n) .attendee-list {
  background-color: #6dd6fd;
}

.att-people .attendee-content {
  margin-left: -15px !important;
}

.att-people .attendee-content:nth-child(1) {
  margin-left: 0 !important;
}

.attendees-popup.send-meeting-details .attendee-list {
  margin-left: 0px !important;
}

.my-modal {
  min-width: 50%;
}

/* .modal-height{
  overflow-y: auto;
  max-height: 460px;
 } */
.modal-body {
  overflow-y: auto;
  max-height: 480px;
}

.modal-attendance {
  margin-top: 25px;
  padding-top: 20px;
  border-top: 1px dashed #c4c4c4;
}

.button-position {
  display: ruby;
}

.modal-height .attendee-content {
  margin-bottom: 0px;
  height: 100%;
  display: flex;
  align-items: center;
}

/* .my-modal .attendees-popup .modal-attendance:nth-child(1n) .attendee-list {
 background-color: red !important; 
} */
.att-people .attendee-content:nth-child(1) {
  margin-left: 0 !important;
}

.attendees-border {
  position: relative;
}

.attendees-border::before {
  position: absolute;
  content: "";
  top: 0;
  left: 52%;
  transform: translate(-50%, -50%);
  height: 10px;
  width: 10px;
  border-radius: 50px;
  border: 2px solid #fff;
  background-color: #16b32b;
}

.separate-pattern {
  position: relative;
  width: 100%;
  margin: 30px 0;
  border: 1px solid #f4f4f4;
  background-color: transparent;
  background-image: url("./assets/images/pattern-bg-clr-1.png");
  padding: 15px 1rem;
}

.dropdown-menu .dropdown-item a {
  font-size: 14px;
  display: flex;
  align-items: center;
}

.mt-7 {
  margin-top: 7px !important;
}

.alert-box select {
  padding: 9px 0;
  margin-right: 7px;
}

.modal-body .attendee-content {
  align-items: center;
  margin-bottom: 20px;
}

.att-people .attendee-content {
  margin-bottom: 0;
}

.css-19bb58m {
  grid-area: 1 / 1 / 1 / 1;
  height: 39px !important;
}

.css-hlgwow {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}

.css-19bb58m {
  height: 100%;
}

/* .btn:hover {
  color: #ffffff !important;
} */
.dropdown-item.active,
.dropdown-item:active {
  background-color: #763cd1 !important;
  color: #fff !important;
}

/* .dropdown-item:hover, .dropdown-item:focus {
  background-color: rgb(246 238 254) !important;
} */

.overflow {
  overflow: hidden;
}

.css-b62m3t-container {
  padding-bottom: 0 !important;
  padding-top: 0 !important;
}

/* [data-label] {
  font-weight: bold !important;
} */
.badge-light-blue {
  --bs-bg-opacity: 1 !important;
  background: rgb(189 220 247);
  color: #444444 !important;
  font-weight: 500 !important;
  font-size: 12px !important;
  margin-top: 10px !important;
}

/* ------------Header-sticky------------ */
.hidden-header {
  top: -100%;
}

body.sticky-header .hidden-header {
  background-color: #fff;
  position: fixed;
  z-index: 9999;
  top: 0 !important;
  transition: top 0.6s ease;
  box-shadow: 0 0 6px #00000026;
}

body.sticky-header .landing-page .menu ul li a {
  color: #000000;
}

body.sticky-header .landing-page .header-btn .book-demo-btn.line-btn button {
  background: transparent;
  border: 2px solid #fc6853;
  color: #fc6853;
}

body.sticky-header
  .landing-page
  .header-btn
  .book-demo-btn.line-btn
  button:hover {
  border: 2px solid #5c3cbf;
}

body.sticky-header .landing-page .menu ul li a::before {
  content: "";
  position: absolute;
  left: 0;
  bottom: -11px;
}

/* .landing-page.visible-sidebar .landing-page .menu-overlay{ 
  display: block;
 } */

.draft-color {
  background-color: #c9c9c9 !important;
  color: #fff !important;
}

.scheduled-color {
  background-color: #2796f3 !important;
  color: #fff !important;
}

.closed-color {
  background-color: #4caf50 !important;
  color: #fff !important;
}

.rescheduled-color {
  background-color: #ab47bc !important;
  color: #fff !important;
}

.canceled-color {
  background-color: #f83e6a !important;
  color: #fff !important;
}

.pending-color {
  background-color: #6252c1 !important;
  color: #fff !important;
}

.requestforReassign-color {
  background-color: #2e7fcb !important;
  color: #fff !important;
}

.reassigned-color {
  background-color: #00ced1 !important;
  color: #fff !important;
}

.forwarded-color {
  background-color: #a767c5 !important;
  color: #fff !important;
}

.completed-color {
  background-color: #4ba550 !important;
  color: #fff !important;
}

.reopened-color {
  background-color: #a7724c !important;
  color: #fff !important;
}

.approved-color {
  background-color: #78bb2c !important;
  color: #fff !important;
}

.NA-color {
  background-color: #d3d3d3 !important;
  color: #000 !important;
}

.delay-color {
  background-color: #ff7300 !important;
  color: #fff !important;
}

.notDelay-color {
  background-color: #4085bd !important;
  color: #fff !important;
}

.forwardrequested-color {
  background: #02c7f5 !important;
}

.accepted-auto-color {
  background-color: #b871f3 !important;
  color: #ffffff !important;
}

.accepted-color {
  background-color: #467e11 !important;
  color: #f7f4f4 !important;
}

.Present-color {
  background-color: #1e90ff !important;
  color: #ffffff !important;
}

.Absent-color {
  background-color: #dc143c !important;
  color: #ffffff !important;
}

.Partial-color {
  background-color: #ffd700 !important;
  color: #000000 !important;
}

.Full-color {
  background-color: #228b22 !important;
  color: #ffffff !important;
}

.headerpprofile .dropdown-menu a:active {
  color: #ffffff !important;
}

.headerpprofile .dropdown-menu a:focus {
  color: #000000;
}

/* .topbar .headerpprofile .dropdown-menu.show .dropdown-item{
  background-color: #763cd1;
} */
.z-index-high {
  z-index: 9999 !important;
}

.line-break {
  word-break: break-all !important;
}

.text-des-comp {
  font-weight: 500 !important;
  color: #757878 !important;
  padding: 2px 5px;
  background-color: rgb(236 236 241);
  border-radius: 3px;
  margin-top: 2px !important;
  font-size: 12px !important;
}

.underline {
  text-decoration: underline;
  position: relative;
  text-decoration-color: rgb(54, 40, 245);
}

#tooltip-top {
  position: absolute;
  left: 0px !important;
}

.add-minutes {
  background: rgb(13, 55, 180);
  background: linear-gradient(
    117deg,
    rgba(13, 55, 180, 1) 0%,
    rgba(118, 60, 209, 1) 59%
  ) !important;
  color: #fff;
  font-size: 8px !important;
  padding: 6px 10px !important;
  border: none;
  display: flex;
  justify-content: center;
  border-radius: 50px;
}

.modal-footer .Mom-btn:hover {
  color: #fff !important;
}

.Mom-btn::before:hover {
  color: #fff !important;
}

.colorRed {
  color: red !important;
}

.dropdown-menu a:focus {
  color: #fff !important;
}

.des-comp span {
  color: #8d95bc;
}

.nsm7Bb-HzV7m-LgbsSe.MFS4be-Ia7Qfc {
  border-radius: 40px !important;
}

.nsm7Bb-HzV7m-LgbsSe .nsm7Bb-HzV7m-LgbsSe-Bz112c-haAclf {
  border-radius: 50% !important;
}

.nsm7Bb-HzV7m-LgbsSe .nsm7Bb-HzV7m-LgbsSe-BPrWId {
  text-indent: -48px;
}

.inner-group input[type="password"] {
  border: none;
}

input:-internal-autofill-selected {
  background-color: transparent !important;
}

.input-pwd-border input {
  border: none !important;
}

.attendee-content {
  cursor: grab;
}

.attendee-content:active {
  cursor: grabbing;
}

#secretToken {
  border: none;
  padding-left: 8px;
}

/* Yamuna's style 17-12-2024 */

.popover-header {
  /* background-color: #35189a !important;   */
  background-color: #d8d0ef !important;
  color: #ffffff !important;
  padding: 8px 16px;
  border-bottom: 1px solid #d8d0ef;
  /* border-bottom: 1px solid #35189a;  */
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
}

.bs-popover-bottom > .popover-arrow::after {
  top: var(--bs-popover-border-width);
  /* border-bottom-color: #35189a !important; */
  /* border-bottom-color:  #d8d0ef!important; */
  border-bottom-color: #a487fc !important;
}

.popover-body {
  color: #080808 !important;
  background-color: #d8d0ef;
  padding: 10px !important;
  border-radius: 8px !important;
  font-size: 15px;
  border: 1px solid #a487fc;
}

.filter-form {
  overflow: hidden;
  position: relative;
  padding-bottom: 70px;
  height: 100%;
}

.filter-form::-webkit-scrollbar {
  display: none;
}

.filter-form {
  scrollbar-width: none;
  -ms-overflow-style: none;
}

.filter-form {
  overflow-y: auto;
}

agenda-accordion {
  border: 1px solid #d4d9f1 !important;
  border-radius: 6px;
  box-shadow: -23px -20px 11px -17px #d4d9f157;
  margin-bottom: 25px;
}

.agenda-accordion .accordion-button {
  border-radius: 6px;
  background: linear-gradient(270deg, #8abce6, #86a8e7, #3e3e99);
  font-size: 18px;
  padding-left: 10px;
  color: #ffffff;
}

.agenda-accordion .accordion-button:not(.collapsed)::after {
  background-image: var(--bs-accordion-btn-active-icon) !important;
  filter: invert(1) brightness(10) !important;
  /* color: #ffffff !important; */
}

.agenda-accordion .accordion-button::after {
  filter: invert(1) !important;
}

.agenda-accordion .accordion-button:not(.collapsed) {
  color: #ffffff;
}

.customized-graph .col-12 {
  padding-left: 0;
}

/* Yamuna's style 17-12-2024 */

.login-with-google-btn {
  transition: background-color 0.3s, box-shadow 0.3s;
  padding: 5px 14px 5px 40px;
  /* padding: 12px 16px 12px 42px; */
  border: none;
  box-shadow: 0 -1px 0 rgba(0, 0, 0, 0.04), 0 1px 1px rgba(0, 0, 0, 0.25);
  color: #757575;
  font-size: 14px;
  font-weight: 520;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
    Ubuntu, Cantarell, "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTgiIGhlaWdodD0iMTgiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PGcgZmlsbD0ibm9uZSIgZmlsbC1ydWxlPSJldmVub2RkIj48cGF0aCBkPSJNMTcuNiA5LjJsLS4xLTEuOEg5djMuNGg0LjhDMTMuNiAxMiAxMyAxMyAxMiAxMy42djIuMmgzYTguOCA4LjggMCAwIDAgMi42LTYuNnoiIGZpbGw9IiM0Mjg1RjQiIGZpbGwtcnVsZT0ibm9uemVybyIvPjxwYXRoIGQ9Ik05IDE4YzIuNCAwIDQuNS0uOCA2LTIuMmwtMy0yLjJhNS40IDUuNCAwIDAgMS04LTIuOUgxVjEzYTkgOSAwIDAgMCA4IDV6IiBmaWxsPSIjMzRBODUzIiBmaWxsLXJ1bGU9Im5vbnplcm8iLz48cGF0aCBkPSJNNCAxMC43YTUuNCA1LjQgMCAwIDEgMC0zLjRWNUgxYTkgOSAwIDAgMCAwIDhsMy0yLjN6IiBmaWxsPSIjRkJCQzA1IiBmaWxsLXJ1bGU9Im5vbnplcm8iLz48cGF0aCBkPSJNOSAzLjZjMS4zIDAgMi41LjQgMy40IDEuM0wxNSAyLjNBOSA5IDAgMCAwIDEgNWwzIDIuNGE1LjQgNS40IDAgMCAxIDUtMy43eiIgZmlsbD0iI0VBNDMzNSIgZmlsbC1ydWxlPSJub256ZXJvIi8+PHBhdGggZD0iTTAgMGgxOHYxOEgweiIvPjwvZz48L3N2Zz4=);
  background-color: #ebebeb;
  background-repeat: no-repeat;
  background-position: 12px 7px;
  /* background-position: 12px 11px; */
  border-radius: 5px;

  &:hover {
    box-shadow: 0 -1px 0 rgba(0, 0, 0, 0.04), 0 2px 4px rgba(0, 0, 0, 0.25);
  }

  &:active {
    background-color: #eeeeee;
  }

  &:focus {
    outline: none;
    box-shadow: 0 -1px 0 rgba(0, 0, 0, 0.04), 0 2px 4px rgba(0, 0, 0, 0.25),
      0 0 0 3px #c8dafc;
  }

  &:disabled {
    filter: grayscale(100%);
    background-color: #ebebeb;
    box-shadow: 0 -1px 0 rgba(0, 0, 0, 0.04), 0 1px 1px rgba(0, 0, 0, 0.25);
    cursor: not-allowed;
  }
}

body {
  text-align: center;
  padding-top: 2rem;
}

.organization-form .form-control.is-invalid:focus,
.was-validated .form-control:invalid:focus,
.organization-form .form-control:focus {
  box-shadow: none !important;
  background: none;
}

.phone-filed input {
  border: none;
}

.organization-form .text {
  margin-bottom: 20px;
}

.sign-in .blue-box-cont {
  height: 100% !important;
}

.bar-graph-tooltip {
  background-color: #f3f3f3 !important;
  border: 1px solid #7109ba;
  border-radius: 5px;
  padding: 8px;
}

.bar-graph-tooltip .intro {
  color: #363636;
  font-weight: 500;
  font-size: 15px;
}

.bar-graph-tooltip .label {
  font-size: 14px;
}

.bar-graph-tooltip .label.high-priority {
  color: #f1416c;
}

.bar-graph-tooltip .label.Normal-priority {
  color: #186fef;
}

.bar-graph-tooltip .label.low-priority {
  color: #35c654;
}

.organization-form .form-control.is-invalid:focus,
.was-validated .form-control:invalid:focus,
.organization-form .form-control:focus {
  box-shadow: none !important;
  background: none;
}

.phone-filed input {
  border: none;
}

.organization-form .text {
  margin-bottom: 20px;
}

.sign-in .blue-box-cont {
  height: 100% !important;
}

.search-id {
  height: 32px !important;
  width: 306px !important;
  margin-top: 0 !important;
}

.search-div {
  display: flex;
  justify-content: end;
}

.next-prev-button-new .left-arrow,
.right-arrow {
  height: 25px !important;
  width: 25px !important;
}

.title-next-prev {
  margin-bottom: 15px;
}

.graph-btn .Mom-btn {
  border-radius: 7px;
  padding: 4px 10px;
}

.graph-btn .Mom-btn p {
  font-size: 14px;
}

.graph-btn .all-btn {
  padding: 6px 7px !important;
}

.graph-btn .user-btn,
.view-details-btn {
  padding: 6px 10px !important;
}

.statistics-data,
.next-prev-button-new {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.next-prev-button-new {
  gap: 5px;
}

.disable-btn {
  color: #fff;
  background-color: #b9babd;
}

.chart-heading {
  margin-bottom: 15px;
}

.chart-min {
  min-height: 510px;
}

.recharts-responsive-container {
  margin-bottom: 20px;
}

.recharts-legend-wrapper {
  bottom: -7px !important;
}

.organization-form .form-control.is-invalid:focus,
.was-validated .form-control:invalid:focus,
.organization-form .form-control:focus {
  box-shadow: none !important;
  background: none;
}

.phone-filed input {
  border: none;
}

.organization-form .text {
  margin-bottom: 20px;
}

.sign-in .blue-box-cont {
  height: 100% !important;
}

.file--upload > label {
  color: #1c1c1c;
  border-color: #ddd;
  font-size: 13px;
}

.file > label {
  font-size: 16px;
  font-weight: 500;
  cursor: pointer;
  outline: 0;
  -webkit-user-select: none;
  user-select: none;
  border: 1px solid #ddd;
  border-radius: 4px;
  background-color: #fff;
  color: #4a4a4a;
  padding: 12px 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 45px;
}

.material-icons {
  margin-right: 5px;
  width: 25px;
}

.file > input {
  display: none;
}

.download-sample {
  border: 1px solid #dddddd;
  /* padding: 8px; */
  border-radius: 5px;
  width: 100%;
  padding: 9px 10px;
  justify-content: center;
  display: flex;
  align-items: center;
  font-size: 16px;
  font-weight: 500;
  cursor: pointer;
  color: #4a4a4a;
}

.verify-email {
  top: 35px;
}

/* Debasis Behera */
.text-editor {
  height: auto;
  max-height: 200px;
  overflow: auto;
  padding-right: 10px;
  word-break: break-word;
}

.text-editor::-webkit-scrollbar {
  width: 6px !important;
  border-radius: 40px !important;
}

.text-editor::-webkit-scrollbar-thumb {
  background: #0f8f92 !important;
  border-radius: 40px !important;
}

.text-editor::-webkit-scrollbar-track {
  background-color: #d9d9d9 !important;
  border-radius: 40px !important;
}

.text-editor h1,
.text-editor em,
.text-editor h2,
.text-editor h3,
.text-editor h4,
.text-editor h5,
.text-editor h6 {
  font-weight: 600;
  font-size: 15px !important;
}

.text-editor ul,
.text-editor ol {
  padding: 0;
  list-style: none;
  padding-left: 16px;
}

.text-editor ol li {
  font-size: 14px;
  list-style: auto;
  margin-bottom: 10px;
}

.text-editor ul li {
  font-size: 14px;
  list-style: disc;
  margin-bottom: 10px;
}

.text-editor ul li:last-child,
.text-editor ol li:last-child {
  margin-bottom: 0px;
}

/* .text-editor li,
p,
span,
strong,
b {
  font-size: 14px;
} */

.text-editor li,
.text-editor p,
.text-editor span,
.text-editor strong,
.text-editor b {
  font-size: 14px;
}

.no-border {
  border: none !important;
}

.ql-editor {
  min-height: 280px;
}

.accordianagenda .accordion-item {
  position: relative;
}

.table-box.responsive-table {
  height: 100%;
}

/* .accordianagenda .accordianagenda-edit-delete {
  position: absolute;
  right: 50px;
  top: 11px;
  z-index: 99;
} */

.recording-btn {
  color: #747588;
  margin-right: 10px;
  border: 1px solid #747588;
  padding: 7px;
  border-radius: 10px;
  border-style: dashed;
  font-size: 15px;
  text-decoration: none !important;
  font-weight: 500;
}

.delay-status-before {
  font-size: 12px !important;
}

.show-token {
  border: none !important;
  padding-left: 8px !important;
}

.timeline {
  --bs-timeline-icon-size: 38px;
  --bs-timeline-icon-space: 0.35rem;
  text-align: left;
}

.timeline .timeline-item {
  position: relative;
  padding: 0;
  margin: 0;
  display: flex;
  align-items: flex-start;
}

.timeline.timeline-border-dashed .timeline-line {
  border-left-style: dashed !important;
}

.timeline .timeline-line {
  display: block;
  content: " ";
  justify-content: center;
  position: absolute;
  z-index: 0;
  left: 0;
  top: 38px;
  bottom: 0;
  transform: translate(50%);
  border-left-width: 1px;
  border-left-style: solid;
  border-left-color: #dbdfe9;
  width: 38px;
  margin-top: 0.35rem;
  margin-bottom: 0.35rem;
}

.timeline.timeline-border-dashed .timeline-icon {
  border-style: dashed !important;
}

.timeline .timeline-icon {
  z-index: 1;
  flex-shrink: 0;
  margin-right: 1rem;
  width: 38px;
  height: 38px;
  display: flex;
  text-align: center;
  align-items: center;
  justify-content: center;
  border: 1px solid #dbdfe9;
  border-radius: 50%;
}

.timeline .timeline-content {
  width: 100%;
  overflow: auto;
  margin-bottom: 1.5rem;
}

.mt-n1 {
  margin-top: -0.25rem !important;
}

.mb-10 {
  margin-bottom: 2.5rem !important;
}

.fs-7 {
  font-size: 13px !important;
}

.timeline .attendee-list {
  margin-left: 0 !important;
}

.timeline.timeline-border-dashed {
  padding: 0;
}

.timeline.timeline-border-dashed:before {
  display: none;
}

.timeline.timeline-border-dashed .reset p {
  font-size: 13px;
  font-weight: 500;
}

.timeline-item:last-child .timeline-line {
  display: none;
}

/* body .fade.modal-backdrop.show:nth-child(1) {
  --bs-backdrop-zindex: 99999 !important;
} */

/* body .modal-backdrop:nth-of-type(1) {
  --bs-backdrop-zindex: 1050 !important;
}

body .modal-backdrop:nth-of-type(3) {
  --bs-backdrop-zindex: 99999 !important;
} */

.recording-video-box {
  width: 100%;
  float: left;
  margin-top: 50px;
}

.recording-video-box-wrapper .col-xl-4:nth-child(1) .recording-video-box,
.recording-video-box-wrapper .col-xl-4:nth-child(2) .recording-video-box,
.recording-video-box-wrapper .col-xl-4:nth-child(3) .recording-video-box {
  margin-top: 0;
}

.recording-video-box h2 {
  font-size: 20px;
  margin-top: 12px;
  margin-bottom: 3px;
}

.video-box {
  width: 100%;
  height: 215px;
  background-image: url(./assets/images/background-MV.jpg);
  border-radius: 10px;
  position: relative;
}

.video-size {
  display: block;
  color: #757373;
  margin-bottom: 10px;
}

.download-one {
  background: #9b75fe !important;
  color: #ffffff !important;
  padding: 2px 5px !important;
  font-size: 12px !important;
}

.delete-one {
  background: #ca0202 !important;
  color: #ffffff !important;
  padding: 2px 5px !important;
  font-size: 12px !important;
}

.video-play-button {
  position: absolute;
  z-index: 10;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
  box-sizing: content-box;
  display: block;
  width: 32px;
  height: 44px;
  /* background: #eb2055; */
  border-radius: 50%;
  padding: 18px 20px 18px 28px;
}

.video-play-button:before {
  content: "";
  position: absolute;
  z-index: 0;
  left: 50%;
  top: 50%;
  transform: translateX(-50%) translateY(-50%);
  display: block;
  width: 55px;
  height: 55px;
  background: #eb2055;
  border-radius: 50%;
  animation: pulse-border 1100ms ease-out infinite;
}

.video-play-button:after {
  content: "";
  position: absolute;
  z-index: 1;
  left: 50%;
  top: 50%;
  transform: translateX(-50%) translateY(-50%);
  display: block;
  width: 60px;
  height: 60px;
  background: #eb2055;
  border-radius: 50%;
  transition: all 200ms;
}

.video-play-button span {
  display: block;
  position: relative;
  z-index: 3;
  width: 0;
  height: 0;
  border-left: 19px solid #fff;
  border-top: 12px solid transparent;
  border-bottom: 12px solid transparent;
  top: 10px;
  left: 5px;
}

@keyframes pulse-border {
  0% {
    transform: translateX(-50%) translateY(-50%) translateZ(0) scale(1);
    opacity: 1;
  }

  100% {
    transform: translateX(-50%) translateY(-50%) translateZ(0) scale(1.5);
    opacity: 0;
  }
}

.download-one svg,
.delete-one svg {
  width: 15px;
}

.alert-wrapper {
  width: 100%;
  left: 0;
  animation: 0;
  position: absolute;
  z-index: 99999;
  background: rgb(0 0 0 / 50%);
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.alert-container {
  background: #ffffff;
  padding: 40px 30px;
  border-radius: 10px;
  width: 460px;
}

.alert-icon-content {
  color: #f58646;
  border: 2px solid #f58646;
  font-size: 5rem;
  line-height: 5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 90px;
  height: 90px;
  border-radius: 50px;
  margin: 0 auto 20px;
}

.alert-title {
  font-size: 1.125rem;
  font-weight: 600;
  line-height: 1.5715;
  text-align: center;
  position: relative;
  word-wrap: break-word;
  margin-top: 0.3125rem;
  margin-bottom: 0;
}

.alert-html-container {
  z-index: 1;
  text-align: center;
  position: relative;
  word-wrap: break-word;
  margin: 10px;
  word-wrap: break-word;
  word-break: break-word;
}

.alert-actions {
  display: -ms-flexbox;
  display: flex;
  z-index: 1;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-pack: center;
  justify-content: center;
  width: 100%;
  margin-top: 1.25rem;
}

.alert-actions > button + button {
  margin-left: 1.25rem;
}

.alert-cancel {
  border-radius: 30px !important;
  border: none !important;
}

.alert-html-container span {
  color: #938e8e;
}

.attendee.attendee-list.sl {
  cursor: grab;
}

.attendee.attendee-list.sl:active {
  cursor: grabbing;
}

.attendee-content.drag-attendees {
  margin-right: 10px;
}

.meeting-f-search {
  display: flex;
  gap: 20px;
}

.timeline-item:nth-child(5n + 1) .attendee-list.pk {
  background-color: rgb(95, 7, 176);
}

.timeline-item:nth-child(5n + 2) .attendee-list.pk {
  background-color: rgb(243, 176, 51);
}

.timeline-item:nth-child(5n + 3) .attendee-list.pk {
  background-color: rgb(22, 179, 43);
}

.timeline-item:nth-child(5n + 4) .attendee-list.pk {
  background-color: rgb(58, 127, 255);
}

.timeline-item:nth-child(5n + 5) .attendee-list.pk {
  background-color: rgb(109, 214, 253);
}

/* Debasis */
.complete-check {
  display: flex;
  gap: 8px;
}

.complete-check .form-check-input {
  position: relative;
  top: 3px;
}

.sign-in .error-message,
.login-page .error-message,
.organization-update .error-message {
  background: #f16565;
  border-radius: 4px;
  bottom: -9px;
  color: #fff !important;
  font-size: 12px;
  height: auto;
  left: 14px;
  line-height: 18px;
  opacity: 1 !important;
  padding: 1px 5px;
  position: absolute;
  top: auto;
  width: auto;
}

.organization-update .sucess-message-org {
  background: #008000;
  border-radius: 4px;
  bottom: -12px;
  color: #fff !important;
  font-size: 12px;
  height: auto;
  left: 14px;
  line-height: 18px;
  opacity: 1 !important;
  padding: 1px 5px;
  position: absolute;
  top: auto;
  width: auto;
}

.top-heading-bg-diff {
  background: linear-gradient(270deg, #9d66f3, #443ac3, #162891);
  /* color: #000000; */
  /* background: transparent; */
  border: 5px solid #e4e2fb;
}

.sidebar::-webkit-scrollbar {
  width: 6px;
}

.sidebar::-webkit-scrollbar-thumb {
  background: rgb(123 49 185);
  border-radius: 5px;
}

.sidebar::-webkit-scrollbar-track {
  background: rgb(99 220 74);
}

input:-webkit-autofill {
  background-color: white !important;
  /* Your preferred background color */
  -webkit-box-shadow: 0 0 0 1000px white inset !important;
  /* Match background color */
  -webkit-text-fill-color: black !important;
  /* Text color */
}

input:-webkit-autofill {
  transition: background-color 0s ease-in-out, color 0s ease-in-out;
}

.icon-container {
  display: flex;
  flex-wrap: wrap;
  /* Allows icons to move to a new line */
  gap: 0px;
  /* Adds space between icons */
  justify-content: flex-start;
}

.icon-wrapper-center {
  display: flex;
  justify-content: center;
}

.icon-wrapper-left {
  display: flex;
  justify-content: flex-start;
}

/* Reset margin-left for the first icon in each new row */
.icon:nth-child(22 + 1) {
  margin-left: 50px;
  /* Apply left padding/margin only to the first icon of each row */
}

.attendee-content {
  margin-left: 10px;
  /* Default spacing */
}

/* Add extra left margin to the first element in each row */
.attendee-content:nth-child(22 + 1) {
  margin-left: 50px;
}

/* added by monalisa */

.sidebar .top-logo {
  height: 55px;
}

/* CSS added by pratishruti */

.plus-icon {
  position: fixed;
  bottom: 24px;
  right: 24px;
  height: 45px;
  width: 45px;
  background: linear-gradient(
    117deg,
    rgba(60, 222, 27, 0.8) 0%,
    rgba(18, 105, 1, 0.65) 100%
  );
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 32px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
  cursor: pointer;
  border: none;
  color: white;
  border-radius: 50%;
  transition: all 0.3s ease-in-out;
  z-index: 1000;
}

.plus-icon:hover {
  background: linear-gradient(204deg, rgb(171 50 255) 0%, rgb(50 77 255) 100%);
  transition: all 0.3s ease-in;
}

.ql-editor.ql-blank {
  height: 150px !important;
  min-height: 150px !important;
}

/* CSS added by pratishruti  */

/* for minute description box */
.action-right-option .form-group {
  margin-bottom: 0 !important;
}
.action-right-option .form-check-input {
  margin-right: 5px;
  height: 15px !important;
  width: 15px !important;
}
.ag-data {
  padding: 20px !important;
}
.add-agenda-modal-dp button {
  margin-left: 12px;
  position: relative;
  height: 30px;
  border-radius: 50% !important;
  padding: 5px 15px !important;
}
.add-agenda-modal-dp button svg {
  position: absolute;
}
.agenda-accordion.accordion-item {
  position: relative !important;
}
/* .agenda-accordion .accordianagenda-edit-delete {
  position: absolute;
  right: 50px;
  top: 11px;
  z-index: 99;
} */

/* .inner-mt{
  margin-top: 20px;
} */
/* .edit-meeting-left-accordion{
  width: 93%;
} */

.edit-meeting-left-accordions {
  width: 100%;
}
.action-right-option .form-group {
  margin-top: 5px;
}
.action-right-option button.btn-close {
  margin-top: 5px;
}
/* for minute description box */

@media only screen and (max-width: 767px) {
  .alert-box div,
  .alert-send-time p,
  .alert-send-time select {
    width: 100%;
  }
  .search-div {
    justify-content: flex-start;
  }

  .search-box {
    width: 100% !important;
  }

  .alert-box div {
    text-align: left !important;
  }

  .attendee-content {
    flex-wrap: wrap;
  }
}

.d-none-div {
  display: none !important;
}

@media only screen and (min-width: 1150px) and (max-width: 1270px) {
  .color-box-resp .panel {
    min-height: 205px;
  }
}

@media only screen and (max-width: 560px) {
  .notifyaction-modal-checkattendee-right .css-b62m3t-container {
    width: 100%;
  }

  .notify-modal-popup .check-attendee {
    align-items: flex-start;
  }

  /* .agenda-box-border .form-group:first-child .col-md-4,
 .agenda-box-border .form-group:first-child .col-md-8 {
padding:0;
} */

  .agenda-accordion .accordion-header span:first-child {
    width: 103px;
    max-width: 108px;
  }

  .notify-modal {
    flex-direction: column;
  }
  .notifyaction-modal-checkattendee-right {
    width: 100% !important;
  }
  .attendee-content {
    flex-wrap: wrap;
    flex-direction: column;
  }

  .modal-body .attendee-content {
    align-items: flex-start;
    margin-left: 0;
  }
  .check-attendee {
    gap: 5px;
  }
  .action-cmnt-text {
    margin-top: 10px;
  }
}

.panel {
  flex-direction: column;
}

@media only screen and (max-width: 514px) {
  /* .color-box-resp .panel{
    min-height: 205px;
  } */
}

.page-heading {
  margin-bottom: 10px;
}

.meeting-header-text h4,
.page-heading h4 {
  margin-bottom: 0px;
}

.attendees-popup .comment-text {
  word-wrap: break-word;
  overflow-wrap: break-word;
  white-space: normal;
  word-break: break-word;
}

.mr-10 {
  margin-right: 10px;
}

@media only screen and (max-width: 1200px) {
  .respmt-20 {
    margin-top: 20px;
    margin-bottom: 15px;
  }
}

.custom-quill-editor .ql-editor {
  min-height: 150px;
  max-height: 300px;
  overflow-y: auto;
}

span.meeting-title-hover {
  /* white-space: nowrap; */
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 420px;
}
.notify-action-modal-body .agenda-minutes {
  width: 100%;
}
.notify-modal-popup .check-attendee {
  gap: 7px;
}
.notifyaction-modal-checkattendee-right {
  width: 50%;
}
.notifyaction-modal-checkattendee-right .css-b62m3t-container {
  width: 100%;
}
.notifyaction-modal-checkattendee-right .css-19bb58m {
  margin: 0 !important;
  padding: 0 !important;
  grid-area: 1 / 1 / 2 / 3;
}
.notifyaction-modal-checkattendee-left input {
  margin: 0 !important;
}
.notifyaction-modal-checkattendee-right .css-13cymwt-control {
  height: 38px;
}
.notifyaction-modal-checkattendee-right .css-t3ipsp-control {
  height: 38px;
}
.notifyaction-modal-checkattendee-right .css-1cfo1cf {
  margin: 0 !important;
  padding: 0 !important;
}
.notifyaction-modal-checkattendee-right .css-13cymwt-control .css-hlgwow {
  height: 38px;
}
.notifyaction-modal-checkattendee-right .css-13cymwt-control .css-1wy0on6 {
  height: 38px;
}
.notifyaction-modal-checkattendee-right .css-b62m3t-container {
  height: 38px;
}
.notifyaction-modal-checkattendee-right .css-1f43avz-a11yText-A11yText {
  height: 38px;
}
.notifyaction-modal-checkattendee-right .css-1nmdiq5-menu {
  z-index: 9999999999999;
}
/* ////////// pratishruti//////////////////// */
.MuiInputBase-input::placeholder {
  color: black !important;
}
fieldset.css-1d3z3hw-MuiOutlinedInput-notchedOutline {
  border-color: rgb(202, 183, 221);
}
.MuiSvgIcon-root.MuiSvgIcon-fontSizeMedium.css-i4bv87-MuiSvgIcon-root {
  fill: #7254af;
  font-size: 19px;
}
.css-jv54yp-MuiList-root-MuiMultiSectionDigitalClockSection-root::after {
  display: block;
  content: "";
  height: 0 !important;
}
.css-jv54yp-MuiList-root-MuiMultiSectionDigitalClockSection-root:not(
    :first-of-type
  ) {
  border-left: 1px solid rgba(0, 0, 0, 0.12);
  width: 70px !important;
}
.css-jv54yp-MuiList-root-MuiMultiSectionDigitalClockSection-root {
  scroll-behavior: auto;
  width: 70px !important;
}
.css-1roo1oa-MuiList-root-MuiMultiSectionDigitalClockSection-root {
  width: 70px !important;
}
.css-1roo1oa-MuiList-root-MuiMultiSectionDigitalClockSection-root::after {
  height: 0 !important;
}
.css-154xyx0-MuiInputBase-root-MuiOutlinedInput-root {
  height: 40px;
}
svg.MuiSvgIcon-root.MuiSvgIcon-fontSizeMedium.css-i4bv87-MuiSvgIcon-root {
  fill: #7254af;
}
.css-19qh8xo-MuiInputBase-input-MuiOutlinedInput-input.Mui-disabled {
  -webkit-text-fill-color: rgb(84, 84, 84) !important;
}
.css-154xyx0-MuiInputBase-root-MuiOutlinedInput-root:hover
  .MuiOutlinedInput-notchedOutline {
  border-color: rgb(202, 183, 221) !important;
}

.css-23p0if-MuiButtonBase-root-MuiPickersDay-root {
  width: 30px !important;
  height: 30px !important;
}

.notification-dropdown {
  padding-left: 10px;
}
/* .MuiPickersDay-root.MuiPickersDay-today {
  border-radius: 0 !important;
  background-color: #2424c7 !important;
  color: #fff !important;
}
.css-1wy8uaa-MuiButtonBase-root-MuiPickersDay-root.Mui-selected {
  color: #fff;
  background-color: #6e8398;
  border-radius: 0 !important;
} */

.uploaded-logo img {
  width: 60%;
  object-fit: cover;
}

.due-action-table {
  margin-top: 0.5rem;
}

.mom-activities-list .form-group {
  padding: 10px 0px;
}

.notify-modal {
  align-items: center;
}

/* PRATISHRUTI ADD THE CSS FOR SET PASSWORD ERROR MESSAGE */
.cpwd-group {
  position: relative;
}

.cpwd-group .error-message {
  background: #f16565;
  border-radius: 4px;
  bottom: -9px;
  color: #fff !important;
  font-size: 12px;
  height: auto;
  left: 14px;
  line-height: 18px;
  opacity: 1 !important;
  padding: 1px 5px;
  position: absolute;
  top: auto;
  width: auto;
}
.pwd-group {
  position: relative;
}
.pwd-group .error-message {
  background: #f16565;
  border-radius: 4px;
  bottom: -9px;
  color: #fff !important;
  font-size: 12px;
  height: auto;
  left: 14px;
  line-height: 18px;
  opacity: 1 !important;
  padding: 1px 5px;
  position: absolute;
  top: auto;
  width: auto;
}
.otp-Check {
  position: relative;
}
.otp-Check .error-message {
  background: #f16565;
  border-radius: 4px;
  bottom: -9px;
  color: #fff !important;
  font-size: 12px;
  height: auto;
  left: 14px;
  line-height: 18px;
  opacity: 1 !important;
  padding: 1px 5px;
  position: absolute;
  top: auto;
  width: auto;
}
/* PRATISHRUTI ADD THE CSS FOR SET PASSWORD ERROR MESSAGE */

.right-agenda-div,
.right-div {
  gap: 8px;
}

.notification-back-icon {
  filter: invert(1);
}

.all-notification-icon {
  width: 19px;
  height: 19px;
  margin-right: 8px;
}
.back-btn p {
  z-index: 1;
}
.organization-component input{
  border: none !important;
  padding: 9px 5px !important;
}
.organization-component .inner-group{
  padding: 0 8px !important;
}
.organization-component input:focus{
  outline: none !important;
  box-shadow: none !important; 
}

.inner-group{
  padding-left:12px !important;
  padding-right: 0px !important;
}
.not-visible-btn{
  height: 32px;
  width: 20px;
  visibility: hidden;
}