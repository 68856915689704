.otp-varify {
    width: 100%;
    height: 100vh;
    float: left;
    background-color: white;
}


.otp-varify .container-fluid,
.otp-varify .loginform-container,
.otp-varify .blue-box-cont {
    width: 100%;
    height: 100vh;
}

.loginform-container {
    width: 100%;
    float: left;
    padding-left: 50px;
    padding-top: 45px;
}

.otp-varify form {
    position: relative;
    width: 400px;
    /* display: flex; */ 
    justify-content: center;
    flex-direction: column;
    height: calc(100vh - 135px) !important;
    display: flex !important
;
}

.text h4 {
    font-weight: 700;
}

.otp-page .form-group {
    margin: 35px 0 !important;
}

.btn1 {
    padding: 7px 10px;
    width: 100%;
    border-radius: 20px;
    background-color: #5522b2;
    color: white;
    border: none;
    font-size: 14px;
}

.btn1 a {
    text-decoration: none;
    color: white;
}

.pincode {
    display: flex;
}

.digit {
    margin-right: 10px;
}

.digit input {
    font-size: 15px;
    outline: none;
    font-weight: 600;
    width: 40px;
    height: 40px;
    border-radius: 5px;
    border: 1px solid gray;
}

.back-resend {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 25px 0;
    font-size: 14px;
}

.resend a {
    color: gray;
}

.back {
    display: flex;
    align-items: center;
    gap: 8px;
}

.back span {
    color: rgb(11 119 232);
}

a {
    text-decoration: none;
}
form.set-password1{
    height: calc(100vh - 100px) !important;
    width: 400px;
}