.position-relative {
  position: relative;
}

.clear-button {
  position: absolute;
  top: 50%;
  right: 10px;
  transform: translateY(-50%);
  background: none;
  border: none;
  font-size: 20px;
  cursor: pointer;
}

.fontSize {
  font-size: 12px !important;
}


.backToSignIn-button{
  z-index:15;
}



.enter-otp-email {
  position: relative;
}

.get-otp-button {
  position: absolute;
  right: 8px;
  top: 35px;
  border-radius: 4px 4px !important;
  padding: 0.3em 0.65em !important;
  font-size: 0.75em !important;
  cursor: pointer;
  color: #fff !important;
  background-color: RGBA(25, 135, 84, var(--bs-bg-opacity, 1)) !important;
}

/* .enter-otp input {
      display: flex;
      align-items: center;
      border: 1px solid rgb(202, 183, 221) !important;
      padding: 0 14px !important;
      border-radius: 5px;
      background: #fff;
      margin-left: 0 !important;
} */

input:-internal-autofill-selected {
  background-color: #fff !important;
}

.sign-in .blue-box-cont {
  width: 100%;
  height: 100% !important;
}


input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  -webkit-box-shadow: 0 0 0 1000px transparent inset !important;
  box-shadow: 0 0 0 1000px transparent inset !important;
  background-color: transparent !important;
  -webkit-text-fill-color: inherit !important;
}


.organization-update form {
  width: 100% !important;
  height: auto !important;
  margin-top: 100px;
  z-index: 1;
}



.org-button {
  /* width: 400px; */
  margin: 20px auto;
}

.email-org {
  padding-right: 74px !important;
}



.organization-update .blue-box-cont {
  padding: 0;
  width: 50%;
  position: fixed;
  right: 0;
  top: 0;
  height: 100vh;
}

.organization-update .row>.col-xl-6:nth-child(2) {
  padding: 0;
}

.organization-update .loginform-container {
  padding: 25px 50px !important;
  height: auto;
}

.organization-update .blue-box {
  border-radius: 0;
}


@media only screen and (max-width: 767px) {
.organization-update .loginform-container{
  padding:20px !important
}

  .resp-padd-0{
  padding:0px !important;
  }

  .organization-update form{
    margin-top:40px !important;
  }
}


@media only screen and (max-width: 576px) {


  /* .sign-in form {   
    height: calc(100vh - -70px) !important;
  } */
}


@media only screen and (max-width: 480px) {

  .org-button {
    width: 100%;
  }

}

.sucess-message-org {
  color: #019701 !important;
  font-size: 12px;
  padding-top: 2px;
}

.backToSignIn-button a {
  color: #1c6efd !important;
  font-size: 15px;
  padding-left: 5px;
}
