.bell {
  display: flex;
  align-items: center;
  justify-content: center;
  height: auto;
  width: auto;
  padding: 8px;
  background-color: transparent;
  border-radius: 50px;
  border: 1px solid #ddd;
}

.bell:hover {
  background-color: #80808045;
}

.footer {
  float: left;
  width: 100%;
  position: relative;
  bottom: 0;
  padding: 15px 0;
  z-index: 99;
  display: none;
}

.footer .create-meeting-button {
  display: flex;
  justify-content: end;
}