.form-group input {
    border: 1px solid rgb(202, 183, 221);
}

.agenda-background {
    width: 100%;
    border: 1px solid #dcdcdc;
}

#children-pane h2 {
    font-size: 17px;
    margin: 0;
}

.open-div .collapse {
    padding: 1rem;
    border-top: 1px solid #80808042;
}

.selected-page {
    color: "blue";
}

.fltr-opt {
    z-index: auto !important;
}

/* .css-b62m3t-container {
    z-index: 2 !important;
} */
.z-index-high{
    z-index: 9999 !important;
  }

  @media only screen and (max-width: 767px) {
   .resp-flex-column{
    flex-direction: column;
   }

   .meeting-f-search {
    flex-direction: column;
    gap:12px;
   }
}

@media only screen and (max-width: 480px) {
    .table-box {
        padding: 16px 8px;
    }
}

.agenda-btn-icon {
    display: flex;
    align-items: center;
    gap:15px;
}