.round-bg1 {
    height: 30px;
    width: 30px;
    border-radius: 50%;
    background-color: #002994;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #ffffff;
    font-size: 13px;
    font-weight: 500;
}

.round-bg2 {
    height: 30px;
    width: 30px;
    border-radius: 50%;
    background-color: #cc0808;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #ffffff;
    font-size: 13px;
    font-weight: 500;
}

.round-bg3 {
    height: 30px;
    width: 30px;
    border-radius: 50%;
    background-color: #007994;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #ffffff;
    font-size: 13px;
    font-weight: 500;
}


.notification {
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid #caedff;
    padding: 20px 20px;
    
    
}

.notification:last-child {
    border-bottom: 0;
}

.inner-notification {
    display: flex;
    gap: 10px;
}

.heading {
    font-weight: 700;
}

.msg p {
    font-weight: 500;
    font-size: 14px;
}

.topic {
    color: grey;
    font-size: 14px;
}

.date {
    color: #a9a9a9;
    font-size: 13px;
    margin-top: 6px;
}

.dots {
    height: 30px;
    width: 30px;
    border-radius: 50%;
    background-color: #8080803d;
    display: flex;
    align-items: center;
    justify-content: center;
    border: none;
}

.bell {
    display: flex;
    align-items: center;
    position: relative;
}

.bell::before {

    height: 13px;
    width: 13px;
    border-radius: 50%;
    background-color: red;
    position: absolute;
    top: -6px;
    left: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 10px;
    color: white;
}

.pop-up1 {
    min-width: 120px;
    background-color: #fff;
    box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.1);
    border: 1px solid #ddd;
    position: absolute;
    right: 0px;
    top: 235px;
    padding: 5px 5px;
    font-weight: 500;
}

.pop-up2 {
    min-width: 120px;
    background-color: #fff;
    box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.1);
    border: 1px solid #ddd;
    position: absolute;
    right: 0px;
    top: 235px;
    padding: 5px 5px;
    font-weight: 500;
}

.pop-up3 {
    min-width: 120px;
    background-color: #fff;
    box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.1);
    border: 1px solid #ddd;
    position: absolute;
    right: 0px;
    top: 235px;
    padding: 5px 5px;
    font-weight: 500;
}

.item {
    display: flex;
    align-items: center;
    gap: 8px;
    cursor: pointer;
    padding: 3px 8px;
}

.item:hover {
    background-color: rgba(175, 174, 174, 0.137);
    border: 5px;
}

.notification-filter.custom-dropdown .Mom-btn .dropdown-toggle {
    width: 0 !important;
    height: 0 !important;
    border-radius: 0 !important;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 11px 25px !important;
    border: none;
    background-color: transparent;
}

.filter-svg {
    position: relative !important;
    z-index: 99 !important;
    color: #fff !important;
}

.unseen {
    background-color: #eaf8ff;
}
