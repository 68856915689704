.sign-in {
    width: 100%;
    height: 100vh;
    float: left;
    background-color: white;
}

.sign-in .container-fluid,
.sign-in .loginform-container,
.sign-in .blue-box-cont {
    width: 100%;
    /* height: 100%; */
    height: 100vh;
}

.loginform-container {
    width: 100%;
    float: left;
    padding-left: 50px;
    padding-top: 45px;

}

.login-page form {
    position: relative;
    width: 400px;
    /* height: calc(100vh - 85px); */
    display: flex;
    justify-content: center;
    flex-direction: column;
}

.text h4 {
    font-weight: 700;
}

.inner-group {
    display: flex;
    align-items: center;
    border: 1px solid rgb(202, 183, 221);
    padding: 0 14px;
    border-radius: 5px;
}

.login-page .form-group input {
    width: 100%;
    padding: 0;
    outline: none;
    border: none;
    text-indent: 10px;
    font-size: 14px;
}

.email-group {
    margin: 30px 0 !important;
}

.signin-btn1 {
    padding: 5px 10px;
    width: 100%;
    /* border-radius: 20px; */
    border-radius: 5px;
    background-color: #5522b2;
    color: white;
    border: none;
    font-size: 14px;
}

.signin-btn2 {
    padding: 5px 10px;
    width: 100%;
    /* border-radius: 20px; */
    border-radius: 5px;
    background-color: transparent;
    border: 1px solid rgba(137, 43, 226, 0.685);
    color: blueviolet;
    font-size: 14px;
}

.background-wh {
    background-color: #fff;
}

.signin-btn1 a {
    text-decoration: none;
    color: white;
}

.signin-btn2 a {
    text-decoration: none;
    color: blueviolet;
}

.or {
    text-align: center;
    position: relative;
    color: gray;
    font-size: 15px;
    margin-top: 15px;
    margin-bottom: 15px;
}

.or:after {
    content: "";
    height: 1px;
    width: 45%;
    position: absolute;
    background-color: rgb(128 128 128 / 31%);
    top: 50%;
    right: 0;
    transform: translateY(-50%);
}

.or:before {
    content: "";
    height: 1px;
    width: 45%;
    position: absolute;
    background-color: rgb(128 128 128 / 31%);
    top: 50%;
    left: 0;
    transform: translateY(-50%);
}

.set-pwd button {
    text-align: center;
    font-size: 14px;
    margin: 20px 0;
    color: gray;
    border: none;
    padding: 0;
}

.set-pwd a {
    color: gray;
}

.img-fluid {
    width: 280px;
}

.signUp,
.FSdAW {
    padding-bottom: 3px;
    padding-top: 9px;
}

@media only screen and (max-width: 767px){
    .signin-btn1 {
        display: flex;
        justify-content: center;
        align-items: center;
        padding:10px 0px;
    }

    .signUp p a{
        display: inline-block;
    }

    .logo-container{
        z-index: 1;
    }

    .sign-in form {
        margin-top:20px;
    }
}

/* .signin-btn2{
    text-decoration: underline;
} */