.topic-head {
  border-bottom: 1px solid #dee2e6;
}

form input[type=checkbox] {
  width: 15px !important;
  height: 15px !important;
}

.form-check label {
  color: #000 !important;
  font-weight: normal !important;
}

.minutes-box {
  border: 1px solid #e5e8eb;
  padding: 15px;
  border-radius: 5px;
  border-left: 4px solid rgb(100 173 85);
  background-color: rgb(246 241 255);
}

.add-minutes {
  background: rgb(13, 55, 180);
  background: linear-gradient(117deg,
      rgba(13, 55, 180, 1) 0%,
      rgba(118, 60, 209, 1) 59%) !important;
  color: #fff;
  font-size: 8px !important;
  padding: 5px 13px !important;
  border: none;
  display: flex;
  justify-content: center;
  border-radius: 50px;
}

/* .add-minutes p {
  font-size: 13px !important;
} */

.minutes-border {
  margin: 15px 0;
  border-top: 1px solid #cbcbcb;
  border-top-style: dashed;
}

.agenda-minutes .btn-outerbox {
  align-items: normal;
}

.mom-activities .form-group {
  border-bottom: 1px solid #ddd;
}

.mom-activities .form-group:last-child {
  border-bottom: none;
}